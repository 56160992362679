import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeWeekStartDay = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (!rruleObj.wkst) {
      return data.options.weekStartsOnSunday;
   }
   return rruleObj.wkst === 6;
};
