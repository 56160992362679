import { FC } from "react";
import { Chip } from "@components";
import { CallbackFn } from "@domain/common";
import { Box, Drawer, Stack, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { env } from "@prmenv";
import { drawerWidth } from "@prmthemes";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
// import LogoSection from "../LogoSection";
import { MenuCard } from "./menucard/MenuCard";
import { MenuList } from "./menulist/MenuList";

interface SidebarProps {
   drawerOpen: boolean;
   drawerToggle: CallbackFn;
}

export const Sidebar: FC<SidebarProps> = ({ drawerOpen, drawerToggle }) => {
   const theme = useTheme();
   const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

   const appVersion = () => {
      return (
         <Stack
            direction="row"
            justifyContent="center"
            sx={{ mb: 2 }}
            data-testid="app-version"
         >
            <Chip
               label={env.APP_VERSION}
               chipcolor="secondary"
               size="small"
               disabled={true}
               sx={{ cursor: "pointer" }}
            />
         </Stack>
      );
   };

   const menu = () => {
      return (
         <Box data-testid="menus">
            <MenuList />
            <MenuCard />
            {appVersion()}
         </Box>
      );
   };

   const browserView = () => {
      return (
         <Box data-testid="browser-view">
            <PerfectScrollbar
               component="div"
               style={{
                  height: !matchUpMd
                     ? "calc(100vh - 56px)"
                     : "calc(100vh - 88px)",
                  paddingLeft: "16px",
                  paddingRight: "16px",
               }}
               data-testid="perfect-scrollbar"
            >
               {menu()}
            </PerfectScrollbar>
         </Box>
      );
   };

   const mobileView = () => {
      return (
         <Box sx={{ px: 2 }} data-testid="mobile-view">
            {menu()}
         </Box>
      );
   };

   const deviceView = () => {
      if (isMobile) {
         return mobileView();
      }
      return browserView();
   };

   const menus = () => {
      return (
         <Box data-testid="drawer-content">
            <Box sx={{ display: { xs: "block", md: "none" } }}>
               <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
                  {/* <LogoSection /> */}
               </Box>
            </Box>
            {deviceView()}
         </Box>
      );
   };

   const drawerContainer = () => {
      if (_.isUndefined(window) || matchUpMd) {
         return undefined;
      }
      return () => window.document.body;
   };

   return (
      <Box
         component="nav"
         sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
         data-testid="sidebar"
      >
         <Drawer
            container={drawerContainer()}
            variant={matchUpMd ? "persistent" : "temporary"}
            anchor="left"
            open={drawerOpen}
            onClose={drawerToggle}
            sx={{
               "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  background: theme.palette.background.default,
                  color: theme.palette.text.primary,
                  borderRight: "none",
                  [theme.breakpoints.up("md")]: {
                     top: "88px",
                  },
               },
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
            data-testid="drawer"
         >
            {menus()}
         </Drawer>
      </Box>
   );
};
