import {
   DAccountSettings,
   DContactIndustries,
   DContactLabels,
   DContactSettings,
} from "@domain/settings";
import _ from "lodash";
import {
   useLoadAccountSettings,
   useLoadContactSettings,
} from "../prm/settings/hooks/useQueries";

const defaultAccountSettings: DAccountSettings = { appLanguage: "" };
const defaultContactSettings: DContactSettings = { labels: [], industries: [] };

export const useGeneralSettings = (): DAccountSettings => {
   const { data: settings } = useLoadAccountSettings();
   if (!settings) {
      return defaultAccountSettings;
   }
   return settings;
};

export const useContactSettings = (): DContactSettings => {
   const { data: settings } = useLoadContactSettings();
   if (!settings) {
      return defaultContactSettings;
   }
   return settings;
};

export const useContactLabels = (): DContactLabels => {
   const { labels } = useContactSettings();
   const dlabelOptions = _.map(labels, (label) => label.value);
   return { dlabels: labels, dlabelOptions };
};

export const useContactIndustries = (): DContactIndustries => {
   const { industries } = useContactSettings();
   const dIndustryOptions = _.map(industries, (industry) => industry.name);
   return { dIndustries: industries, dIndustryOptions };
};
