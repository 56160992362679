import { FC, useCallback, useEffect, useRef } from "react";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $insertNodes, EditorState, LexicalEditor } from "lexical";

interface ContentPluginProps {
   content?: string;
   onChange: (html: string) => void;
}

export const ContentPlugin: FC<ContentPluginProps> = ({
   content = "",
   onChange,
}) => {
   const [editor] = useLexicalComposerContext();
   const isExecuted = useRef<boolean>(false);

   const setInitContent = useCallback(() => {
      if (!isExecuted.current) {
         const parser = new DOMParser();
         const dom = parser.parseFromString(content, "text/html");
         const nodes = $generateNodesFromDOM(editor, dom);
         $insertNodes(nodes);
         isExecuted.current = true;
      }
   }, [isExecuted, content, editor]);

   useEffect(() => {
      return editor.update(() => {
         setInitContent();
      });
   }, [editor, setInitContent]);

   const onChangeHandler = (
      editorState: EditorState,
      editor: LexicalEditor
   ) => {
      editorState.read(() => {
         const html = $generateHtmlFromNodes(editor, null);
         onChange(html);
      });
   };

   return (
      <OnChangePlugin onChange={onChangeHandler} ignoreSelectionChange={true} />
   );
};
