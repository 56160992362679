import { useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
   COMMAND_PRIORITY_LOW,
   FORMAT_TEXT_COMMAND,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import { ToolbarButton } from "../widgets/ToolbarButton";
import { IconType, ToolbarIcon } from "../widgets/ToolbarIcon";
import { selectionHasFormat } from "./utils/nodes";

export const UnderlineButton = () => {
   const [editor] = useLexicalComposerContext();
   const [isUnderline, setIsUnderline] = useState(false);

   const updateToolbar = useCallback(() => {
      const fontFamily = selectionHasFormat("underline");
      setIsUnderline(fontFamily);
   }, []);

   useEffect(() => {
      return editor.registerCommand(
         SELECTION_CHANGE_COMMAND,
         () => {
            updateToolbar();
            return false;
         },
         COMMAND_PRIORITY_LOW
      );
   }, [editor, updateToolbar]);

   const format = () => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
   };

   return (
      <ToolbarButton
         data-testid="underline-button"
         onClick={format}
         active={isUnderline}
      >
         <ToolbarIcon type={IconType.TYPE_UNDERLINE} blured={!isUnderline} />
      </ToolbarButton>
   );
};
