import { FC, useState } from "react";
import { Popover } from "@mui/material";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarChevron } from "./ToolbarChevron";
import { IconType, ToolbarIcon } from "./ToolbarIcon";
import { ToolbarItemProps } from "./ToolbarItem";
import { ToolbarItemText } from "./ToolbarItemText";

interface ToolbarDropDownProps extends ToolbarItemProps {
   label?: string;
   icon?: IconType;
   suppressCloseOnSelection?: boolean;
}

export const ToolbarDropDown: FC<ToolbarDropDownProps> = ({
   label,
   icon,
   suppressCloseOnSelection,
   sx,
   children,
}) => {
   const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
   const [showPopover, setShowPopover] = useState(false);

   const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      setShowPopover(true);
   };

   const onPopoverClick = () => {
      if (!suppressCloseOnSelection) {
         onPopoverClose();
      }
   };

   const onPopoverClose = () => {
      setAnchorEl(null);
      setShowPopover(false);
   };

   const popover = () => {
      return (
         <Popover
            data-testid="dropdown-popover"
            open={showPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "left",
            }}
            onClose={onPopoverClose}
            onClick={onPopoverClick}
            sx={{
               "& .MuiPopover-paper": {
                  borderRadius: "8px",
                  minWidth: "100px",
                  minHeight: "40px",
               },
            }}
         >
            {children}
         </Popover>
      );
   };

   const toolbarIcon = () => {
      if (icon) {
         return <ToolbarIcon type={icon} sx={{ mr: "8px" }} />;
      }
      return null;
   };

   const toolbarText = () => {
      if (label) {
         return <ToolbarItemText>{label}</ToolbarItemText>;
      }
      return null;
   };

   return (
      <>
         <ToolbarButton
            data-testid="toolbar-dropdown"
            onClick={handleClick}
            sx={sx}
         >
            {toolbarIcon()}
            {toolbarText()}
            <ToolbarChevron />
         </ToolbarButton>
         {popover()}
      </>
   );
};
