import { FC } from "react";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import { IconAdjustmentsHorizontal, IconSearch } from "@tabler/icons-react";
import { SearchAvatar } from "./SearchAvatar";

interface BrowserSearchProps {
   value: string;
   setValue: (value: string) => void;
}

export const BrowserSearch: FC<BrowserSearchProps> = ({ value, setValue }) => {
   return (
      <Box
         sx={{ display: { xs: "none", md: "block" } }}
         data-testid="browser-search"
      >
         <OutlinedInput
            id="search-input"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            startAdornment={
               <InputAdornment position="start" data-testid="start-adormant">
                  <IconSearch
                     stroke={1.5}
                     size="16px"
                     data-testid="search-icon"
                  />
               </InputAdornment>
            }
            endAdornment={
               <InputAdornment position="end" data-testid="end-adormant">
                  <SearchAvatar>
                     <IconAdjustmentsHorizontal
                        stroke={1.5}
                        size="20px"
                        data-testid="adjustments-icon"
                     />
                  </SearchAvatar>
               </InputAdornment>
            }
            inputProps={{
               sx: { bgcolor: "transparent", pl: 0.5 },
            }}
            sx={{ width: { md: 250, lg: 434 }, ml: 2, px: 2 }}
            data-testid="outlined-input"
         />
      </Box>
   );
};
