import { FC } from "react";
import { CallbackFn } from "@domain/common";
import { Box } from "@mui/material";
import { toTestId } from "../../../../../../utils/common";
import { IconType, ToolbarIcon } from "./ToolbarIcon";
import { ToolbarItemProps } from "./ToolbarItem";

interface ToolbarDropDownOptionProps extends ToolbarItemProps {
   onClick: CallbackFn;
   text: string;
   icon?: IconType;
   active?: boolean;
}

export const ToolbarDropDownOption: FC<ToolbarDropDownOptionProps> = ({
   onClick,
   text,
   icon,
   active,
   sx,
}) => {
   const toolbarIcon = () => {
      if (icon) {
         return (
            <ToolbarIcon blured={!active} type={icon} sx={{ mr: "12px" }} />
         );
      }
      return null;
   };

   return (
      <Box
         data-testid={`toolbar-dropdown-option-${toTestId(text)}`}
         component="button"
         onClick={onClick}
         sx={{
            display: "flex",
            m: "0 8px 0 8px",
            p: "8px",
            color: "#050505",
            cursor: "pointer",
            lineHeight: "16px",
            fontSize: "15px",
            alignContent: "center",
            flexDirection: "row",
            flexShrink: "0",
            backgroundColor: active ? "#dfe8fa4d" : "#fff",
            borderRadius: "8px",
            border: "0",
            minWidth: "268px",
            ":first-of-type": {
               mt: "8px",
            },
            ":last-of-type": {
               mb: "8px",
            },
            ":hover": {
               backgroundColor: "#eee",
            },
            ...sx,
         }}
      >
         {toolbarIcon()}
         <Box
            component="span"
            sx={{
               display: "flex",
               lineHeight: "20px",
               flexGrow: "1",
               width: "200px",
            }}
         >
            {text}
         </Box>
      </Box>
   );
};
