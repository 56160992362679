import { FC } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
   Autocomplete,
   AutocompleteChangeReason,
   Box,
   Checkbox as MuiCheckbox,
   Chip,
   TextField,
} from "@mui/material";
import { toTestId } from "@utils";
import _ from "lodash";

export interface CheckboxManageOptionsConfig {
   value: string;
   action: () => void;
   icon: JSX.Element;
}

export interface CheckboxOptionConfig {
   value: string;
   color: string | null;
}

export interface CheckboxProps {
   field: string;
   label: string;
   value: string | string[] | null;
   placeholder?: string;
   options: string[];
   optionsConfig?: CheckboxOptionConfig[];
   manageOptionsConfig?: CheckboxManageOptionsConfig;
   multiple?: boolean;
   disableCloseOnSelect?: boolean;
   required?: boolean;
   fullWidth?: boolean;
   onChange: (newValue: string | string[] | null) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
   field,
   label,
   value,
   placeholder,
   options,
   optionsConfig,
   manageOptionsConfig: moc,
   multiple,
   disableCloseOnSelect,
   fullWidth = true,
   onChange,
}) => {
   const handleValueChange = (
      newValue: string | string[] | null,
      reason: AutocompleteChangeReason
   ) => {
      if (newValue && isManageOptions(newValue)) {
         moc?.action();
      } else {
         if (reason == "clear" && !_.isArray(newValue)) {
            newValue = null;
         }
         onChange(newValue);
      }
   };

   const resolveColor = (option: string) => {
      const oc = _.find(optionsConfig, (oc) => _.isEqual(option, oc.value));
      return oc?.color || undefined;
   };

   const isManageOptions = (options: string | string[]): boolean => {
      if (_.isArray(options)) {
         const mo = _.find(options, (option) => isManageOptions(option));
         return mo !== undefined;
      }
      return _.isEqual(options, moc?.value);
   };

   const selectableOption = (option: string, selected: boolean) => {
      return (
         <>
            <MuiCheckbox
               icon={<CheckBoxOutlineBlankIcon />}
               checkedIcon={<CheckBoxIcon />}
               checked={selected}
            />
            {option}
            <Box
               component="span"
               sx={{
                  ml: "0.5em",
                  width: "1em",
                  flexShrink: 0,
                  borderRadius: "3px",
               }}
               style={{ backgroundColor: resolveColor(option) }}
            />
         </>
      );
   };

   const manageOptions = (option: string) => {
      return (
         <Box
            sx={{
               display: "flex",
               justifyContent: "start",
               alignItems: "center",
               ml: "0.5em",
               pt: "1em",
               borderTop: "1px solid #d8d8d8",
               width: "100%",
            }}
         >
            <Box component="span">{moc?.icon}</Box>
            <Box component="span" sx={{ ml: "0.5em", mt: "-0.4em" }}>
               {option}
            </Box>
         </Box>
      );
   };

   const checkBoxOption = (option: string, selected: boolean) => {
      if (isManageOptions(option)) {
         return manageOptions(option);
      }
      return selectableOption(option, selected);
   };

   return (
      <Autocomplete
         id={field}
         fullWidth={fullWidth}
         multiple={multiple}
         disableCloseOnSelect={disableCloseOnSelect}
         options={options}
         renderOption={(props, option, { selected }) => {
            return <li {...props}>{checkBoxOption(option, selected)}</li>;
         }}
         renderInput={(params) => {
            return (
               <TextField
                  {...params}
                  label={label}
                  placeholder={placeholder}
                  variant="outlined"
               />
            );
         }}
         renderTags={(values: string[], getTagProps) => {
            return _.map(values, (value: string, index: number) => {
               return (
                  <Chip
                     data-testid={`chip-${toTestId(value)}`}
                     label={value}
                     sx={{ backgroundColor: resolveColor(value) }}
                     {...getTagProps({ index })}
                  />
               );
            });
         }}
         value={value}
         onChange={(_, newValue, reason) => handleValueChange(newValue, reason)}
         data-testid={field}
      />
   );
};
