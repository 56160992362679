import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as uiReducer } from "./ui/reducer";
import { reducer as userReducer } from "./user/reducer";

const SESSION_STORAGE = "prm_session_storage";

const getInitState = (): Partial<AppState> => {
   const storage = sessionStorage.getItem(SESSION_STORAGE);
   if (storage) {
      return JSON.parse(storage);
   }
   return {};
};

const rootReducer = combineReducers({
   user: userReducer,
   ui: uiReducer,
});

export const setupStore = () => {
   const store = configureStore({
      reducer: rootReducer,
      preloadedState: getInitState(),
   });
   return store;
};

export type AppState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
