import { useState } from "react";
import { authenticateAxiosClients } from "@backend/axios";
import { Box, CircularProgress } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LandingPage } from "../pages/landing/LandingPage";
import { MainRoutes } from "./routes/MainRoutes";

export const App = () => {
   const { initialized, keycloak } = useKeycloak();
   const [queryClient] = useState(() => new QueryClient());
   authenticateAxiosClients(keycloak);

   const router = createBrowserRouter([MainRoutes]);

   const renderSpinner = () => {
      return (
         <Box
            sx={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: "100vh",
            }}
            data-testid="spinner"
         >
            <CircularProgress />
         </Box>
      );
   };

   const renderLandingPage = () => {
      return <LandingPage />;
   };

   const render = () => {
      if (!initialized) {
         return renderSpinner();
      }
      if (!keycloak.authenticated) {
         return renderLandingPage();
      }
      return <RouterProvider router={router} />;
   };

   return (
      <Box sx={{ height: "100%", minHeight: "100vh" }} data-testid="prm-app">
         <QueryClientProvider client={queryClient}>
            {render()}
            <ReactQueryDevtools
               initialIsOpen={false}
               buttonPosition="bottom-left"
            />
         </QueryClientProvider>
      </Box>
   );
};
