import { FC, ReactNode } from "react";
import { Chip } from "@components";
import {
   Avatar,
   Box,
   Divider,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemSecondaryAction,
   ListItemText,
   Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface ListItemWrapperProps {
   children: ReactNode;
}

const ListItemWrapper: FC<ListItemWrapperProps> = ({ children }) => {
   return (
      <Box
         sx={{
            p: 2,
            borderBottom: "1px solid",
            borderColor: "divider",
            cursor: "pointer",
            "&:hover": {
               bgcolor: "primary.light",
            },
         }}
      >
         {children}
      </Box>
   );
};

export const NotificationList = () => {
   const theme = useTheme();

   const chipSX = {
      height: 24,
      padding: "0 6px",
   };

   const chipErrorSX = {
      ...chipSX,
      color: theme.palette.orange.dark,
      backgroundColor: theme.palette.orange.light,
      marginRight: "5px",
   };

   const chipWarningSX = {
      ...chipSX,
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
   };

   const chipSuccessSX = {
      ...chipSX,
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
      height: 28,
   };

   return (
      <List
         sx={{
            width: "100%",
            maxWidth: 330,
            py: 0,
            borderRadius: "10px",
            [theme.breakpoints.down("md")]: {
               maxWidth: 300,
            },
            "& .MuiListItemSecondaryAction-root": {
               top: 22,
            },
            "& .MuiDivider-root": {
               my: 0,
            },
            "& .list-container": {
               pl: 7,
            },
         }}
         data-testid="notification-list"
      >
         <ListItemWrapper>
            <ListItem alignItems="center">
               <ListItemAvatar>
                  <Avatar />
               </ListItemAvatar>
               <ListItemText primary="John Doe" />
               <ListItemSecondaryAction>
                  <Grid container justifyContent="flex-end">
                     <Grid item xs={12}>
                        <Typography
                           variant="caption"
                           display="block"
                           gutterBottom
                        >
                           2 min ago
                        </Typography>
                     </Grid>
                  </Grid>
               </ListItemSecondaryAction>
            </ListItem>
            <Grid container direction="column" className="list-container">
               <Grid item xs={12} sx={{ pb: 2 }}>
                  <Typography variant="subtitle2">
                     It is a long established fact that a reader will be
                     distracted
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Grid container>
                     <Grid item>
                        <Chip label="Unread" sx={chipErrorSX} />
                     </Grid>
                     <Grid item>
                        <Chip label="New" sx={chipWarningSX} />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </ListItemWrapper>
         <Divider />
         <ListItemWrapper>
            <ListItem alignItems="center">
               <ListItemAvatar>
                  <Avatar />
               </ListItemAvatar>
               <ListItemText
                  primary={
                     <Typography variant="subtitle1">John Doe</Typography>
                  }
               />
               <ListItemSecondaryAction>
                  <Grid container justifyContent="flex-end">
                     <Grid item xs={12}>
                        <Typography
                           variant="caption"
                           display="block"
                           gutterBottom
                        >
                           2 min ago
                        </Typography>
                     </Grid>
                  </Grid>
               </ListItemSecondaryAction>
            </ListItem>
            <Grid container direction="column" className="list-container">
               <Grid item xs={12} sx={{ pb: 2 }}>
                  <Typography variant="subtitle2">
                     It is a long established fact that a reader will be
                     distracted
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Grid container>
                     <Grid item>
                        <Chip
                           label="Confirmation of Account."
                           sx={chipSuccessSX}
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </ListItemWrapper>
      </List>
   );
};
