import { FC, useState } from "react";
import { Close, Menu } from "@mui/icons-material";
import { Box, Container, IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AuthBtns } from "./AuthBtns";
import { Logo } from "./Logo";
import { Navigation } from "./Navigation";

export const Header: FC = () => {
   const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
   const { breakpoints } = useTheme();
   const matchMobileView = useMediaQuery(breakpoints.down("md"));

   const closeIcon = () => {
      if (visibleMenu && matchMobileView) {
         return (
            <IconButton
               data-testid="close-menu-btn"
               sx={{
                  position: "fixed",
                  top: 10,
                  right: 10,
                  zIndex: "appBar",
               }}
               onClick={() => setVisibleMenu(!visibleMenu)}
            >
               <Close />
            </IconButton>
         );
      }
      return null;
   };

   const navigationBtns = () => {
      return (
         <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={5}
            sx={{
               transition: (theme) => theme.transitions.create(["top"]),
               ...(matchMobileView && {
                  position: "fixed",
                  width: "100%",
                  height: "100vh",
                  top: visibleMenu ? 0 : "-120vh",
                  left: 0,
                  backgroundColor: "background.paper",
                  zIndex: "appBar",
               }),
            }}
            data-testid="navigation-btns"
         >
            <Stack justifyContent="center" height={{ xs: "70%", md: "auto" }}>
               <Navigation />
            </Stack>
            <Stack justifyContent="center" height={{ xs: "30%", md: "auto" }}>
               <AuthBtns />
            </Stack>
         </Stack>
      );
   };

   const navigationPanel = () => {
      return (
         <Stack
            width="100%"
            direction={{ xs: "column", md: "row" }}
            justifyContent="end"
            data-testid="navigation-panel"
         >
            {navigationBtns()}
            {closeIcon()}
         </Stack>
      );
   };

   return (
      <Container
         data-testid="header"
         sx={{ py: { xs: 2, md: 3, backgroundColor: "background.paper" } }}
      >
         <Stack direction="row" alignItems="center">
            <Logo />
            {navigationPanel()}
            <Box
               sx={{
                  display: { xs: "inline-flex", md: "none" },
               }}
            >
               <IconButton
                  data-testid="menu-btn"
                  onClick={() => setVisibleMenu(!visibleMenu)}
               >
                  <Menu />
               </IconButton>
            </Box>
         </Stack>
      </Container>
   );
};
