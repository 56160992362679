import {
   cancelSubscription,
   createSubscription,
   loadCurrentPricePlan,
   loadPrices,
   loadSubscriptionInfo,
} from "@backend/subscription/subscription";
import {
   DCreateSubscription,
   DPaymentSession,
   DPricePlan,
   DPricesPage,
   DSubscriptionInfo,
} from "@domain/subscription";
import {
   keepPreviousData,
   QueryClient,
   UndefinedInitialDataOptions,
   useMutation,
   UseMutationOptions,
   UseMutationResult,
   useQuery,
   useQueryClient,
   UseQueryResult,
} from "@tanstack/react-query";

export const createSubscriptionOptions = (
   queryClient: QueryClient
): UseMutationOptions<DPaymentSession, Error, DCreateSubscription> => {
   return {
      mutationFn: createSubscription,
      onSuccess: () => {
         queryClient.invalidateQueries({
            queryKey: ["subscription"],
         });
      },
   };
};

export const useCreateSubscription = (): UseMutationResult<
   DPaymentSession,
   Error,
   DCreateSubscription
> => {
   const queryClient = useQueryClient();
   const options = createSubscriptionOptions(queryClient);
   return useMutation(options);
};

export const cancelSubscriptionOptions = (
   queryClient: QueryClient
): UseMutationOptions<void, Error, void> => {
   return {
      mutationFn: cancelSubscription,
      onSuccess: () => {
         queryClient.invalidateQueries({
            queryKey: ["subscription"],
         });
         queryClient.invalidateQueries({
            queryKey: ["current-price-plan"],
         });
      },
   };
};

export const useCancelSubscription = (): UseMutationResult<
   void,
   Error,
   void
> => {
   const queryClient = useQueryClient();
   const options = cancelSubscriptionOptions(queryClient);
   return useMutation(options);
};

export const loadSubscriptionInfoOptions = (): UndefinedInitialDataOptions<
   DSubscriptionInfo,
   Error,
   DSubscriptionInfo
> => {
   return {
      queryKey: ["subscription"],
      queryFn: () => loadSubscriptionInfo(),
      staleTime: 5 * 60 * 1000,
   };
};

export const useLoadSubscriptionInfo =
   (): UseQueryResult<DSubscriptionInfo> => {
      const options = loadSubscriptionInfoOptions();
      return useQuery(options);
   };

export const loadCurrentPricePlanOptions = (): UndefinedInitialDataOptions<
   DPricePlan,
   Error,
   DPricePlan
> => {
   return {
      queryKey: ["current-price-plan"],
      queryFn: () => loadCurrentPricePlan(),
      staleTime: 5 * 60 * 1000,
   };
};

export const useLoadCurrentPricePlan = (): UseQueryResult<DPricePlan> => {
   const options = loadCurrentPricePlanOptions();
   return useQuery(options);
};

export const loadPricesOptions = (): UndefinedInitialDataOptions<
   DPricesPage,
   Error,
   DPricesPage
> => {
   return {
      queryKey: ["subscription/prices"],
      queryFn: () => loadPrices(),
      placeholderData: keepPreviousData,
      staleTime: 5 * 60 * 1000,
   };
};

export const useLoadPrices = (): UseQueryResult<DPricesPage> => {
   const options = loadPricesOptions();
   return useQuery<DPricesPage>(options);
};
