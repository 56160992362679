import { Options } from "rrule";
import { MonthlyMode, RecurrenceConfig } from "../../../types";

export const computeMonthlyMode = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
): MonthlyMode => {
   if (rruleObj.freq !== 1) {
      return data.repeat.monthly.mode;
   }
   if (rruleObj.bymonthday) {
      return "on day";
   }
   return "on the";
};
