import { FC } from "react";
import { Box, BoxProps } from "@mui/material";
import redoImg from "@assets/toolbar/icons/arrow-clockwise.svg";
import undoImg from "@assets/toolbar/icons/arrow-counterclockwise.svg";

export enum ImageType {
   UNDO = "UNDO",
   REDO = "REDO"
}

interface ToolbarImageProps extends BoxProps {
   type: ImageType;
   disabled?: boolean;
}

export const ToolbarImage: FC<ToolbarImageProps> = ({
   type,
   disabled,
   sx,
   ...rest
}) => {
   const getImage = (): string => {
      switch (type) {
         case ImageType.UNDO:
            return undoImg;
         case ImageType.REDO:
            return redoImg;
      }
   };

   return (
      <Box
         data-testid="item-image"
         component="img"
         sx={{
            height: "18px",
            width: "18px",
            marginTop: "2px",
            backgroundSize: "contain",
            display: "inline-block",
            verticalAlign: "-0.25em",
            opacity: disabled ? "0.2" : "0.6",
            ...sx
         }}
         src={getImage()}
         {...rest}
      />
   );
};
