import { FC } from "react";
import {
   Box,
   Button,
   ButtonProps,
   Dialog as MuiDialog,
   DialogActions,
   DialogContent,
   DialogProps as MuiDialogProps,
   DialogTitle,
} from "@mui/material";
import _ from "lodash";
import { toTestId } from "../../../utils/common";

export interface DialogActionProps extends ButtonProps {
   label: string;
}

export interface DialogProps extends MuiDialogProps {
   title: string;
   actions: DialogActionProps[];
   children: JSX.Element | JSX.Element[];
}

export const Dialog: FC<DialogProps> = ({
   title,
   fullWidth = true,
   maxWidth = "lg",
   actions,
   children,
   ...dialogProps
}) => {
   const actionBtn = (props: DialogActionProps, index: number) => {
      const { label } = props;
      return (
         <Button
            data-testid={`act-btn-${toTestId(label)}`}
            key={index}
            {...props}
         >
            {label}
         </Button>
      );
   };

   const actionBtns = () => {
      return _.map(actions, (action, i) => actionBtn(action, i));
   };

   return (
      <Box data-testid="dialog">
         <MuiDialog
            data-testid="mui-dialog"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            {...dialogProps}
         >
            <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
            <DialogContent data-testid="dialog-content" dividers={true}>
               {children}
            </DialogContent>
            <DialogActions data-testid="dialog-actions">
               {actionBtns()}
            </DialogActions>
         </MuiDialog>
      </Box>
   );
};
