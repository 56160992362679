import { FC, useState } from "react";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { daysInMonth, toTestId } from "@utils";
import _ from "lodash";
import {
   Day,
   DAYS,
   Month,
   MonthlyMode,
   MonthlyRepeat,
   MONTHS,
   Order,
   ORDERS,
} from "../../types";

interface MonhtlyProps {
   config: MonthlyRepeat;
   onChange: (update: MonthlyRepeat) => void;
}

export const Monthly: FC<MonhtlyProps> = ({
   config: { mode: initMode, interval: initInterval },
   onChange,
}) => {
   const [mode, setMode] = useState<MonthlyMode>(initMode);
   const [interval, setInterval] = useState<number>(initInterval);
   const [monthDay, setMonthDay] = useState<number>(1);
   const [order, setOrder] = useState<Order>("First");
   const [weekday, setWeekday] = useState<Day>("Monday");

   const getMonthdays = (selectedMonth: Month): number[] => {
      const monthDigit =
         _.findIndex(MONTHS, (m) => _.isEqual(m, selectedMonth)) + 1;
      const daysCount = daysInMonth(monthDigit) + 1;
      return _.range(1, daysCount);
   };

   const updatedOnDayConfig = (
      mode: MonthlyMode,
      interval: number,
      monthDay: number
   ) => {
      const update: MonthlyRepeat = {
         mode,
         interval: interval,
         on: { day: monthDay },
         onThe: { day: weekday, which: order },
      };
      onChange(update);
   };

   const updatedOnTheConfig = (
      mode: MonthlyMode,
      order: Order,
      weekday: Day
   ) => {
      const update: MonthlyRepeat = {
         mode,
         interval,
         on: { day: monthDay },
         onThe: { day: weekday, which: order },
      };
      onChange(update);
   };

   const updateMode = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as MonthlyMode;
      setMode(value);
      updatedOnDayConfig(value, interval, monthDay);
   };

   const updateInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setInterval(value);
      updatedOnDayConfig(mode, value, monthDay);
   };

   const updateMonthDay = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newMonthDay = Number(event.target.value);
      setMonthDay(newMonthDay);
      updatedOnDayConfig(mode, interval, newMonthDay);
   };

   const updateOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newOrder = event.target.value as Order;
      setOrder(newOrder);
      updatedOnTheConfig(mode, newOrder, weekday);
   };

   const updateWeekday = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newWeekday = event.target.value as Day;
      setWeekday(newWeekday);
      updatedOnTheConfig(mode, order, newWeekday);
   };

   const modes: MonthlyMode[] = ["on day", "on the"];

   const modeSelector = () => {
      return (
         <TextField
            id="select-repeat-mode"
            select={true}
            label="Mode"
            value={mode}
            onChange={updateMode}
            sx={{ width: "8em" }}
            data-testid="select-repeat-mode"
         >
            {modes.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`repeat-mode-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const intervalInput = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{
               justifyContent: "center",
               alignItems: "center",
            }}
            data-testid="executions-interval"
         >
            <Grid item={true}>
               <Typography>every</Typography>
            </Grid>
            <Grid item={true}>
               <TextField
                  id="interval"
                  type="number"
                  label="After"
                  value={interval}
                  onChange={updateInterval}
                  data-testid="interval"
               />
            </Grid>
            <Grid item={true}>
               <Typography>month(s)</Typography>
            </Grid>
         </Grid>
      );
   };

   const monthDaySelector = () => {
      return (
         <TextField
            id="select-monthday"
            select={true}
            label="Day"
            value={monthDay}
            onChange={updateMonthDay}
            key="monthday"
            data-testid="select-monthday"
         >
            {getMonthdays("Jan").map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`monthday-${option}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const orderSelector = () => {
      return (
         <TextField
            id="select-order"
            select={true}
            label="Order"
            value={order}
            onChange={updateOrder}
            data-testid="select-order"
         >
            {ORDERS.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`order-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const weekDaySelector = () => {
      return (
         <TextField
            id="select-weekday"
            select={true}
            label="Weekday"
            value={weekday}
            onChange={updateWeekday}
            data-testid="select-weekday"
         >
            {DAYS.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`weekday-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const onDayInputs = () => {
      return (
         <Grid
            container={true}
            gap={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
         >
            <Grid item={true}>{intervalInput()}</Grid>
            <Grid item={true}>{monthDaySelector()}</Grid>
         </Grid>
      );
   };

   const onTheInputs = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
         >
            <Grid item={true}>{intervalInput()}</Grid>
            <Grid item={true}>{orderSelector()}</Grid>
            <Grid item={true}>{weekDaySelector()}</Grid>
         </Grid>
      );
   };

   const inputs = () => {
      if (mode === "on day") {
         return onDayInputs();
      }
      return onTheInputs();
   };

   return (
      <Grid container={true} gap={2} data-testid="monthly-repeat">
         <Grid item={true}>{modeSelector()}</Grid>
         <Grid item={true}>{inputs()}</Grid>
      </Grid>
   );
};
