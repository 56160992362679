import { FC } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";

const companyMenu = [
   { label: "Contact Us", path: "#" },
   { label: "Privacy & Policy", path: "#" },
   { label: "Term & Condition", path: "#" },
   { label: "FAQ", path: "#" },
];

export const Navigation: FC = () => {
   const sectonTitle = (title: string) => {
      return (
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               mb: 2,
            }}
         >
            <Typography
               component="p"
               variant="h5"
               sx={{ color: "primary.contrastText", fontWeight: "700" }}
            >
               {title}
            </Typography>
         </Box>
      );
   };

   const link = (path: string, label: string, index: number) => {
      return (
         <Link
            href={path}
            underline="hover"
            key={index}
            sx={{
               display: "block",
               mb: 1,
               color: "primary.contrastText",
            }}
         >
            {label}
         </Link>
      );
   };

   const links = () => {
      return companyMenu.map(({ label, path }, index) =>
         link(path, label, index)
      );
   };

   return (
      <Grid
         container={true}
         justifyContent={{ xs: "center" }}
         data-testid="navigation"
      >
         <Grid item={true}>
            {sectonTitle("About")}
            {links()}
         </Grid>
      </Grid>
   );
};
