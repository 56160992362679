import { FC } from "react";
import { Box, Typography } from "@mui/material";

export const Logo: FC = () => {
   return (
      <Box data-testid="logo">
         <Typography
            sx={{
               typography: { xs: "h2", md: "h1" },
               fontWeight: 700,
               "& span": {
                  color: "primary.main",
               },
            }}
         >
            Vision<span>Notes</span>
         </Typography>
      </Box>
   );
};
