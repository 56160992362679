import { Grid } from "@mui/material";
import { useLoadSubscriptionInfo } from "../hooks/useQueries";
import { ActiveSubscription } from "./views/active/ActiveSubscription";
import { PriceTable } from "./views/pricetable/PriceTable";

export const SubscriptionPanel = () => {
   const { data: subscriptionInfo } = useLoadSubscriptionInfo();

   const renderSubscription = () => {
      if (subscriptionInfo?.active) {
         return <ActiveSubscription />;
      }
      return <PriceTable />;
   };

   return (
      <Grid
         container={true}
         justifyContent="center"
         sx={{
            minHeight: "100%",
         }}
         data-testid="subscription-panel"
      >
         <Grid
            item={true}
            sm={8}
            sx={{
               py: "2em",
               borderRadius: "8px",
               backgroundColor: "#FFFFFF",
            }}
         >
            {renderSubscription()}
         </Grid>
      </Grid>
   );
};
