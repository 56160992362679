import { FC, useCallback, useEffect, useState } from "react";
import {
   INSERT_CHECK_LIST_COMMAND,
   INSERT_ORDERED_LIST_COMMAND,
   INSERT_UNORDERED_LIST_COMMAND,
   REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
   $createHeadingNode,
   $createQuoteNode,
   HeadingTagType,
} from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { mergeRegister } from "@lexical/utils";
import {
   $createParagraphNode,
   $getSelection,
   $isRangeSelection,
   COMMAND_PRIORITY_CRITICAL,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import _ from "lodash";
import { ToolbarDropDown } from "../widgets/ToolbarDropDown";
import { ToolbarDropDownOption } from "../widgets/ToolbarDropDownOption";
import { IconType } from "../widgets/ToolbarIcon";
import { getSelectionElementType } from "./utils/nodes";

enum FormatCode {
   paragraph = "paragraph",
   h1 = "h1",
   h2 = "h2",
   h3 = "h3",
   bullet = "bullet",
   number = "number",
   check = "check",
   quote = "quote",
}

enum FormatLabel {
   paragraph = "Normal",
   h1 = "Heading 1",
   h2 = "Heading 2",
   h3 = "Heading 3",
   bullet = "Bullet List",
   number = "Numbered List",
   check = "Check List",
   quote = "Quote",
}

enum Icon {
   paragraph = IconType.PARAGRAPH,
   h1 = IconType.TYPE_H1,
   h2 = IconType.TYPE_H2,
   h3 = IconType.TYPE_H3,
   bullet = IconType.BULLET_LIST,
   number = IconType.NUMBERED_LIST,
   check = IconType.CHECK_LIST,
   quote = IconType.QUOTE,
}

interface BlockFormatProps {
   initBlockType?: string;
}

export const BlockFormat: FC<BlockFormatProps> = ({
   initBlockType = FormatCode.paragraph,
}) => {
   const [editor] = useLexicalComposerContext();
   const [currentblockType, setCurrentBlockType] = useState(initBlockType);

   const updateToolbar = useCallback(() => {
      const selection = $getSelection();
      const type = getSelectionElementType(editor, selection);
      if (type) {
         setCurrentBlockType(type);
      }
   }, [editor]);

   useEffect(() => {
      return mergeRegister(
         editor.registerCommand<void>(
            SELECTION_CHANGE_COMMAND,
            () => {
               updateToolbar();
               return false;
            },
            COMMAND_PRIORITY_CRITICAL
         ),
         editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
               updateToolbar();
            });
         })
      );
   }, [editor, updateToolbar]);

   const formatParagraph = () => {
      if (currentblockType !== FormatCode.paragraph) {
         editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
               $wrapNodes(selection, () => $createParagraphNode());
            }
         });
      }
   };

   const formatQuote = () => {
      if (currentblockType !== FormatCode.quote) {
         editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
               $wrapNodes(selection, () => $createQuoteNode());
            }
         });
      }
   };

   const formatHeading = (headingSize: HeadingTagType) => {
      if (currentblockType !== headingSize) {
         editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
               $wrapNodes(selection, () => $createHeadingNode(headingSize));
            }
         });
      }
   };

   const formatBulletList = () => {
      if (currentblockType !== FormatCode.bullet) {
         editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      } else {
         editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
   };

   const formatNumberedList = () => {
      if (currentblockType !== FormatCode.number) {
         editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      } else {
         editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
   };

   const formatCheckList = () => {
      if (currentblockType !== FormatCode.check) {
         editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
      } else {
         editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
   };

   const getCurrentLabel = () => {
      return _.get(FormatLabel, currentblockType);
   };

   const getCurrentIcon = () => {
      return _.get(Icon, currentblockType);
   };

   const isActive = (blockType: string) => {
      return _.isEqual(blockType, currentblockType);
   };

   return (
      <div data-testid="block-formatting">
         <ToolbarDropDown label={getCurrentLabel()} icon={getCurrentIcon()}>
            <ToolbarDropDownOption
               text={FormatLabel.paragraph}
               icon={IconType.PARAGRAPH}
               onClick={formatParagraph}
               active={isActive(FormatCode.paragraph)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.h1}
               icon={IconType.TYPE_H1}
               onClick={() => formatHeading("h1")}
               active={isActive(FormatCode.h1)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.h2}
               icon={IconType.TYPE_H2}
               onClick={() => formatHeading("h2")}
               active={isActive(FormatCode.h2)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.h3}
               icon={IconType.TYPE_H3}
               onClick={() => formatHeading("h3")}
               active={isActive(FormatCode.h3)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.bullet}
               icon={IconType.BULLET_LIST}
               onClick={formatBulletList}
               active={isActive(FormatCode.bullet)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.number}
               icon={IconType.NUMBERED_LIST}
               onClick={formatNumberedList}
               active={isActive(FormatCode.number)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.check}
               icon={IconType.CHECK_LIST}
               onClick={formatCheckList}
               active={isActive(FormatCode.check)}
            />
            <ToolbarDropDownOption
               text={FormatLabel.quote}
               icon={IconType.QUOTE}
               onClick={formatQuote}
               active={isActive(FormatCode.quote)}
            />
         </ToolbarDropDown>
      </div>
   );
};
