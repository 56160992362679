import { Box } from "@mui/material";
import _ from "lodash";
import { menuGroups } from "./menuGroups";
import { NavGroup } from "./navgroup/NavGroup";

export const MenuList = () => {
   const navGroups = () => {
      return _.map(menuGroups, (group) => {
         return <NavGroup key={group.id} group={group} />;
      });
   };

   return <Box data-testid="menu-list">{navGroups()}</Box>;
};
