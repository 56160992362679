import { FC } from "react";
import {
   Box,
   Button,
   Card,
   CardContent,
   Stack,
   Typography,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import _ from "lodash";
import { DLandingPagePrice } from "./types";

interface PriceProsp {
   price: DLandingPagePrice;
}

export const Price: FC<PriceProsp> = ({ price }) => {
   const { keycloak } = useKeycloak();

   const signup = async () => {
      keycloak.register();
   };

   const currency = () => {
      return _.upperCase(price.currency);
   };

   const pricePerUnit = () => {
      return `${currency()} ${price.price}`;
   };

   const planPrice = () => {
      return (
         <Stack spacing={1}>
            <Stack
               direction="row"
               justifyContent="center"
               alignItems="center"
               spacing={0.5}
               data-testid="plan-price"
            >
               <Typography sx={{ fontWeight: 500, fontSize: "2.3em" }}>
                  {pricePerUnit()}
               </Typography>
               <Typography
                  sx={{
                     fontSize: "0.8em",
                     color: "rgb(26, 26, 26)",
                  }}
               >
                  per
                  <br />
                  {price.period}
               </Typography>
            </Stack>
            <Stack justifyContent="center" data-testid="plan-comment">
               <Typography align="center">{price.comment}</Typography>
            </Stack>
         </Stack>
      );
   };

   const signUpBtn = () => {
      return (
         <Button
            size="large"
            variant="contained"
            fullWidth={true}
            onClick={signup}
            sx={{ borderRadius: "5px" }}
            data-testid="sign-up-btn"
         >
            {price.signUpLabel}
         </Button>
      );
   };

   const planFeature = (feature: string, index: number) => {
      return (
         <Typography
            sx={{ fontSize: "1.3em" }}
            key={index}
            data-testid={`plan-feature-${index}`}
         >
            {feature}
         </Typography>
      );
   };

   const planFeatures = () => {
      return (
         <Stack alignItems="center" spacing={0.7} data-testid="plan-features">
            {_.map(price.features, (f, i) => planFeature(f, i))}
         </Stack>
      );
   };

   return (
      <Card
         variant="elevation"
         sx={{
            m: "1em",
            p: "1em",
            minWidth: 300,
            height: "90%",
            backgroundColor: "secondary.light",
         }}
         data-testid="price"
      >
         <CardContent data-testid="price-content">
            <Stack spacing={4}>
               <Typography
                  align="center"
                  sx={{ fontWeight: 700, fontSize: "2em" }}
                  data-testid="plan-title"
               >
                  {price.title}
               </Typography>
               <Stack spacing={3}>
                  <Stack spacing={5}>
                     {planPrice()}
                     {signUpBtn()}
                  </Stack>
                  {planFeatures()}
               </Stack>
            </Stack>
         </CardContent>
      </Card>
   );
};
