import { FC } from "react";
import { ToolbarItem, ToolbarItemProps } from "./ToolbarItem";

interface ToolbarButtonProps extends ToolbarItemProps {
   active?: boolean;
}

export const ToolbarButton: FC<ToolbarButtonProps> = ({
   children,
   spaced,
   active,
   sx,
   ...rest
}) => {
   return (
      <ToolbarItem
         component="button"
         data-testid="toolbar-button"
         spaced={spaced}
         sx={{
            backgroundColor: active ? "#dfe8fa4d" : "#fff",
            ...sx,
         }}
         {...rest}
      >
         {children}
      </ToolbarItem>
   );
};
