import { FC, useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
   Box,
   Collapse,
   List,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { borderRadius } from "@prmthemes";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { MenuGroup, MenuItem } from "../../types";
import { NavItem } from "../navitem/NavItem";

interface NavCollapseProps {
   group: MenuGroup;
   level: number;
}

export const NavCollapse: FC<NavCollapseProps> = ({ group, level }) => {
   const theme = useTheme();
   const { pathname } = useLocation();
   const [open, setOpen] = useState(false);
   const [selected, setSelected] = useState<string | null>(null);

   const ensureSelectionHighlighted = (items: MenuItem[] | undefined) => {
      _.forEach(items, (item) => {
         if (item.url === pathname) {
            setSelected(group.id);
            setOpen(true);
         }
      });
   };

   // menu collapse for sub-levels
   useEffect(() => {
      setOpen(false);
      setSelected(null);

      ensureSelectionHighlighted(group.items);
      _.forEach(group.subGroups, (subGroup) => {
         ensureSelectionHighlighted(subGroup.items);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pathname, group.subGroups, group.items]);

   const handleClick = () => {
      setOpen(!open);
      setSelected(!selected ? group.id : null);
   };

   const itemIcon = () => {
      if (group.icon) {
         const Icon = group.icon;
         return (
            <Icon
               strokeWidth={1.5}
               size="1.3rem"
               style={{ marginTop: "auto", marginBottom: "auto" }}
               data-testid="icon"
            />
         );
      }

      return (
         <FiberManualRecordIcon
            sx={{
               width: selected === group.id ? 8 : 6,
               height: selected === group.id ? 8 : 6,
            }}
            fontSize={level > 0 ? "inherit" : "medium"}
            data-testid="manual-recored-icon"
         />
      );
   };

   const listItemIcon = () => {
      return (
         <ListItemIcon
            sx={{ my: "auto", minWidth: !group.icon ? 18 : 36 }}
            data-testid="list-item-icon"
         >
            {itemIcon()}
         </ListItemIcon>
      );
   };

   const primaryItemText = () => {
      return (
         <Typography
            variant={selected === group.id ? "h5" : "body1"}
            color="inherit"
            sx={{ my: "auto" }}
            data-testid="text-primary"
         >
            {group.title}
         </Typography>
      );
   };

   const secondaryItemText = () => {
      if (group.caption) {
         return (
            <Typography
               variant="caption"
               sx={{ ...theme.typography.subMenuCaption }}
               display="block"
               gutterBottom={true}
               data-testid="text-secondary"
            >
               {group.caption}
            </Typography>
         );
      }
      return null;
   };

   const listItemText = () => {
      return (
         <ListItemText
            primary={primaryItemText()}
            secondary={secondaryItemText()}
            data-testid="list-item-text"
         />
      );
   };

   const chevronIcon = () => {
      if (open) {
         return (
            <IconChevronUp
               stroke={1.5}
               size="1rem"
               style={{ marginTop: "auto", marginBottom: "auto" }}
               data-testid="chevron-open"
            />
         );
      }
      return (
         <IconChevronDown
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
            data-testid="chevron-closed"
         />
      );
   };

   const subGroupMenus = () => {
      return _.map(group.subGroups, (subGroup) => {
         return (
            <NavCollapse key={subGroup.id} group={subGroup} level={level + 1} />
         );
      });
   };

   const subItemMenus = () => {
      return _.map(group.items, (item) => {
         return <NavItem key={item.id} item={item} level={level + 1} />;
      });
   };

   const collapsableList = () => {
      return (
         <List
            component="div"
            disablePadding
            sx={{
               position: "relative",
               "&:after": {
                  content: "''",
                  position: "absolute",
                  left: "32px",
                  top: 0,
                  height: "100%",
                  width: "1px",
                  opacity: 1,
                  background: theme.palette.primary.light,
               },
            }}
            data-testid="list"
         >
            {subGroupMenus()}
            {subItemMenus()}
         </List>
      );
   };

   return (
      <Box data-testid="nav-collapse">
         <ListItemButton
            sx={{
               borderRadius: `${borderRadius}px`,
               mb: 0.5,
               alignItems: "flex-start",
               backgroundColor:
                  level > 1 ? "transparent !important" : "inherit",
               py: level > 1 ? 1 : 1.25,
               pl: `${level * 24}px`,
            }}
            selected={selected === group.id}
            onClick={handleClick}
            data-testid="nav-collapsable-item"
         >
            {listItemIcon()}
            {listItemText()}
            {chevronIcon()}
         </ListItemButton>
         <Collapse
            in={open}
            timeout="auto"
            unmountOnExit={true}
            data-testid="collapsable-list"
         >
            {collapsableList()}
         </Collapse>
      </Box>
   );
};
