import { FC, useState } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { toTestId } from "@utils";
import {
   DailyRepeat,
   FREQUENCIES,
   Frequency,
   MonthlyRepeat,
   RepeatConfig,
   WeeklyRepeat,
   YearlyRepeat,
} from "../types";
import { Daily } from "./daily/Daily";
import { Monthly } from "./monthly/Monthly";
import { Weekly } from "./weekly/Weekly";
import { Yearly } from "./yearly/Yearly";

interface RepeatProps {
   config: RepeatConfig;
   onChange: (update: RepeatConfig) => void;
}

export const Repeat: FC<RepeatProps> = ({ config, onChange }) => {
   const [frequency, setFrequency] = useState<Frequency>(config.frequency);
   const [yearly, setYearly] = useState<YearlyRepeat>(config.yearly);
   const [monthly, setMonthly] = useState<MonthlyRepeat>(config.monthly);
   const [weekly, setWeekly] = useState<WeeklyRepeat>(config.weekly);
   const [daily, setDaily] = useState<DailyRepeat>(config.daily);

   const updatedConfig = (
      uFrequency: Frequency,
      uYearly: YearlyRepeat,
      uMonthly: MonthlyRepeat,
      uWeekly: WeeklyRepeat,
      uDaily: DailyRepeat
   ) => {
      const newConfig: RepeatConfig = {
         frequency: uFrequency,
         yearly: uYearly,
         monthly: uMonthly,
         weekly: uWeekly,
         daily: uDaily,
      };
      onChange(newConfig);
   };

   const updateFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as Frequency;
      setFrequency(value);
      updatedConfig(value, yearly, monthly, weekly, daily);
   };

   const updateYearly = (newYearly: YearlyRepeat) => {
      updatedConfig(frequency, newYearly, monthly, weekly, daily);
   };

   const updateMonthly = (newMonthly: MonthlyRepeat) => {
      updatedConfig(frequency, yearly, newMonthly, weekly, daily);
   };

   const updateWeekly = (newWeekly: WeeklyRepeat) => {
      updatedConfig(frequency, yearly, monthly, newWeekly, daily);
   };

   const updateDaily = (newDaily: DailyRepeat) => {
      updatedConfig(frequency, yearly, monthly, weekly, newDaily);
   };

   const frequencySelector = () => {
      return (
         <TextField
            id="select-frequency"
            select={true}
            label="Repeat"
            value={frequency}
            onChange={updateFrequency}
            sx={{ width: "8em" }}
            data-testid="select-frequency"
         >
            {FREQUENCIES.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`frequency-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const yearlyRepeat = () => {
      return <Yearly config={yearly} onChange={updateYearly} />;
   };

   const monthlyRepeat = () => {
      return <Monthly config={monthly} onChange={updateMonthly} />;
   };

   const weeklyRepeat = () => {
      return <Weekly config={weekly} onChange={updateWeekly} />;
   };

   const dailyRepeat = () => {
      return <Daily config={daily} onChange={updateDaily} />;
   };

   const inputs = () => {
      if (frequency === "Monthly") {
         return monthlyRepeat();
      }
      if (frequency === "Weekly") {
         return weeklyRepeat();
      }
      if (frequency === "Daily") {
         return dailyRepeat();
      }
      return yearlyRepeat();
   };

   return (
      <Grid container={true} gap={1} data-testid="recurrence-repeat">
         <Grid item={true} xs={2}>
            {frequencySelector()}
         </Grid>
         <Grid item={true} xs={9}>
            {inputs()}
         </Grid>
      </Grid>
   );
};
