import _ from "lodash";
import { Options, Weekday } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeWeeklyDays = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   let weekdays: number[] = [];

   if (rruleObj.freq !== 2) {
      return data.repeat.weekly.days;
   }

   if (rruleObj.byweekday && _.isArray(rruleObj.byweekday)) {
      const bwks = rruleObj.byweekday as Weekday[];
      weekdays = _.map(bwks, (bwk) => bwk.weekday);
   }

   return {
      mon: weekdays.includes(0),
      tue: weekdays.includes(1),
      wed: weekdays.includes(2),
      thu: weekdays.includes(3),
      fri: weekdays.includes(4),
      sat: weekdays.includes(5),
      sun: weekdays.includes(6),
   };
};
