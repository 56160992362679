import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { CallbackFn } from "@domain/common";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
   Box,
   Button,
   InputBaseProps,
   MenuItem,
   TextField,
} from "@mui/material";
import { CategorizedInputValueInternal } from "./CategorizedInputs";

export interface CategorizedInputProps extends InputBaseProps {
   field: string;
   label: string;
   categoryLabel: string;
   categoryOptions: string[];
   value: CategorizedInputValueInternal;
   onValueChange: (newValue: CategorizedInputValueInternal) => void;
   onAdd?: CallbackFn;
   onRemove?: (value: CategorizedInputValueInternal) => void;
}

export const CategorizedInput: FC<CategorizedInputProps> = ({
   field,
   label,
   categoryLabel,
   categoryOptions,
   type,
   value,
   onBlur,
   onValueChange,
   onAdd,
   onRemove,
}) => {
   const [inputValue, setInputValue] = useState(value.value);
   const [typeValue, setTypeValue] = useState(value.type);

   const currentValue = useCallback(() => {
      return { value: inputValue, type: typeValue, id: value.id };
   }, [inputValue, typeValue, value.id]);

   useEffect(() => {
      const timeOutId = setTimeout(() => onValueChange(currentValue()), 500);
      return () => clearTimeout(timeOutId);
   }, [onValueChange, currentValue]);

   const onIputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
   };

   const onTypeValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setTypeValue(value);
      onValueChange(currentValue());
   };

   const textField = () => {
      return (
         <TextField
            id={field}
            variant="outlined"
            type={type}
            label={label}
            value={inputValue}
            onChange={onIputValueChange}
            onBlur={onBlur}
            sx={{
               flexBasis: { xs: "65%", lg: "75%" },
            }}
            data-testid={`input-${field}`}
         />
      );
   };

   const select = () => {
      return (
         <TextField
            id={field}
            variant="outlined"
            select={true}
            label={categoryLabel}
            value={typeValue}
            onChange={onTypeValueChange}
            sx={{
               flexBasis: { xs: "35%", lg: "25%" },
            }}
            data-testid={`type-${field}`}
         >
            <MenuItem key="none" value="">
               <em>None</em>
            </MenuItem>
            {categoryOptions.map((option, i) => (
               <MenuItem key={i} value={option}>
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const addBtn = () => {
      if (onAdd) {
         return (
            <Button
               variant="text"
               size="small"
               onClick={onAdd}
               sx={{
                  minWidth: "0",
               }}
               className="categorized-input-btn"
               data-testid="btn-add"
            >
               <AddIcon />
            </Button>
         );
      }
      return null;
   };

   const removeBtn = () => {
      if (onRemove) {
         return (
            <Button
               variant="text"
               size="small"
               onClick={() => onRemove(currentValue())}
               sx={{
                  minWidth: "0",
               }}
               className="categorized-input-btn"
               data-testid="btn-remove"
            >
               <RemoveIcon />
            </Button>
         );
      }
      return null;
   };

   const buttons = () => {
      return (
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
            }}
         >
            {removeBtn()}
            {addBtn()}
         </Box>
      );
   };

   const fields = () => {
      return (
         <Box
            sx={{
               display: "flex",
               gap: "0.5em",
               width: "100%",
            }}
         >
            {textField()}
            {select()}
         </Box>
      );
   };

   return (
      <Box
         data-testid="categorized-input"
         sx={{
            display: "flex",
            gap: "0.5em",
            alignItems: "center",
            width: { xs: "100%", lg: "50%" },
            "& .categorized-input-btn": {
               visibility: "hidden",
            },
            "&:hover .categorized-input-btn": {
               visibility: "visible",
            },
         }}
      >
         {fields()}
         {buttons()}
      </Box>
   );
};
