import { FC } from "react";
import { Box } from "@mui/material";
import { Alignment } from "./components/Alignment";
import { BackgroundColor } from "./components/BackgroundColor";
import { BlockFormat } from "./components/BlockFormat";
import { BoldButton } from "./components/BoldButton";
import { FontFamily } from "./components/FontFamily";
import { FontSize } from "./components/FontSize";
import { ItalicButton } from "./components/ItalicButton";
import { RedoButton } from "./components/RedoButton";
import { TextColor } from "./components/TextColor";
import { TextFormat } from "./components/TextFormat";
import { UnderlineButton } from "./components/UnderlineButton";
import { UndoButton } from "./components/UndoButton";
import { ToolbarDivider } from "./widgets/ToolbarDivider";

interface ToolbarPluginProps {
   visible: boolean;
}

export const ToolbarPlugin: FC<ToolbarPluginProps> = ({ visible }) => {
   const render = () => {
      if (visible) {
         return (
            <Box
               data-testid="toolbar-plugin"
               sx={{
                  display: "flex",
                  mb: "1px",
                  p: "4px",
                  background: "#fff",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  verticalAlign: "middle",
               }}
            >
               <UndoButton />
               <RedoButton />
               <ToolbarDivider />
               <BlockFormat />
               <ToolbarDivider />
               <FontFamily />
               <ToolbarDivider />
               <FontSize />
               <ToolbarDivider />
               <BoldButton />
               <ItalicButton />
               <UnderlineButton />
               <TextColor />
               <BackgroundColor />
               <TextFormat />
               <ToolbarDivider />
               <Alignment />
            </Box>
         );
      }
      return null;
   };

   return render();
};
