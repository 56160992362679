import { Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { Feature } from "./Feature";
import { DFeature } from "./types";

const features: DFeature[] = [
   {
      title: "User-Friendly Interface",
      description:
         "Intuitive design that allows users to quickly add, organize, and access contacts",
   },
   {
      title: "Customizable Categories",
      description:
         "Easily categorize contacts by industry, location, or relationship status to enhance your networking strategy",
   },
   {
      title: "Interaction Tracking",
      description:
         "Record notes, set reminders, and log interactions to keep track of your relationships, what they are looking for and how you can help them",
   },
   {
      title: "Referral Management",
      description:
         "Simplify the referral process by tracking referrals made and received, ensuring that no opportunity is missed",
   },
   {
      title: "Build Stronger Connections",
      description:
         "Foster deeper relationships through organized communication and timely follow-ups",
   },
   {
      title: "Maximize Opportunities",
      description:
         " Leverage your network effectively and generate more leads and referrals",
   },
];

export const Features = () => {
   const feature = (feature: DFeature, index: number) => {
      return (
         <Grid item={true} key={index}>
            <Feature feature={feature} />
         </Grid>
      );
   };

   const featureCards = () => {
      return _.map(features, (f, i) => feature(f, i));
   };

   return (
      <Stack
         id="features"
         justifyContent="center"
         alignItems="center"
         spacing={3}
         data-testid="features"
      >
         <Typography variant="h1" component="h1" gutterBottom={true}>
            Features
         </Typography>
         <Grid
            container={true}
            justifyContent={{ xs: "space-evenly", lg: "space-between" }}
         >
            {featureCards()}
         </Grid>
      </Stack>
   );
};
