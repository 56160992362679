import { RRule } from "rrule";
import { RecurrenceConfig } from "../../../types";
import { computeEnd } from "./computeEnd";
import { computeOptions } from "./computeOptions";
import { computeRepeat } from "./computeRepeat";
import { computeStart } from "./computeStart";

export const computeRRule = ({
   start,
   repeat,
   end,
   options,
}: RecurrenceConfig) => {
   const rruleObject = {
      ...computeStart(start),
      ...computeRepeat(repeat),
      ...computeEnd(end),
      ...computeOptions(options),
   };
   const rrule = new RRule(rruleObject);
   return rrule.toString();
};
