import { FC } from "react";
import { Box, Divider, List, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { MenuGroup } from "../../types";
import { NavCollapse } from "../navcollapse/NavCollapse";
import { NavItem } from "../navitem/NavItem";

interface NavGroup {
   group: MenuGroup;
}

export const NavGroup: FC<NavGroup> = ({ group }) => {
   const theme = useTheme();

   const subGroupMenus = () => {
      return _.map(group.subGroups, (subGroup) => {
         return <NavCollapse key={subGroup.id} group={subGroup} level={1} />;
      });
   };

   const subItemMenus = () => {
      return _.map(group.items, (item) => {
         return <NavItem key={item.id} item={item} level={1} />;
      });
   };

   const caption = () => {
      if (group.caption) {
         return (
            <Typography
               variant="caption"
               sx={{ ...theme.typography.subMenuCaption }}
               display="block"
               gutterBottom={true}
               data-testid="caption"
            >
               {group.caption}
            </Typography>
         );
      }
      return null;
   };

   const subheader = () => {
      return (
         <Typography
            variant="caption"
            sx={{ ...theme.typography.menuCaption }}
            display="block"
            gutterBottom={true}
            data-testid="title"
         >
            {group.title}
            {caption()}
         </Typography>
      );
   };

   return (
      <Box data-testid="nav-group">
         <List subheader={subheader()} data-testid="list">
            {subGroupMenus()}
            {subItemMenus()}
         </List>
         <Divider sx={{ mt: 0.25, mb: 1.25 }} data-testid="divider" />
      </Box>
   );
};
