import { FC } from "react";
import Box from "@mui/material/Box";
import _ from "lodash";
import { Link } from "react-scroll";
import headlineCurve from "@assets/img/pages/landing/headline-curve.svg";

const navigations = [
   {
      label: "Home",
      path: "#",
   },
   {
      label: "Features",
      path: "features",
   },
   {
      label: "Pricing",
      path: "pricing",
   },
];

export const Navigation: FC = () => {
   const link = (destination: string, label: string) => {
      return (
         <Box
            component={Link}
            key={destination}
            activeClass="current"
            to={destination}
            spy={true}
            smooth={true}
            duration={500}
            sx={{
               position: "relative",
               color: "text.disabled",
               cursor: "pointer",
               fontWeight: 600,
               display: "inline-flex",
               alignItems: "center",
               justifyContent: "center",
               px: { xs: 0, md: 3 },
               mb: { xs: 3, md: 0 },
               fontSize: { xs: "1.2rem", md: "inherit" },
               ...(destination === "/" && {
                  color: "primary.main",
               }),
               "& > div": { display: "none" },
               "&.current>div": { display: "block" },
               "&:hover": {
                  color: "primary.main",
                  "&>div": {
                     display: "block",
                  },
               },
            }}
         >
            <Box
               sx={{
                  position: "absolute",
                  top: 12,
                  transform: "rotate(3deg)",
                  "& img": { width: 44, height: "auto" },
               }}
            >
               <img src={headlineCurve} alt="Headline curve" />
            </Box>
            {label}
         </Box>
      );
   };

   const links = () => {
      return _.map(navigations, (nav) => link(nav.path, nav.label));
   };

   return (
      <Box
         data-testid="navigation"
         sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
      >
         {links()}
      </Box>
   );
};
