import { Options, RRule } from "rrule";
import { RecurrenceConfigOptions } from "../../../types";

export const computeOptions = ({
   weekStartsOnSunday,
}: RecurrenceConfigOptions): Partial<Options> => {
   const options: Partial<Options> = {};

   if (weekStartsOnSunday) {
      options.wkst = RRule.SU;
   }
   return options;
};
