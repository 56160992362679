import { loadable } from "react-lazily/loadable";
import { RouteObject } from "react-router-dom";
import { MainPage } from "../../pages/main/MainPage";
import { SubscriptionPanel } from "../../prm/subscription/panels/SubscriptionPanel";
import { Loader } from "./Loader";

const { DashboardPanel } = loadable(
   () => import("../../prm/dashboard/panels/DashboardPanel"),
   {
      fallback: <Loader />,
   }
);

const { Contacts } = loadable(
   () => import("../../prm/contacts/tables/Contacts"),
   {
      fallback: <Loader />,
   }
);

const { ContactPanel } = loadable(
   () => import("../../prm/contacts/panels/ContactPanel"),
   {
      fallback: <Loader />,
   }
);

const { NewContactPanel } = loadable(
   () => import("../../prm/contacts/panels/NewContactPanel"),
   {
      fallback: <Loader />,
   }
);

const { SettingsPanel } = loadable(
   () => import("../../prm/settings/panels/SettingsPanel"),
   {
      fallback: <Loader />,
   }
);

export const MainRoutes: RouteObject = {
   path: "/",
   element: <MainPage />,
   children: [
      {
         path: "/",
         element: <Contacts />,
      },
      {
         path: "/dashboard",
         children: [
            {
               path: "/dashboard",
               element: <DashboardPanel />,
            },
         ],
      },
      {
         path: "/contacts",
         children: [
            { path: "/contacts", element: <Contacts /> },
            {
               path: ":id",
               element: <ContactPanel />,
            },
            {
               path: "new",
               element: <NewContactPanel />,
            },
         ],
      },
      {
         path: "/settings",
         element: <SettingsPanel />,
      },
      {
         path: "/subscription",
         element: <SubscriptionPanel />,
      },
   ],
};
