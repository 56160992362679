import { FC } from "react";
import { Box } from "@mui/material";

export const ToolbarDivider: FC = () => {
   return (
      <Box
         data-testid="toolbar-divider"
         sx={{
            width: "1px",
            backgroundColor: "#eee",
            m: "0 4px"
         }}
      />
   );
};
