import { FC } from "react";
import { Box, BoxProps, Button, ButtonProps } from "@mui/material";
import _ from "lodash";
import { toTestId } from "../../../utils/common";

export interface PaneActionProps extends ButtonProps {
   label: string;
}

export interface PaneProps extends BoxProps {
   title?: string;
   actions?: PaneActionProps[];
   children: JSX.Element | JSX.Element[];
   styles?: {
      actions?: { mt?: string };
   };
}

export const Pane: FC<PaneProps> = ({
   title,
   actions = [],
   children,
   styles: panesx,
   ...boxProps
}) => {
   const actionBtn = (props: PaneActionProps, index: number) => {
      const { label } = props;
      return (
         <Button
            data-testid={`act-btn-${toTestId(label)}`}
            key={index}
            {...props}
         >
            {label}
         </Button>
      );
   };

   const actionBtns = () => {
      return _.map(actions, (action, i) => actionBtn(action, i));
   };

   const paneTitle = () => {
      if (title) {
         return <Box data-testid="pane-title">{title}</Box>;
      }
      return null;
   };

   const paneContent = () => {
      return <Box data-testid="pane-content">{children}</Box>;
   };

   const paneActions = () => {
      if (!_.isEmpty(actions)) {
         return (
            <Box
               data-testid="pane-actions"
               sx={{
                  mt: "1em",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0.5em",
                  ...panesx?.actions,
               }}
            >
               {actionBtns()}
            </Box>
         );
      }
      return null;
   };

   return (
      <Box data-testid="pane" {...boxProps}>
         {paneTitle()}
         {paneContent()}
         {paneActions()}
      </Box>
   );
};
