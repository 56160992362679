import { FC, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { DailyRepeat } from "../../types";

interface DailyProps {
   config: DailyRepeat;
   onChange: (update: DailyRepeat) => void;
}

export const Daily: FC<DailyProps> = ({
   config: { interval: initInterval },
   onChange,
}) => {
   const [interval, setInterval] = useState<number>(initInterval);

   const updatedConfig = (interval: number) => {
      const update: DailyRepeat = {
         interval: interval,
      };
      onChange(update);
   };

   const updateInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setInterval(value);
      updatedConfig(value);
   };

   const intervalInput = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{
               justifyContent: "center",
               alignItems: "center",
            }}
            data-testid="executions-interval"
         >
            <Grid item={true}>
               <Typography>every</Typography>
            </Grid>
            <Grid item={true}>
               <TextField
                  id="interval"
                  type="number"
                  label="After"
                  value={interval}
                  onChange={updateInterval}
                  data-testid="interval"
               />
            </Grid>
            <Grid item={true}>
               <Typography>day(s)</Typography>
            </Grid>
         </Grid>
      );
   };

   return (
      <Grid container={true} gap={1} data-testid="daily-repeat">
         <Grid item={true}>{intervalInput()}</Grid>
      </Grid>
   );
};
