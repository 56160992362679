import { RepeatConfig } from "../../../types";
import { computeDaily } from "./computeDaily";
import { computeMonthly } from "./computeMonthly";
import { computeWeekly } from "./computeWeekly";
import { computeYearly } from "./computeYearly";

export const computeRepeat = ({
   frequency,
   yearly,
   monthly,
   weekly,
   daily,
}: RepeatConfig) => {
   switch (frequency) {
      case "Yearly": {
         return computeYearly(yearly);
      }
      case "Monthly": {
         return computeMonthly(monthly);
      }
      case "Weekly": {
         return computeWeekly(weekly);
      }
      case "Daily": {
         return computeDaily(daily);
      }
   }
};
