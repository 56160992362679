import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export const Loader = () => (
   <Box
      sx={{ position: "fixed", top: 0, left: 0, zIndex: 1301, width: "100%" }}
      data-testid="loader"
   >
      <LinearProgress color="primary" data-testid="linear-progress" />
   </Box>
);
