import { Dayjs } from "dayjs";
import { FormikErrors, FormikProps } from "formik";
import _ from "lodash";
import { isValidDate } from "../../utils/dateUtils";
import { CategorizedInputValue } from "../widgets/categorizedInput/CategorizedInputs";

export enum FormStatus {
   SUBMIT_ERRORS,
}

export enum MessageType {
   INFO = "Info",
   WARNING = "Warning",
   ERROR = "Error",
}

export class FormHandler<T> {
   private formik: FormikProps<T>;

   constructor(formik: FormikProps<T>) {
      this.formik = formik;
   }

   resolveFieldValue(field: string) {
      return this.readFieldValue(field);
   }

   message(field: string): string {
      if (!this.isErrorField(field)) {
         return "";
      }
      const msg = this.readFieldError(field);
      if (_.startsWith(msg, MessageType.INFO)) {
         const prefix = `${MessageType.INFO}:`;
         return _.replace(msg, prefix, "");
      } else if (_.startsWith(msg, MessageType.WARNING)) {
         const prefix = `${MessageType.WARNING}:`;
         return _.replace(msg, prefix, "");
      } else if (_.startsWith(msg, MessageType.ERROR)) {
         const prefix = `${MessageType.ERROR}:`;
         return _.replace(msg, prefix, "");
      }
      return msg;
   }

   messageType(field: string): string | undefined {
      if (!this.isErrorField(field)) {
         return undefined;
      }
      const msg = this.readFieldError(field);
      if (_.startsWith(msg, MessageType.INFO)) {
         return MessageType.INFO;
      } else if (_.startsWith(msg, MessageType.WARNING)) {
         return MessageType.WARNING;
      } else if (_.startsWith(msg, MessageType.ERROR)) {
         return MessageType.ERROR;
      }
      return MessageType.ERROR;
   }

   async canSubmit(): Promise<boolean> {
      const valErrs = await this.validateForm();
      const hasErrors = !_.isEmpty(valErrs);
      if (hasErrors) {
         this.formik.setStatus(FormStatus.SUBMIT_ERRORS);
      }
      return !hasErrors;
   }

   async validateForm(): Promise<FormikErrors<T>> {
      return await this.formik.validateForm();
   }

   hasErrors(): boolean {
      return !_.isEmpty(this.formik.errors);
   }

   isErrorField(field: string) {
      const isFieldError = this.isFieldError(field);
      const isTouchhedAndErronous = this.isFieldTouched(field) && isFieldError;
      const isSubmitedAndErronous = this.isSubmitErrorsStatus() && isFieldError;
      return isTouchhedAndErronous || isSubmitedAndErronous;
   }

   getStatus() {
      return this.formik.status;
   }

   handleCategoryInputChange(field: string, values: CategorizedInputValue[]) {
      this.formik.setFieldValue(field, values);
   }

   handleDateChange(field: string, newDate: Dayjs | null) {
      if (isValidDate(newDate)) {
         const value = newDate?.toISOString();
         this.formik.setFieldValue(field, value);
      } else if (_.isNull(newDate)) {
         this.formik.setFieldValue(field, newDate);
      }
   }

   handleCheckBoxChange(field: string, value: string | string[] | null) {
      this.formik.setFieldValue(field, value);
   }

   private isFieldError(field: string): boolean {
      return !_.isEmpty(this.readFieldError(field));
   }

   private isFieldTouched(field: string): boolean {
      return this.readFieldTouched(field);
   }

   private readFieldValue(field: string): string | string[] | object[] {
      return _.get(this.formik.values, field);
   }

   private readFieldError(field: string): string {
      return _.get(this.formik.errors, field);
   }

   private readFieldTouched(field: string): boolean {
      return _.get(this.formik.touched, field);
   }

   private isSubmitErrorsStatus(): boolean {
      return this.formik.status === FormStatus.SUBMIT_ERRORS;
   }
}
