import _ from "lodash";
import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeYearlyOnTheWhich = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (rruleObj.freq !== 0 || !rruleObj.byweekday) {
      return data.repeat.yearly.onThe.which;
   }

   let bysetpos = 0;

   if (typeof rruleObj.bysetpos === "number") {
      bysetpos = rruleObj.bysetpos;
   }

   if (_.isArray(rruleObj.bysetpos)) {
      bysetpos = rruleObj.bysetpos[0];
   }

   switch (bysetpos) {
      case 1: {
         return "First";
      }
      case 2: {
         return "Second";
      }
      case 3: {
         return "Third";
      }
      case 4: {
         return "Fourth";
      }
      case -1: {
         return "Last";
      }
      default: {
         return data.repeat.yearly.onThe.which;
      }
   }
};
