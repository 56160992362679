import { createSlice } from "@reduxjs/toolkit";
import { UiState } from "./types";

const initialState: UiState = {
   leftDrawerOpened: true,
   openMenuId: [],
};

export const counterSlice = createSlice({
   name: "ui",
   initialState,
   reducers: {
      leftDrawerOpened: (state, action) => {
         state.leftDrawerOpened = action.payload;
      },
      openMenuId: (state, action) => {
         state.openMenuId = [action.payload];
      },
   },
});

export const { leftDrawerOpened, openMenuId } = counterSlice.actions;

export const reducer = counterSlice.reducer;
