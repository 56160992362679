import {
   AutoLinkPlugin as LexicalAutoLinkPlugin,
   createLinkMatcherWithRegExp,
} from "@lexical/react/LexicalAutoLinkPlugin";

const URL_REGEX =
   /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_REGEX =
   /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const URL_MATCHER = createLinkMatcherWithRegExp(URL_REGEX, (text) => {
   return text;
});

export const EMAIL_MATCHER = createLinkMatcherWithRegExp(
   EMAIL_REGEX,
   (text) => {
      return `mailto:${text}`;
   }
);

export const AutoLinkPlugin = () => {
   const matchers = [URL_MATCHER, EMAIL_MATCHER];
   return <LexicalAutoLinkPlugin matchers={matchers} />;
};
