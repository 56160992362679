import { FC } from "react";
import { Box, BoxProps } from "@mui/material";
import chevronDown from "@assets/toolbar/icons/chevron-down.svg";

type ToolbarChevronProps = BoxProps;

export const ToolbarChevron: FC<ToolbarChevronProps> = ({ sx, ...rest }) => {
   const getIcon = (): string => {
      return chevronDown;
   };

   return (
      <Box
         data-testid="item-chevron"
         component="span"
         sx={{
            mt: "3px",
            width: "16px",
            height: "16px",
            display: "flex",
            userSelect: "none",
            backgroundImage: `url(${getIcon()})`,
            ...sx
         }}
         {...rest}
      />
   );
};
