import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeEndAfter = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (!rruleObj.count && rruleObj.count !== 0) {
      return data.end.after;
   }
   return rruleObj.count;
};
