import { useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from "@lexical/selection";
import { Box } from "@mui/material";
import {
   $getSelection,
   $isRangeSelection,
   COMMAND_PRIORITY_LOW,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import { ColorPicker } from "../widgets/ColorPicker";
import { ToolbarDropDown } from "../widgets/ToolbarDropDown";
import { IconType } from "../widgets/ToolbarIcon";
import { getSelectionColor } from "./utils/nodes";

export const TextColor = () => {
   const [editor] = useLexicalComposerContext();
   const [currentColor, setCurrentColor] = useState<string>("#000000");

   const updateToolbar = useCallback(() => {
      const color = getSelectionColor("#000000");
      setCurrentColor(color);
   }, []);

   const styleSelectedText = useCallback(
      (styles: Record<string, string>) => {
         editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
               $patchStyleText(selection, styles);
            }
         });
      },
      [editor]
   );

   useEffect(() => {
      return editor.registerCommand<boolean>(
         SELECTION_CHANGE_COMMAND,
         () => {
            updateToolbar();
            return false;
         },
         COMMAND_PRIORITY_LOW
      );
   }, [editor, updateToolbar]);

   const styleText = (value: string) => {
      styleSelectedText({ color: value });
   };

   return (
      <Box data-testid="text-color-picker">
         <ToolbarDropDown
            icon={IconType.TEXT_COLOR}
            suppressCloseOnSelection={true}
         >
            <ColorPicker initColor={currentColor} onChange={styleText} />
         </ToolbarDropDown>
      </Box>
   );
};
