import dayjs from "dayjs";
import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeStartOnDate = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (!rruleObj.dtstart) {
      return data.start.onDate.date;
   }
   return dayjs(rruleObj.dtstart);
};
