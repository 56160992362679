import { FC, useState } from "react";
import { DatePicker } from "@components";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { toTestId } from "@utils";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { EndConfig, EndMode } from "../types";

export interface EndUpdate {
   mode: EndMode;
   until: Dayjs;
   count: number;
}

interface EndProps {
   config: EndConfig;
   onChange: (update: EndUpdate) => void;
}

export const End: FC<EndProps> = ({
   config: { mode: initMode, onDate, after: initAfter },
   onChange,
}) => {
   const [mode, setMode] = useState<EndMode>(initMode);
   const [date, setDate] = useState<Dayjs>(onDate.date);
   const [after, setAfter] = useState<number>(initAfter);

   const updatedConfig = (mode: EndMode, until: Dayjs, count: number) => {
      const update: EndUpdate = { mode, until, count };
      onChange(update);
   };

   const updateMode = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as EndMode;
      setMode(value);
      updatedConfig(value, date, after);
   };

   const updateOnDate = (newDate: Dayjs | null) => {
      if (_.isNull(newDate)) {
         newDate = dayjs().second(0).millisecond(0);
      }
      setDate(newDate);
      updatedConfig(mode, newDate, after);
   };

   const updateAfter = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setAfter(value);
      updatedConfig(mode, date, value);
   };

   const endModes: EndMode[] = ["Never", "After", "On Date"];

   const endModeSelector = () => {
      return (
         <TextField
            id="select-end-mode"
            select={true}
            label="End"
            value={mode}
            onChange={updateMode}
            sx={{ width: "8em" }}
         >
            {endModes.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`mode-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const onDatePicker = () => {
      return (
         <DatePicker
            label="On Date"
            field="end-date"
            fullWidth={true}
            value={date}
            onChange={updateOnDate}
            onClear={() => updateOnDate(null)}
         />
      );
   };

   const afterInput = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{
               justifyContent: "center",
               alignItems: "center",
            }}
            data-testid="after-executions"
         >
            <Grid item={true}>
               <TextField
                  id="after-end-mode"
                  type="number"
                  label="After"
                  value={after}
                  onChange={updateAfter}
                  data-testid="after-text-field"
               />
            </Grid>
            <Grid item={true}>
               <Typography>executions</Typography>
            </Grid>
         </Grid>
      );
   };

   const endModeInput = () => {
      if (mode === "After") {
         return afterInput();
      }
      if (mode === "On Date") {
         return onDatePicker();
      }
      return null;
   };

   return (
      <Grid container={true} gap={1} data-testid="recurrence-end">
         <Grid item={true}>{endModeSelector()}</Grid>
         <Grid item={true}>{endModeInput()}</Grid>
      </Grid>
   );
};
