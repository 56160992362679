import { composeUrl } from "@backend/utils";
import { DAccountSettings, DContactSettings } from "@domain/settings";
import { visionNotesClient } from "../axios";
import { get, put } from "../common";

const SETTTINGS_BASE_URL = "settings";
const ACCOUNT_SETTTINGS_URL = SETTTINGS_BASE_URL + "/account";
const CONTACT_SETTTINGS_URL = SETTTINGS_BASE_URL + "/contact";

export const loadAccountSettings = async (): Promise<DAccountSettings> => {
   const url = composeUrl(ACCOUNT_SETTTINGS_URL);
   return await get(visionNotesClient, url);
};

export const updateContactSettings = async (
   settings: DContactSettings
): Promise<void> => {
   const url = CONTACT_SETTTINGS_URL;
   await put(visionNotesClient, url, settings);
};

export const loadContactSettings = async (): Promise<DContactSettings> => {
   const url = composeUrl(CONTACT_SETTTINGS_URL);
   return await get(visionNotesClient, url);
};
