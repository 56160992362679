import { FC } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Navigation } from "./Navigation";

export const Footer: FC = () => {
   return (
      <Box
         component="footer"
         sx={{
            py: { xs: 4, md: 6 },
            backgroundColor: "primary.main",
         }}
         data-testid="footer"
      >
         <Container>
            <Grid container={true} spacing={1}>
               <Grid item xs={12} md={9}>
                  <Grid
                     container={true}
                     item={true}
                     justifyContent={{ xs: "center", md: "start" }}
                     sx={{
                        mb: { xs: 3, md: 0 },
                     }}
                  >
                     <Typography
                        variant="h2"
                        sx={{ mb: 2, color: "primary.contrastText" }}
                     >
                        VisionNotes
                     </Typography>
                     <Typography
                        sx={{
                           letterSpacing: 1,
                           mb: 2,
                           color: "primary.contrastText",
                        }}
                     >
                        VisionNotes an innovative tool designed to help
                        professionals manage their networking connections with
                        ease and efficiency.
                     </Typography>
                  </Grid>
               </Grid>
               <Grid item={true} xs={12} md={3}>
                  <Navigation />
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
};
