import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeMonthlyInterval = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
): number => {
   if (rruleObj.freq !== 1) {
      return data.repeat.monthly.interval;
   }
   return rruleObj.interval || 0;
};
