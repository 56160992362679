import { createTheme, ThemeOptions } from "@mui/material/styles";
import { borderRadius, fontFamily } from "@prmthemes";
import { componentsOverrides } from "./componentsOverrides";
import { themePalette } from "./palette";
import { CSSModuleClasses, PrmThemeOptions } from "./type";
import { themeTypography } from "./typography";
import colors from "@assets/scss/_themes-vars.module.scss";

export const theme = () => {
   const color = colors as CSSModuleClasses;

   const prmThemeOption: PrmThemeOptions = {
      mode: "light",
      colors: color,
      heading: color.grey900,
      paper: color.paper,
      backgroundDefault: color.paper,
      background: color.primaryLight,
      darkTextPrimary: color.grey700,
      darkTextSecondary: color.grey500,
      textDark: color.grey900,
      menuSelected: color.secondaryDark,
      menuSelectedBack: color.secondaryLight,
      divider: color.grey200,
      grey500: color.grey500,
      fontFamily: fontFamily,
      borderRadius: borderRadius,
   };

   const muiThemeOptions: ThemeOptions = {
      direction: "ltr",
      palette: themePalette(prmThemeOption),
      mixins: {
         toolbar: {
            minHeight: "48px",
            padding: "8px",
            "@media (min-width: 600px)": {
               minHeight: "48px",
            },
         },
      },
      typography: themeTypography(prmThemeOption),
   };

   const theme = createTheme(muiThemeOptions);
   theme.components = componentsOverrides(prmThemeOption);
   return theme;
};
