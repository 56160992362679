import { FC, useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from "@lexical/selection";
import { Box } from "@mui/material";
import {
   $getSelection,
   $isRangeSelection,
   COMMAND_PRIORITY_LOW,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import { SizeToggle } from "../widgets/SizeToggle";
import { getSelectionFontSize } from "./utils/nodes";

interface FontSizeProps {
   initFontSize?: string;
}

export const FontSize: FC<FontSizeProps> = ({ initFontSize = "15px" }) => {
   const [editor] = useLexicalComposerContext();
   const [currentFontSize, setCurrentFontSize] = useState<string>(initFontSize);

   const updateToolbar = useCallback(() => {
      const fontSize = getSelectionFontSize(initFontSize);
      setCurrentFontSize(fontSize);
   }, [initFontSize]);

   const styleSelectedText = useCallback(
      (styles: Record<string, string>) => {
         editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
               $patchStyleText(selection, styles);
            }
         });
      },
      [editor]
   );

   useEffect(() => {
      return editor.registerCommand<void>(
         SELECTION_CHANGE_COMMAND,
         () => {
            updateToolbar();
            return false;
         },
         COMMAND_PRIORITY_LOW
      );
   }, [editor, updateToolbar]);

   const numberToPixel = (value: number): string => {
      return `${value}px`;
   };

   const pixelToNumber = (value: string): number => {
      if (value) {
         return parseInt(value, 10);
      }
      return NaN;
   };

   const styleText = (value: string) => {
      styleSelectedText({ "font-size": value });
   };

   const resizeText = (fontSize: number) => {
      const fontSizepx = numberToPixel(fontSize);
      setCurrentFontSize(fontSizepx);
      styleText(fontSizepx);
   };

   return (
      <Box
         data-testid="font-size"
         sx={{
            display: "flex",
            alignItems: "center",
         }}
      >
         <SizeToggle
            key={`size-toggle-${currentFontSize}`}
            initSize={pixelToNumber(currentFontSize)}
            maxSize={72}
            minSize={8}
            onChange={resizeText}
         />
      </Box>
   );
};
