import { FC } from "react";
import { Transitions } from "@components";
import { PopperChildrenProps } from "@mui/base/Popper";
import {
   Avatar,
   Box,
   Card,
   Grid,
   InputAdornment,
   OutlinedInput,
   Popper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
   IconAdjustmentsHorizontal,
   IconSearch,
   IconX,
} from "@tabler/icons-react";
import PopupStateWidget, {
   bindPopper,
   bindToggle,
} from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/hooks";
import { SearchAvatar } from "./SearchAvatar";

interface MobileSearchProps {
   value: string;
   setValue: (value: string) => void;
}

export const MobileSearch: FC<MobileSearchProps> = ({ value, setValue }) => {
   const theme = useTheme();

   const searchInput = (popupState: PopupState) => {
      return (
         <OutlinedInput
            id="search-input"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            startAdornment={
               <InputAdornment position="start" data-testid="start-adormant">
                  <IconSearch
                     stroke={1.5}
                     size="16px"
                     data-testid="search-icon"
                  />
               </InputAdornment>
            }
            endAdornment={
               <InputAdornment position="end" data-testid="end-adormant">
                  <SearchAvatar>
                     <IconAdjustmentsHorizontal
                        stroke={1.5}
                        size="20px"
                        data-testid="adjustments-icon"
                     />
                  </SearchAvatar>
                  <Box sx={{ ml: 2 }}>
                     <Avatar
                        variant="rounded"
                        sx={{
                           ...theme.typography.commonAvatar,
                           ...theme.typography.mediumAvatar,
                           bgcolor: "orange.light",
                           color: "orange.dark",
                           "&:hover": {
                              bgcolor: "orange.dark",
                              color: "orange.light",
                           },
                        }}
                        {...bindToggle(popupState)}
                        data-testid="iconx-avatar"
                     >
                        <IconX stroke={1.5} size="20px" data-testid="iconx" />
                     </Avatar>
                  </Box>
               </InputAdornment>
            }
            inputProps={{
               sx: { bgcolor: "transparent", pl: 0.5 },
            }}
            sx={{ width: "100%", ml: 0.5, px: 2, bgcolor: "background.paper" }}
            data-testid="outlined-input"
         />
      );
   };

   const searchInputCard = (popupState: PopupState) => {
      return (
         <Card
            sx={{
               bgcolor: "background.default",
               border: 0,
               boxShadow: "none",
            }}
            data-testid="search-card"
         >
            <Box sx={{ p: 2 }}>
               <Grid
                  container={true}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item={true} xs={true}>
                     {searchInput(popupState)}
                  </Grid>
               </Grid>
            </Box>
         </Card>
      );
   };

   return (
      <Box
         sx={{ display: { xs: "block", md: "none" } }}
         data-testid="mobile-search"
      >
         <PopupStateWidget variant="popper" popupId="search-popup">
            {(popupState) => (
               <Box data-testid="popup-state-widget">
                  <Box sx={{ ml: 2 }}>
                     <SearchAvatar {...bindToggle(popupState)}>
                        <IconSearch
                           stroke={1.5}
                           size="19.2px"
                           data-testid="search-icon"
                        />
                     </SearchAvatar>
                  </Box>
                  <Popper
                     {...bindPopper(popupState)}
                     transition={true}
                     sx={{
                        zIndex: 1100,
                        width: "99%",
                        top: "-55px !important",
                        px: { xs: 1.25, sm: 1.5 },
                     }}
                     data-testid="search-input-popper"
                  >
                     {({ TransitionProps }: PopperChildrenProps) => (
                        <>
                           <Transitions
                              type="zoom"
                              {...TransitionProps}
                              sx={{ transformOrigin: "center left" }}
                           >
                              {searchInputCard(popupState)}
                           </Transitions>
                        </>
                     )}
                  </Popper>
               </Box>
            )}
         </PopupStateWidget>
      </Box>
   );
};
