import { FC } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { DFeature } from "./types";

interface FeatureProps {
   feature: DFeature;
}

export const Feature: FC<FeatureProps> = ({ feature }) => {
   return (
      <Card sx={{ maxWidth: 345 }} data-testid="feature">
         <CardContent>
            <Stack>
               <Typography
                  variant="h2"
                  component="h2"
                  align="center"
                  gutterBottom={true}
                  data-testid="title"
               >
                  {feature.title}
               </Typography>
               <Typography
                  variant="body1"
                  align="center"
                  data-testid="description"
               >
                  {feature.description}
               </Typography>
            </Stack>
         </CardContent>
      </Card>
   );
};
