import { FC, useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
   COMMAND_PRIORITY_LOW,
   ElementFormatType,
   FORMAT_ELEMENT_COMMAND,
   INDENT_CONTENT_COMMAND,
   OUTDENT_CONTENT_COMMAND,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import _ from "lodash";
import { ToolbarDropDown } from "../widgets/ToolbarDropDown";
import { ToolbarDropDownOption } from "../widgets/ToolbarDropDownOption";
import { IconType } from "../widgets/ToolbarIcon";
import { getSelectionFormatType } from "./utils/nodes";

enum AlignCode {
   left = "left",
   center = "center",
   right = "right",
   justify = "justify",
   start = "start",
   end = "end",
   outdent = "outdent",
   indent = "indent",
}

enum AlignLabel {
   left = "Left Align",
   center = "Center Align",
   right = "Right Align",
   justify = "Justify Align",
   start = "Start Align",
   end = "End Align",
   outdent = "Outdent",
   indent = "Indent",
}

enum Icon {
   left = IconType.ALIGN_LEFT,
   center = IconType.ALIGN_CENTER,
   right = IconType.ALIGN_RIGHT,
   justify = IconType.ALIGN_JUSTIFY,
   start = IconType.ALIGN_LEFT,
   end = IconType.ALIGN_RIGHT,
   outdent = IconType.ALIGN_OUTDENT,
   indent = IconType.ALIGN_INDENT,
}

interface AlignmentProps {
   initAlignment?: string;
}

export const Alignment: FC<AlignmentProps> = ({
   initAlignment = AlignCode.left,
}) => {
   const [editor] = useLexicalComposerContext();
   const [currentAlignment, setCurrentAlignment] =
      useState<string>(initAlignment);

   const updateToolbar = useCallback(() => {
      const alignment = getSelectionFormatType(currentAlignment);
      setCurrentAlignment(alignment);
   }, [currentAlignment]);

   useEffect(() => {
      return editor.registerCommand(
         SELECTION_CHANGE_COMMAND,
         () => {
            updateToolbar();
            return false;
         },
         COMMAND_PRIORITY_LOW
      );
   }, [editor, updateToolbar]);

   const formatText = (formatType: ElementFormatType) => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, formatType);
      setCurrentAlignment(formatType);
   };

   const outdentText = () => {
      editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
      setCurrentAlignment(AlignCode.outdent);
   };

   const indentText = () => {
      editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
      setCurrentAlignment(AlignCode.indent);
   };

   const getCurrentLabel = () => {
      return getLabel(currentAlignment);
   };

   const getCurrentIcon = () => {
      return getIcon(currentAlignment);
   };

   const getLabel = (alignCode: string) => {
      return _.get(AlignLabel, alignCode);
   };

   const getIcon = (alignCode: string) => {
      return _.get(Icon, alignCode);
   };

   const isActive = (alignment: string) => {
      return _.isEqual(alignment, currentAlignment);
   };

   return (
      <div data-testid="text-alignment">
         <ToolbarDropDown label={getCurrentLabel()} icon={getCurrentIcon()}>
            <ToolbarDropDownOption
               text={getLabel(AlignCode.left)}
               icon={getIcon(AlignCode.left)}
               onClick={() => formatText(AlignCode.left)}
               active={isActive(AlignCode.left)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.center)}
               icon={getIcon(AlignCode.center)}
               onClick={() => formatText(AlignCode.center)}
               active={isActive(AlignCode.center)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.right)}
               icon={getIcon(AlignCode.right)}
               onClick={() => formatText(AlignCode.right)}
               active={isActive(AlignCode.right)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.justify)}
               icon={getIcon(AlignCode.justify)}
               onClick={() => formatText(AlignCode.justify)}
               active={isActive(AlignCode.justify)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.start)}
               icon={getIcon(AlignCode.start)}
               onClick={() => formatText(AlignCode.start)}
               active={isActive(AlignCode.start)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.end)}
               icon={getIcon(AlignCode.end)}
               onClick={() => formatText(AlignCode.end)}
               active={isActive(AlignCode.end)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.outdent)}
               icon={getIcon(AlignCode.outdent)}
               onClick={outdentText}
               active={isActive(AlignCode.outdent)}
            />
            <ToolbarDropDownOption
               text={getLabel(AlignCode.indent)}
               icon={getIcon(AlignCode.indent)}
               onClick={indentText}
               active={isActive(AlignCode.indent)}
            />
         </ToolbarDropDown>
      </div>
   );
};
