import { FC, forwardRef, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItemButton, {
   ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDisptach, useAppSelector } from "@prmhooks";
import { leftDrawerOpened, openMenuId } from "@prmredux/ui/reducer";
import { selectUiState } from "@prmredux/ui/selector";
import { borderRadius } from "@prmthemes";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { MenuItem } from "../../types";

interface NavItemProps {
   item: MenuItem;
   level: number;
}

export const NavItem: FC<NavItemProps> = ({ item, level }) => {
   const dispatch = useAppDisptach();
   const uiState = useAppSelector((state) => selectUiState(state));
   const { pathname } = useLocation();
   const theme = useTheme();
   const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

   useEffect(() => {
      const paths = _.split(pathname, "/");
      const currentIndex = _.findIndex(paths, (path) => path === item.id);
      if (currentIndex > -1) {
         dispatch(openMenuId(item.id));
      }
      // eslint-disable-next-line
   }, [pathname]);

   const isNavItemOpened =
      _.findIndex(uiState.openMenuId, (id) => id === item.id) > -1;

   const handleItemClick = () => {
      dispatch(openMenuId(item.id));
      if (matchesSM) {
         dispatch(leftDrawerOpened(false));
      }
   };

   const itemIcon = () => {
      if (item?.icon) {
         const Icon = item.icon;
         return <Icon stroke={1.5} size="1.3rem" data-testid="icon" />;
      }

      const width = isNavItemOpened ? 8 : 6;
      const height = isNavItemOpened ? 8 : 6;
      const fontSize = level > 0 ? "inherit" : "medium";
      return (
         <FiberManualRecordIcon
            sx={{
               width: width,
               height: height,
            }}
            fontSize={fontSize}
            data-testid="manual-recored-icon"
         />
      );
   };

   const listItemIcon = () => {
      return (
         <ListItemIcon
            sx={{ my: "auto", minWidth: !item.icon ? 18 : 36 }}
            data-testid="list-item-icon"
         >
            {itemIcon()}
         </ListItemIcon>
      );
   };

   const primaryItemText = () => {
      return (
         <Typography
            variant={isNavItemOpened ? "h5" : "body1"}
            color="inherit"
            data-testid="text-primary"
         >
            {item.title}
         </Typography>
      );
   };

   const secondaryItemText = () => {
      if (item.caption) {
         return (
            <Typography
               variant="caption"
               sx={{ ...theme.typography.subMenuCaption }}
               display="block"
               gutterBottom={true}
               data-testid="text-secondary"
            >
               {item.caption}
            </Typography>
         );
      }
      return null;
   };

   const listItemText = () => {
      return (
         <ListItemText
            primary={primaryItemText()}
            secondary={secondaryItemText()}
            data-testid="list-item-text"
         />
      );
   };

   const chipAvatar = () => {
      if (item.chip?.avatar) {
         return <Avatar data-testid="chip-avatar">{item.chip.avatar}</Avatar>;
      }
      return undefined;
   };

   const chip = () => {
      if (item.chip) {
         return (
            <Chip
               color={item.chip.color}
               variant={item.chip.variant}
               size={item.chip.size}
               label={item.chip.label}
               avatar={chipAvatar()}
               data-testid="chip"
            />
         );
      }
      return null;
   };

   const listItemButtonProps = (): ListItemButtonProps => {
      const target = item.target ? item.target : "_self";
      if (item?.external) {
         return {
            component: "a",
            href: item.url,
            target: target,
         } as ListItemButtonProps;
      }

      const LinkWithRef = forwardRef<HTMLAnchorElement>((props, ref) => (
         <Link ref={ref} {...props} to={item.url} target={target} />
      ));
      LinkWithRef.displayName = "Link";
      return {
         component: LinkWithRef,
      };
   };

   return (
      <ListItemButton
         {...listItemButtonProps()}
         disabled={item.disabled}
         selected={isNavItemOpened}
         onClick={handleItemClick}
         sx={{
            borderRadius: `${borderRadius}px`,
            mb: 0.5,
            alignItems: "flex-start",
            backgroundColor: level > 1 ? "transparent !important" : "inherit",
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 24}px`,
         }}
         data-testid="nav-item"
      >
         {listItemIcon()}
         {listItemText()}
         {chip()}
      </ListItemButton>
   );
};
