import {
   CategorizedInputs,
   CategorizedInputValue,
   Checkbox,
   DatePicker,
} from "@components";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import _ from "lodash";
import { FormHandler } from "./FormHandler";
import {
   BuildProps,
   CategorizedInputsBuildProps,
   CheckBoxBuildProps,
   InputTextBuildProps,
} from "./types";

export class FormBuilder<T> {
   private formik: FormikProps<T>;
   private handler: FormHandler<T>;

   constructor(formik: FormikProps<T>) {
      this.formik = formik;
      this.handler = new FormHandler<T>(formik);
   }

   async canSubmit(): Promise<boolean> {
      return await this.handler.canSubmit();
   }

   datePicker({ field, label, fullWidth = true }: BuildProps) {
      return (
         <DatePicker
            field={field}
            label={label}
            fullWidth={fullWidth}
            value={this.resolveDateFieldValue(field)}
            onChange={(e: Dayjs | null) =>
               this.handler.handleDateChange(field, e)
            }
            onClear={() => this.handler.handleDateChange(field, null)}
         />
      );
   }

   textField({
      field,
      label,
      placeholder,
      type,
      required,
      fullWidth = true,
   }: InputTextBuildProps) {
      return (
         <TextField
            id={field}
            variant="outlined"
            type={type}
            required={required}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder || label}
            value={this.resolveTextFieldValue(field)}
            onChange={(e) => this.formik.handleChange(e)}
            onBlur={(e) => this.formik.handleBlur(e)}
            error={this.handler.isErrorField(field)}
            helperText={this.handler.message(field)}
            data-testid={field}
         />
      );
   }

   categorizedInputs({
      field,
      label,
      typeLabel,
      typeOptions,
      inputType,
   }: CategorizedInputsBuildProps) {
      return (
         <CategorizedInputs
            field={field}
            label={label}
            categoryLabel={typeLabel}
            categoryOptions={typeOptions}
            type={inputType}
            value={this.resolveCategoryInputFieldValue(field)}
            onValuesChange={(values) =>
               this.handler.handleCategoryInputChange(field, values)
            }
            data-testid={field}
         />
      );
   }

   checkBox({
      field,
      label,
      placeholder,
      options,
      optionsConfig,
      manageOptionsConfig,
      multiple,
      disableCloseOnSelect,
      fullWidth = true,
   }: CheckBoxBuildProps) {
      return (
         <Checkbox
            field={field}
            label={label}
            placeholder={placeholder}
            fullWidth={fullWidth}
            multiple={multiple}
            disableCloseOnSelect={disableCloseOnSelect}
            options={options}
            optionsConfig={optionsConfig}
            manageOptionsConfig={manageOptionsConfig}
            value={this.resolveCheckBoxFieldValue(field)}
            onChange={(newValue) => {
               this.handler.handleCheckBoxChange(field, newValue);
            }}
         />
      );
   }

   private resolveFieldValue(
      field: string
   ): string | string[] | object[] | null {
      return this.handler.resolveFieldValue(field);
   }

   private resolveTextFieldValue(field: string): string | string[] | object[] {
      const value = this.resolveFieldValue(field);
      return value || "";
   }

   private resolveCategoryInputFieldValue(
      field: string
   ): CategorizedInputValue[] {
      return this.resolveFieldValue(field) as CategorizedInputValue[];
   }

   private resolveCheckBoxFieldValue(field: string): string | string[] | null {
      const value = this.resolveFieldValue(field);
      if (_.isArray(value)) {
         return value as string[];
      }
      return value;
   }

   private resolveDateFieldValue(field: string): Dayjs | null {
      const value = this.resolveFieldValue(field) as string;
      if (value) {
         return dayjs(value);
      }
      return null;
   }
}
