import "./LEditor.css";
import { FC } from "react";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import {
   InitialConfigType,
   LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { Box } from "@mui/material";
import { ParagraphNode } from "lexical";
import { lEditorTheme } from "./LEditorTheme";
import { AutoLinkPlugin } from "./plugins/AutoLinkPlugin";
import { ContentPlugin } from "./plugins/ContentPlugin";
import { ToolbarPlugin } from "./plugins/toolbar/ToolbarPlugin";

interface LEditorProps {
   initContent: string;
   onContentChange: (html: string) => void;
   editable: boolean;
}

export const defaultErrorHandler = (error: Error): void => {
   console.error(error);
};

export const LEditor: FC<LEditorProps> = ({
   initContent,
   onContentChange,
   editable,
}) => {
   const editorConfig: InitialConfigType = {
      namespace: "Rich Text Editor",
      theme: lEditorTheme,
      editable: editable,
      onError: defaultErrorHandler,
      nodes: [
         HeadingNode,
         ListNode,
         ListItemNode,
         QuoteNode,
         ParagraphNode,
         AutoLinkNode,
         LinkNode,
      ],
   };

   return (
      <Box data-testid="leditor" key={`leditor${editable}`}>
         <LexicalComposer initialConfig={editorConfig}>
            <Box
               data-testid="editor-container"
               sx={{
                  border: "1px solid #d8d8d8",
                  lineHeight: "20px",
                  fontWeight: "400",
                  textAlign: "left",
                  color: "#000",
               }}
            >
               <ToolbarPlugin visible={editable} />
               <Box
                  data-testid="editor-inner"
                  sx={{
                     position: "relative",
                  }}
               >
                  <RichTextPlugin
                     contentEditable={
                        <ContentEditable
                           className="editor-input"
                           data-testid="editor-input"
                        />
                     }
                     placeholder={null}
                     ErrorBoundary={LexicalErrorBoundary}
                  />
                  <HistoryPlugin />
                  <AutoFocusPlugin />
                  <CheckListPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <AutoLinkPlugin />
                  <LexicalClickableLinkPlugin />
                  <ContentPlugin
                     content={initContent}
                     onChange={onContentChange}
                  />
               </Box>
            </Box>
         </LexicalComposer>
      </Box>
   );
};
