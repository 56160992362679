import { FC, useState } from "react";
import { Box, InputBaseProps } from "@mui/material";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { CategorizedInput } from "./CategorizedInput";

export interface CategorizedInputValue {
   value: string;
   type: string;
}

export interface CategorizedInputValueInternal extends CategorizedInputValue {
   id: string;
}

export interface CategorizedInputsProps extends InputBaseProps {
   field: string;
   label: string;
   categoryLabel: string;
   categoryOptions: string[];
   value: CategorizedInputValue[];
   onValuesChange: (values: CategorizedInputValue[]) => void;
}

export const CategorizedInputs: FC<CategorizedInputsProps> = ({
   field,
   value,
   onValuesChange,
   ...props
}) => {
   const toInternalValue = (
      v: CategorizedInputValue
   ): CategorizedInputValueInternal => {
      return { ...v, id: uuidv4() };
   };

   const toExternalValue = (
      v: CategorizedInputValueInternal
   ): CategorizedInputValue => {
      return { value: v.value, type: v.type };
   };

   const newValue = (): CategorizedInputValueInternal => {
      return { value: "", type: "", id: uuidv4() };
   };

   const initValues = () => {
      if (_.isEmpty(value)) {
         return [newValue()];
      }
      return _.map(value, (v) => toInternalValue(v));
   };

   const [values, setValues] =
      useState<CategorizedInputValueInternal[]>(initValues());

   const propagateValueChange = (
      newValues: CategorizedInputValueInternal[]
   ) => {
      const nonBlankValues = _.filter(
         newValues,
         (v) => !_.isEmpty(v.value) || !_.isEmpty(v.type)
      );
      const changedValues = _.map(nonBlankValues, (v) => toExternalValue(v));
      onValuesChange(changedValues);
   };

   const onValueChange = (newValue: CategorizedInputValueInternal) => {
      const updatedValue = _.find(values, (v) =>
         _.isEqual(v.id, newValue.id)
      ) as CategorizedInputValueInternal;

      updatedValue.value = newValue.value;
      updatedValue.type = newValue.type;
      propagateValueChange(values);
   };

   const onAdd = () => {
      const newValues = [...values, newValue()];
      setValues(newValues);
      propagateValueChange(newValues);
   };

   const onRemove = (toRemove: CategorizedInputValueInternal) => {
      const newValues = _.filter(values, (v) => !_.isEqual(v.id, toRemove.id));
      setValues(newValues);
      propagateValueChange(newValues);
   };

   const canRemove = (): boolean => {
      return values.length > 1;
   };

   const categorizedInput = (v: CategorizedInputValueInternal) => {
      const removeFn = canRemove() ? onRemove : undefined;
      return (
         <CategorizedInput
            field={field}
            value={v}
            onValueChange={onValueChange}
            onAdd={onAdd}
            onRemove={removeFn}
            key={v.id}
            {...props}
         />
      );
   };

   const inputs = () => {
      return _.map(values, (v) => {
         return categorizedInput(v);
      });
   };

   return (
      <Box
         data-testid={field}
         sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "1em",
            flexWrap: "wrap",
            width: "100%",
         }}
      >
         {inputs()}
      </Box>
   );
};
