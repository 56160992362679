import dayjs from "dayjs";
import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeEndOnDate = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (!rruleObj.until) {
      return data.end.onDate.date;
   }
   return dayjs(rruleObj.until);
};
