import { FC } from "react";
import { CallbackFn } from "@domain/common";
import { Avatar, Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconMenu2 } from "@tabler/icons-react";
import { Notifications } from "./notifications/Notifications";
import { Profile } from "./profile/Profile";
import { Search } from "./search/Search";
// import LogoSection from "../LogoSection";

interface HeaderProps {
   handleDrawerToggle: CallbackFn;
}

export const Header: FC<HeaderProps> = ({ handleDrawerToggle }) => {
   const theme = useTheme();

   const logo = () => {
      return (
         <Box
            component="span"
            sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
            data-testid="logo"
         >
            {/* <LogoSection /> */}
         </Box>
      );
   };

   const sidebarToggle = () => {
      return (
         <ButtonBase
            sx={{ borderRadius: "8px", overflow: "hidden" }}
            data-testid="sidebar-toggle"
         >
            <Avatar
               variant="rounded"
               sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  "&:hover": {
                     background: theme.palette.secondary.dark,
                     color: theme.palette.secondary.light,
                  },
               }}
               onClick={handleDrawerToggle}
               color="inherit"
               data-testid="toggle-avatar"
            >
               <IconMenu2
                  stroke={1.5}
                  size="1.3rem"
                  data-testid="toggle-icon"
               />
            </Avatar>
         </ButtonBase>
      );
   };

   return (
      <Box sx={{ display: "flex", width: "100%" }} data-testid="header">
         <Box
            sx={{
               width: 228,
               display: "flex",
               [theme.breakpoints.down("md")]: {
                  width: "auto",
               },
            }}
            data-testid="sidebar-section"
         >
            {logo()}
            {sidebarToggle()}
         </Box>
         <Search />
         <Box sx={{ flexGrow: 1 }} />
         <Box sx={{ flexGrow: 1 }} />
         <Notifications />
         <Profile />
      </Box>
   );
};
