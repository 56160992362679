import { FC, forwardRef, ReactNode } from "react";
import { Box } from "@mui/material";
import { motion, useCycle } from "framer-motion";

interface Scale {
   hover: number;
   tap: number;
}

interface AnimateButtonProps {
   children: ReactNode;
   offset?: number;
   type?: "slide" | "scale" | "rotate";
   direction?: "up" | "down" | "left" | "right";
   scale?: number | Scale;
}

export const AnimateButton: FC<AnimateButtonProps> = forwardRef<
   HTMLDivElement,
   AnimateButtonProps
>(
   (
      {
         children,
         type = "scale",
         direction = "right",
         offset = 10,
         scale = {
            hover: 1,
            tap: 0.9,
         },
      },
      ref
   ) => {
      let offset1;
      let offset2;

      switch (direction) {
         case "up":
         case "left":
            offset1 = offset;
            offset2 = 0;
            break;
         case "right":
         case "down":
         default:
            offset1 = 0;
            offset2 = offset;
            break;
      }

      const [x, cycleX] = useCycle(offset1, offset2);
      const [y, cycleY] = useCycle(offset1, offset2);

      const rotateMotion = () => {
         return (
            <motion.div
               ref={ref}
               animate={{ rotate: 360 }}
               transition={{
                  repeat: Infinity,
                  repeatType: "loop",
                  duration: 2,
                  repeatDelay: 0,
               }}
            >
               {children}
            </motion.div>
         );
      };

      const slideMotion = () => {
         if (direction === "up" || direction === "down") {
            return (
               <motion.div
                  ref={ref}
                  animate={{ y: y }}
                  onHoverEnd={() => cycleY()}
                  onHoverStart={() => cycleY()}
               >
                  {children}
               </motion.div>
            );
         }
         return (
            <motion.div
               ref={ref}
               animate={{ x: x }}
               onHoverEnd={() => cycleX()}
               onHoverStart={() => cycleX()}
            >
               {children}
            </motion.div>
         );
      };

      const scaleMotion = () => {
         if (typeof scale === "number") {
            scale = {
               hover: scale,
               tap: scale,
            };
         }
         return (
            <motion.div
               ref={ref}
               whileHover={{ scale: scale?.hover }}
               whileTap={{ scale: scale?.tap }}
            >
               {children}
            </motion.div>
         );
      };

      const render = () => {
         switch (type) {
            case "rotate":
               return rotateMotion();
            case "slide":
               return slideMotion();
            case "scale":
            default:
               return scaleMotion();
         }
      };
      return <Box data-testid="animate-button">{render()}</Box>;
   }
);

AnimateButton.displayName = "AnimateButton";
