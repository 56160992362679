import { FC, useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
   COMMAND_PRIORITY_LOW,
   FORMAT_TEXT_COMMAND,
   SELECTION_CHANGE_COMMAND,
   TextFormatType,
} from "lexical";
import { ToolbarDropDown } from "../widgets/ToolbarDropDown";
import { ToolbarDropDownOption } from "../widgets/ToolbarDropDownOption";
import { IconType } from "../widgets/ToolbarIcon";
import { selectionHasFormat } from "./utils/nodes";

enum FormatLabel {
   strikethrough = "Strikethrough",
   subscript = "Subscript",
   superscript = "Superscript",
}

export const TextFormat: FC = () => {
   const [editor] = useLexicalComposerContext();
   const [isStrikethrough, setIsStrikethrough] = useState(false);
   const [isSubscript, setIsSubscript] = useState(false);
   const [isSuperscript, setIsSuperscript] = useState(false);

   const updateToolbar = useCallback(() => {
      setIsStrikethrough(selectionHasFormat("strikethrough"));
      setIsSubscript(selectionHasFormat("subscript"));
      setIsSuperscript(selectionHasFormat("superscript"));
   }, []);

   useEffect(() => {
      return editor.registerCommand<void>(
         SELECTION_CHANGE_COMMAND,
         () => {
            updateToolbar();
            return false;
         },
         COMMAND_PRIORITY_LOW
      );
   }, [editor, updateToolbar]);

   const formatText = (formatType: TextFormatType) => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
   };

   return (
      <div data-testid="text-format">
         <ToolbarDropDown icon={IconType.TEXT_FORMAT}>
            <ToolbarDropDownOption
               text={FormatLabel.strikethrough}
               icon={IconType.TYPE_STRIKETHROUGH}
               onClick={() => formatText("strikethrough")}
               active={isStrikethrough}
            />
            <ToolbarDropDownOption
               text={FormatLabel.subscript}
               icon={IconType.TYPE_SUBSCRIPT}
               onClick={() => formatText("subscript")}
               active={isSubscript}
            />
            <ToolbarDropDownOption
               text={FormatLabel.superscript}
               icon={IconType.TYPE_SUPERSCRIPT}
               onClick={() => formatText("superscript")}
               active={isSuperscript}
            />
         </ToolbarDropDown>
      </div>
   );
};
