import { ChangeEvent, useEffect, useRef, useState } from "react";
import { MainCard, Transitions } from "@components";
import { PopperChildrenProps } from "@mui/base/Popper";
import {
   Avatar,
   Box,
   Button,
   ButtonBase,
   CardActions,
   Chip,
   ClickAwayListener,
   Divider,
   Grid,
   Paper,
   Popper,
   Stack,
   TextField,
   Typography,
   useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconBell } from "@tabler/icons-react";
import { toTestId } from "@utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { NotificationList } from "./NotificationList";

const status = [
   {
      value: "all",
      label: "All Notification",
   },
   {
      value: "new",
      label: "New",
   },
   {
      value: "unread",
      label: "Unread",
   },
   {
      value: "other",
      label: "Other",
   },
];

export const Notifications = () => {
   const [open, setOpen] = useState(false);
   const [value, setValue] = useState("");
   const anchorRef = useRef<HTMLDivElement | null>(null);
   const theme = useTheme();
   const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

   const prevOpen = useRef(open);

   useEffect(() => {
      if (prevOpen.current === true && open === false && anchorRef.current) {
         anchorRef.current.focus();
      }
      prevOpen.current = open;
   }, [open]);

   const handleToggle = () => {
      setOpen(!open);
   };

   const handleClose = () => {
      setOpen(false);
   };

   const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event?.target.value);
   };

   const bellIcon = () => {
      return (
         <Box
            sx={{
               ml: 2,
               mr: 3,
               [theme.breakpoints.down("md")]: {
                  mr: 2,
               },
            }}
            data-testid="bell-icon-box"
         >
            <ButtonBase
               sx={{ borderRadius: "12px" }}
               data-testid="bell-icon-button"
            >
               <Avatar
                  variant="rounded"
                  sx={{
                     ...theme.typography.commonAvatar,
                     ...theme.typography.mediumAvatar,
                     transition: "all .2s ease-in-out",
                     background: theme.palette.secondary.light,
                     color: theme.palette.secondary.dark,
                     '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light,
                     },
                  }}
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  color="inherit"
                  data-testid="bell-icon-avatar"
               >
                  <IconBell
                     stroke={1.5}
                     size="1.3rem"
                     data-testid="bell-icon"
                  />
               </Avatar>
            </ButtonBase>
         </Box>
      );
   };

   const managementCardHeader = () => {
      return (
         <Grid
            container={true}
            alignItems="center"
            justifyContent="space-between"
            sx={{ pt: 2, px: 2 }}
            data-testid="mgnt-card-header"
            spacing={2}
         >
            <Grid item={true}>
               <Stack
                  direction="row"
                  spacing={0.5}
                  data-testid="notificaitons-count"
               >
                  <Typography variant="subtitle1">All Notification</Typography>
                  <Chip
                     size="small"
                     label="1"
                     sx={{
                        color: theme.palette.background.default,
                        bgcolor: theme.palette.warning.dark,
                     }}
                  />
               </Stack>
            </Grid>
            <Grid item={true}>
               <Typography
                  component={Link}
                  to="#"
                  variant="subtitle2"
                  color="primary"
               >
                  Mark as all read
               </Typography>
            </Grid>
         </Grid>
      );
   };

   const managementCardBody = () => {
      return (
         <PerfectScrollbar
            style={{
               height: "100%",
               maxHeight: "calc(100vh - 205px)",
               overflowX: "hidden",
            }}
         >
            <Grid
               container={true}
               direction="column"
               spacing={2}
               data-testid="mgnt-card-body"
            >
               <Grid item={true} xs={12}>
                  <Box sx={{ px: 2, pt: 0.25 }}>
                     <TextField
                        id="notificaiton-type"
                        select={true}
                        fullWidth={true}
                        value={value}
                        onChange={handleTypeChange}
                        SelectProps={{
                           native: true,
                        }}
                        data-testid="notificaiton-type"
                     >
                        {status.map((option) => (
                           <option
                              key={option.value}
                              value={option.value}
                              data-testid={`${toTestId(option.value)}`}
                           >
                              {option.label}
                           </option>
                        ))}
                     </TextField>
                  </Box>
               </Grid>
               <Grid item={true} xs={12} p={0}>
                  <Divider sx={{ my: 0 }} />
               </Grid>
            </Grid>
            <NotificationList />
         </PerfectScrollbar>
      );
   };

   const managementCardConent = () => {
      return (
         <Grid
            container={true}
            direction="column"
            spacing={2}
            data-testid="mgnt-card-content"
         >
            <Grid item={true} xs={12}>
               {managementCardHeader()}
            </Grid>
            <Grid item={true} xs={12}>
               {managementCardBody()}
            </Grid>
         </Grid>
      );
   };

   const managementCardActions = () => {
      return (
         <CardActions
            sx={{ p: 1.25, justifyContent: "center" }}
            data-testid="mgnt-card-actions"
         >
            <Button size="small" disableElevation={true}>
               View All
            </Button>
         </CardActions>
      );
   };
   const managementCard = () => {
      return (
         <MainCard
            border={false}
            elevation={16}
            content={false}
            boxShadow={true}
            shadow={theme.shadows[16]}
            data-testid="mgnt-card"
         >
            {managementCardConent()}
            <Divider />
            {managementCardActions()}
         </MainCard>
      );
   };

   const popperContent = () => {
      return (
         <Paper data-testid="popper-content">
            <ClickAwayListener onClickAway={handleClose}>
               {managementCard()}
            </ClickAwayListener>
         </Paper>
      );
   };

   const popper = () => {
      return (
         <Popper
            placement={matchesXs ? "bottom" : "bottom-end"}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition={true}
            disablePortal={true}
            popperOptions={{
               modifiers: [
                  {
                     name: "offset",
                     options: {
                        offset: [matchesXs ? 5 : 0, 20],
                     },
                  },
               ],
            }}
            data-testid="popper"
         >
            {({ TransitionProps }: PopperChildrenProps) => (
               <Transitions
                  position={matchesXs ? "top" : "top-right"}
                  in={open}
                  {...TransitionProps}
                  data-testid="popper-transition"
               >
                  {popperContent()}
               </Transitions>
            )}
         </Popper>
      );
   };

   return (
      <Box
         sx={{
            display: "flex",
            alignItems: "center",
         }}
         data-testid="notifications"
      >
         {bellIcon()}
         {popper()}
      </Box>
   );
};
