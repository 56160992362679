import _ from "lodash";
import { Options, RRule } from "rrule";
import { WeeklyIntervalDays, WeeklyRepeat } from "../../../types";

const byweekday = (days: WeeklyIntervalDays): number[] => {
   const result: number[] = [];
   const values = _.values(days);
   _.map(values, (value, i) => {
      if (value) {
         result.push(i);
      }
   });
   return result;
};

export const computeWeekly = ({
   interval,
   days,
}: WeeklyRepeat): Partial<Options> => {
   return {
      freq: RRule.WEEKLY,
      interval,
      byweekday: byweekday(days),
   };
};
