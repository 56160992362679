import {
   loadAccountSettings,
   loadContactSettings,
   updateContactSettings,
} from "@backend/settings/settings";
import { DAccountSettings, DContactSettings } from "@domain/settings";
import {
   QueryClient,
   UndefinedInitialDataOptions,
   useMutation,
   UseMutationOptions,
   UseMutationResult,
   useQuery,
   useQueryClient,
   UseQueryResult,
} from "@tanstack/react-query";

export const loadAccountSettingsOptions = (): UndefinedInitialDataOptions<
   DAccountSettings,
   Error,
   DAccountSettings
> => {
   return {
      queryKey: ["general-settings"],
      queryFn: () => loadAccountSettings(),
      staleTime: 3 * 60 * 60 * 1000,
   };
};

export const useLoadAccountSettings = (): UseQueryResult<DAccountSettings> => {
   const options = loadAccountSettingsOptions();
   return useQuery(options);
};

export const updateContactSettingsOptions = (
   queryClient: QueryClient
): UseMutationOptions<void, Error, DContactSettings> => {
   return {
      mutationFn: updateContactSettings,
      onSuccess: () => {
         queryClient.invalidateQueries({
            queryKey: ["contact-settings"],
         });
      },
   };
};

export const useUpdateContactSettings = (): UseMutationResult<
   void,
   Error,
   DContactSettings
> => {
   const queryClient = useQueryClient();
   const options = updateContactSettingsOptions(queryClient);
   return useMutation(options);
};

export const loadContactSettingsOptions = (): UndefinedInitialDataOptions<
   DContactSettings,
   Error,
   DContactSettings
> => {
   return {
      queryKey: ["contact-settings"],
      queryFn: () => loadContactSettings(),
      staleTime: 3 * 60 * 60 * 1000,
   };
};

export const useLoadContactSettings = (): UseQueryResult<DContactSettings> => {
   const options = loadContactSettingsOptions();
   return useQuery(options);
};
