import { FC } from "react";
import { Box, BoxProps } from "@mui/material";
import backgroundColor from "@assets/toolbar/icons/background-color.svg";
import quote from "@assets/toolbar/icons/chat-square-quote.svg";
import fontFamily from "@assets/toolbar/icons/font-family.svg";
import numberedList from "@assets/toolbar/icons/list-ol.svg";
import bulletList from "@assets/toolbar/icons/list-ul.svg";
import checkList from "@assets/toolbar/icons/square-check.svg";
import alignCenter from "@assets/toolbar/icons/text-center.svg";
import textColor from "@assets/toolbar/icons/text-color.svg";
import textFormat from "@assets/toolbar/icons/text-format.svg";
import alignIndent from "@assets/toolbar/icons/text-indent.svg";
import alignJustify from "@assets/toolbar/icons/text-justify.svg";
import alignLeft from "@assets/toolbar/icons/text-left.svg";
import alignOutdent from "@assets/toolbar/icons/text-outdent.svg";
import paragrahp from "@assets/toolbar/icons/text-paragraph.svg";
import alignRight from "@assets/toolbar/icons/text-right.svg";
import textSizeMinus from "@assets/toolbar/icons/text-size-minus.svg";
import textSizePlus from "@assets/toolbar/icons/text-size-plus.svg";
import typeBold from "@assets/toolbar/icons/type-bold.svg";
import typeH1 from "@assets/toolbar/icons/type-h1.svg";
import typeH2 from "@assets/toolbar/icons/type-h2.svg";
import typeH3 from "@assets/toolbar/icons/type-h3.svg";
import typeItalic from "@assets/toolbar/icons/type-italic.svg";
import typeStrikeThrough from "@assets/toolbar/icons/type-strikethrough.svg";
import typeSubscript from "@assets/toolbar/icons/type-subscript.svg";
import typeSuperscript from "@assets/toolbar/icons/type-superscript.svg";
import typeUnderline from "@assets/toolbar/icons/type-underline.svg";

export enum IconType {
   PARAGRAPH = "PARAGRAPH",
   TYPE_H1 = "TYPE_H1",
   TYPE_H2 = "TYPE_H2",
   TYPE_H3 = "TYPE_H3",
   TYPE_BOlD = "TYPE_BOlD",
   TYPE_ITALIC = "TYPE_ITALIC",
   TYPE_UNDERLINE = "TYPE_UNDERLINE",
   TYPE_STRIKETHROUGH = "TYPE_STRIKETHROUGH",
   TYPE_SUBSCRIPT = "TYPE_SUBSCRIPT",
   TYPE_SUPERSCRIPT = "TYPE_SUPERSCRIPT",
   BULLET_LIST = "BULLET_LIST",
   NUMBERED_LIST = "NUMBERED_LIST",
   CHECK_LIST = "CHECK_LIST",
   QUOTE = "QUOTE",
   FONT_FAMILY = "FONT_FAMILY",
   ALIGN_RIGHT = "ALIGN_RIGHT",
   ALIGN_LEFT = "ALIGN_LEFT",
   ALIGN_CENTER = "ALIGN_CENTER",
   ALIGN_JUSTIFY = "ALIGN_JUSTIFY",
   ALIGN_OUTDENT = "ALIGN_OUTDENT",
   ALIGN_INDENT = "ALIGN_INDENT",
   TEXT_COLOR = "TEXT_COLOR",
   BACKGROUND_COLOR = "BACKGROUND_COLOR",
   TEXT_FORMAT = "TEXT_FORMAT",
   TEXT_SIZE_MINUS = "TEXT_SIZE_MINUS",
   TEXT_SIZE_PLUS = "TEXT_SIZE_PLUS",
}

interface ToolbarIconProps extends BoxProps {
   type: IconType;
   spaced?: boolean;
   blured?: boolean;
   disabled?: boolean;
}

export const ToolbarIcon: FC<ToolbarIconProps> = ({
   type,
   spaced,
   blured,
   disabled,
   sx,
   ...rest
}) => {
   const getIcon = (): string => {
      switch (type) {
         case IconType.PARAGRAPH:
            return paragrahp;
         case IconType.TYPE_H1:
            return typeH1;
         case IconType.TYPE_H2:
            return typeH2;
         case IconType.TYPE_H3:
            return typeH3;
         case IconType.TYPE_BOlD:
            return typeBold;
         case IconType.TYPE_ITALIC:
            return typeItalic;
         case IconType.TYPE_UNDERLINE:
            return typeUnderline;
         case IconType.TYPE_STRIKETHROUGH:
            return typeStrikeThrough;
         case IconType.TYPE_SUBSCRIPT:
            return typeSubscript;
         case IconType.TYPE_SUPERSCRIPT:
            return typeSuperscript;
         case IconType.BULLET_LIST:
            return bulletList;
         case IconType.NUMBERED_LIST:
            return numberedList;
         case IconType.CHECK_LIST:
            return checkList;
         case IconType.QUOTE:
            return quote;
         case IconType.FONT_FAMILY:
            return fontFamily;
         case IconType.ALIGN_RIGHT:
            return alignRight;
         case IconType.ALIGN_LEFT:
            return alignLeft;
         case IconType.ALIGN_CENTER:
            return alignCenter;
         case IconType.ALIGN_JUSTIFY:
            return alignJustify;
         case IconType.ALIGN_OUTDENT:
            return alignOutdent;
         case IconType.ALIGN_INDENT:
            return alignIndent;
         case IconType.TEXT_COLOR:
            return textColor;
         case IconType.BACKGROUND_COLOR:
            return backgroundColor;
         case IconType.TEXT_FORMAT:
            return textFormat;
         case IconType.TEXT_SIZE_MINUS:
            return textSizeMinus;
         case IconType.TEXT_SIZE_PLUS:
            return textSizePlus;
      }
   };

   return (
      <Box
         data-testid="item-icon"
         component="span"
         sx={{
            display: "flex",
            width: "20px",
            height: "20px",
            userSelect: "none",
            mt: "0",
            mr: spaced ? "8px" : "0",
            lineHeight: "16px",
            backgroundSize: "contain",
            opacity: disabled ? "0.2" : blured ? "0.6" : "1",
            backgroundImage: `url(${getIcon()})`,
            ...sx,
         }}
         {...rest}
      />
   );
};
