import { isValidDate } from "@utils";
import dayjs from "dayjs";
import { Options } from "rrule";
import { StartConfig } from "../../../types";

export const computeStart = ({
   onDate: { date },
}: StartConfig): Partial<Options> => {
   if (!isValidDate(date)) {
      date = dayjs().millisecond(0);
   }
   return {
      dtstart: date.toDate(),
   };
};
