import { FC, useState } from "react";
import {
   Button,
   ButtonGroup,
   Grid,
   TextField,
   Typography,
} from "@mui/material";
import { WeeklyIntervalDays, WeeklyRepeat } from "../../types";

interface WeeklyProps {
   config: WeeklyRepeat;
   onChange: (update: WeeklyRepeat) => void;
}

export const Weekly: FC<WeeklyProps> = ({
   config: { interval: initInterval, days: initDays },
   onChange,
}) => {
   const [interval, setInterval] = useState<number>(initInterval);
   const [days, setDays] = useState<WeeklyIntervalDays>(initDays);

   const updatedConfig = (interval: number, days: WeeklyIntervalDays) => {
      const update: WeeklyRepeat = {
         interval: interval,
         days: days,
      };
      onChange(update);
   };

   const updateInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setInterval(value);
      updatedConfig(value, days);
   };

   const updateDays = (
      day: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun"
   ) => {
      const newDays = { ...days };
      newDays[day] = !days[day];
      setDays(newDays);
      updatedConfig(interval, newDays);
   };

   const intervalInput = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{
               justifyContent: "center",
               alignItems: "center",
            }}
            data-testid="executions-interval"
         >
            <Grid item={true}>
               <Typography>every</Typography>
            </Grid>
            <Grid item={true}>
               <TextField
                  id="interval"
                  type="number"
                  label="After"
                  value={interval}
                  onChange={updateInterval}
                  data-testid="interval"
               />
            </Grid>
            <Grid item={true}>
               <Typography>week(s)</Typography>
            </Grid>
         </Grid>
      );
   };

   const daysInput = () => {
      return (
         <ButtonGroup data-testid="days">
            <Button
               onClick={() => updateDays("mon")}
               variant={days.mon ? "contained" : "outlined"}
               data-testid="day-mon"
            >
               Mon
            </Button>
            <Button
               onClick={() => updateDays("tue")}
               variant={days.tue ? "contained" : "outlined"}
               data-testid="day-tue"
            >
               Tue
            </Button>
            <Button
               onClick={() => updateDays("wed")}
               variant={days.wed ? "contained" : "outlined"}
               data-testid="day-wed"
            >
               Wed
            </Button>
            <Button
               onClick={() => updateDays("thu")}
               variant={days.thu ? "contained" : "outlined"}
               data-testid="day-thu"
            >
               Thu
            </Button>
            <Button
               onClick={() => updateDays("fri")}
               variant={days.fri ? "contained" : "outlined"}
               data-testid="day-fri"
            >
               Fri
            </Button>
            <Button
               onClick={() => updateDays("sat")}
               variant={days.sat ? "contained" : "outlined"}
               data-testid="day-sat"
            >
               Sat
            </Button>
            <Button
               onClick={() => updateDays("sun")}
               variant={days.sun ? "contained" : "outlined"}
               data-testid="day-sun"
            >
               Sun
            </Button>
         </ButtonGroup>
      );
   };

   return (
      <Grid container={true} gap={1} data-testid="weekly-repeat">
         <Grid item={true}>{intervalInput()}</Grid>
         <Grid item={true}>{daysInput()}</Grid>
      </Grid>
   );
};
