import { FC } from "react";
import { Box, BoxProps } from "@mui/material";

export type ToolbarItemTextProps = BoxProps;

export const ToolbarItemText: FC<ToolbarItemTextProps> = ({
   children,
   sx,
   ...rest
}) => {
   return (
      <Box
         component="span"
         data-testid="toolbar-item-text"
         sx={{
            display: "flex",
            lineHeight: "20px",
            fontSize: "14px",
            color: "#777",
            width: "70px",
            height: "20px",
            verticalAlign: "middle",
            textOverflow: "ellipsis",
            overflow: "hidden",
            textAlign: "left",
            ...sx
         }}
         {...rest}
      >
         {children}
      </Box>
   );
};
