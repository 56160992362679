import { PageQuery, Pagination } from "@domain/common";
import _ from "lodash";
import {
   MRT_ColumnFiltersState,
   MRT_PaginationState,
   MRT_SortingState,
} from "material-react-table";

export const resolvePagination = (
   pagination: MRT_PaginationState
): Pagination => {
   return {
      pageNumber: pagination?.pageIndex || 0,
      pageSize: pagination?.pageSize || 50,
   };
};

export const resolveSorting = (
   sorting?: MRT_SortingState
): string | undefined => {
   if (sorting && !_.isEmpty(sorting)) {
      const sort = sorting[0];
      if (sort.desc) {
         return `desc(${sort.id})`;
      }
      return `asc(${sort.id})`;
   }
   return undefined;
};

export const resolveColumnFilters = (
   columnFilters?: MRT_ColumnFiltersState
) => {
   return _.mapValues(_.keyBy(columnFilters, "id"), "value");
};

export const pageQuery = (
   pagination: MRT_PaginationState,
   sorting?: MRT_SortingState,
   columnFilters?: MRT_ColumnFiltersState
): PageQuery<object> => {
   const query: PageQuery<object> = {
      pagination: resolvePagination(pagination),
      sort: resolveSorting(sorting),
      filter: resolveColumnFilters(columnFilters),
   };
   return query;
};
