import { FC, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
   CAN_UNDO_COMMAND,
   COMMAND_PRIORITY_CRITICAL,
   UNDO_COMMAND
} from "lexical";
import { ToolbarButton } from "../widgets/ToolbarButton";
import { ImageType, ToolbarImage } from "../widgets/ToolbarImage";

export const UndoButton: FC = () => {
   const [canUndo, setCanUndo] = useState(false);
   const [editor] = useLexicalComposerContext();

   useEffect(() => {
      return editor.registerCommand<boolean>(
         CAN_UNDO_COMMAND,
         (payload: boolean) => {
            setCanUndo(payload);
            return false;
         },
         COMMAND_PRIORITY_CRITICAL
      );
   }, [editor]);

   const undo = () => {
      editor.dispatchCommand(UNDO_COMMAND, undefined);
   };

   const isDisabled = () => {
      return !canUndo;
   };

   return (
      <ToolbarButton
         data-testid="undo-button"
         spaced={true}
         disabled={isDisabled()}
         onClick={undo}
         aria-label="Undo"
      >
         <ToolbarImage type={ImageType.UNDO} disabled={isDisabled()} />
      </ToolbarButton>
   );
};
