import { $isListNode, ListNode } from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getSelectionStyleValueForProperty } from "@lexical/selection";
import { $getNearestNodeOfType } from "@lexical/utils";
import {
   $getSelection,
   $isRangeSelection,
   $isRootNode,
   $isTextNode,
   BaseSelection,
   LexicalEditor,
   ParagraphNode,
   TextFormatType,
} from "lexical";
import { FontFamilyOptions } from "./constants";

const getSelectionStyleProperty = (
   property: string,
   defaultValue: string
): string => {
   const selection = $getSelection();
   if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      if ($isTextNode(anchorNode)) {
         const fontFamily = $getSelectionStyleValueForProperty(
            selection,
            property,
            defaultValue
         );
         return fontFamily as FontFamilyOptions;
      }
   }
   return defaultValue;
};

export const getSelectionElementType = (
   editor: LexicalEditor,
   selection: BaseSelection | null
): string | null => {
   if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const node =
         anchorNode.getKey() === "root"
            ? anchorNode
            : anchorNode.getTopLevelElementOrThrow();

      const nodeKey = node.getKey();
      const nodeDOM = editor.getElementByKey(nodeKey);

      if (nodeDOM !== null) {
         if ($isListNode(node)) {
            const parentList = $getNearestNodeOfType<ListNode>(
               anchorNode,
               ListNode
            );
            if (parentList) {
               return parentList.getListType();
            }
            return node.getListType();
         } else {
            if ($isHeadingNode(node)) {
               return node.getTag();
            }
            return node.getType();
         }
      }
   }
   return null;
};

export const getSelectionFormatType = (defaultValue: string): string => {
   const selection = $getSelection();
   if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      if (!$isRootNode(anchorNode)) {
         const paragraphNode = anchorNode.getParentOrThrow<ParagraphNode>();
         const formatType = paragraphNode.getFormatType();
         if (formatType) {
            return formatType;
         }
      }
   }
   return defaultValue;
};

export const getSelectionFontFamily = (defaultValue: string): string => {
   return getSelectionStyleProperty("font-family", defaultValue);
};

export const getSelectionFontSize = (defaultValue: string): string => {
   return getSelectionStyleProperty("font-size", defaultValue);
};

export const getSelectionColor = (defaultValue: string): string => {
   return getSelectionStyleProperty("color", defaultValue);
};

export const getSelectionBackgroundColor = (defaultValue: string): string => {
   return getSelectionStyleProperty("background-color", defaultValue);
};

export const selectionHasFormat = (type: TextFormatType): boolean => {
   const selection = $getSelection();
   if ($isRangeSelection(selection)) {
      return selection.hasFormat(type);
   }
   return false;
};
