import { FC } from "react";
import {
   DCreateSubscription,
   DPaymentSession,
   DPrice,
} from "@domain/subscription";
import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   Typography,
} from "@mui/material";
import _ from "lodash";
import { useCreateSubscription } from "../../../hooks/useQueries";

interface PriceProsp {
   price: DPrice;
}

export const Price: FC<PriceProsp> = ({ price }) => {
   const createSubscriptionMutation = useCreateSubscription();

   const createSubscription = () => {
      const subscription: DCreateSubscription = {
         priceId: price.id,
      };
      createSubscriptionMutation.mutate(subscription, {
         onSuccess: (ps: DPaymentSession) =>
            (window.location.href = ps.checkoutUrl),
      });
   };

   const currency = () => {
      return _.upperCase(price.currency);
   };

   const unitAmount = (): number => {
      return price.unitAmount / 100;
   };

   const pricePerUnit = () => {
      return `${currency()} ${unitAmount()}`;
   };

   const interval = () => {
      const intervalCount = price.recurring.intervalCount;
      switch (price.recurring.interval) {
         case "month":
            if (intervalCount == 1) {
               return "month";
            }
            if (intervalCount == 3) {
               return "quater";
            }
            return `${intervalCount} months`;
         default:
            return price.recurring.interval;
      }
   };

   const productName = () => {
      return price.product.name;
   };

   return (
      <Box data-testid="price">
         <Card
            variant="elevation"
            sx={{
               m: "1em",
               p: "2em",
               backgroundColor: "rgba(26, 26, 26, 0.05)",
            }}
         >
            <CardContent data-testid="price-content">
               <Box sx={{ mb: "2em" }} data-testid="product-name">
                  <Typography sx={{ fontWeight: 500, fontSize: "1.5em" }}>
                     {productName()}
                  </Typography>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     gap: "0.3em",
                  }}
                  data-testid="product-price"
               >
                  <Typography sx={{ fontWeight: 700, fontSize: "2.3em" }}>
                     {pricePerUnit()}
                  </Typography>
                  <Typography
                     sx={{
                        fontSize: "0.8em",
                        color: "rgb(26, 26, 26)",
                     }}
                  >
                     per
                     <br />
                     {interval()}
                  </Typography>
               </Box>
            </CardContent>
            <CardActions data-testid="price-actions">
               <Button
                  size="large"
                  variant="contained"
                  fullWidth={true}
                  onClick={createSubscription}
                  sx={{ borderRadius: "5px" }}
                  data-testid={`subscribe-btn-${price.id}`}
               >
                  Subscribe
               </Button>
            </CardActions>
         </Card>
      </Box>
   );
};
