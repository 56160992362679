import { IconBrandChrome, IconDashboard, IconKey } from "@tabler/icons-react";
import { MenuGroup } from "../types";

const dashboard: MenuGroup = {
   id: "dashboard",
   title: "Dashboard",
   items: [
      {
         id: "dashboard",
         title: "Dashboard",
         url: "/dashboard",
         icon: IconDashboard,
         breadcrumbs: false,
      },
   ],
};

const contacts: MenuGroup = {
   id: "contacts",
   title: "Contacts",
   items: [
      {
         id: "contacts",
         title: "My Contacts",
         url: "/contacts",
         icon: IconBrandChrome,
         breadcrumbs: false,
      },
   ],
};

const preferences: MenuGroup = {
   id: "preferences",
   title: "Preferences",
   items: [
      {
         id: "settings",
         title: "Preferences",
         url: "/settings",
         icon: IconBrandChrome,
         breadcrumbs: false,
      },
   ],
};

// const settings: MenuGroup = {
//    id: "settings",
//    title: "Settings",
//    subGroups: [
//       {
//          id: "accountSettings",
//          title: "Account",
//          caption: "Account Caption",
//          icon: IconKey,
//          subGroups: [
//             {
//                id: "accountSettings",
//                title: "Profile",
//                caption: "Profile",
//                icon: IconKey,
//                subGroups: [],
//                items: [
//                   {
//                      id: "settings",
//                      title: "Login",
//                      url: "/settings/login3",
//                   },
//                   {
//                      id: "register3",
//                      title: "Register",
//                      url: "/settings",
//                      target: "_blank",
//                   },
//                ],
//             },
//          ],
//       },
//    ],
//    items: [
//       {
//          id: "subscription",
//          title: "Subscription",
//          url: "/subscription",
//          icon: IconBrandChrome,
//          breadcrumbs: false,
//       },
//    ],
// };

export const menuGroups = [dashboard, contacts, preferences];
