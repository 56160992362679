import { Dayjs } from "dayjs";

export type Frequency = "Yearly" | "Monthly" | "Weekly" | "Daily";

export const FREQUENCIES: Frequency[] = [
   "Yearly",
   "Monthly",
   "Weekly",
   "Daily",
];

export type YearlyMode = "on" | "on the";
export type MonthlyMode = "on day" | "on the";
export type EndMode = "Never" | "After" | "On Date";

export type Day =
   | "Monday"
   | "Tuesday"
   | "Wednesday"
   | "Thursday"
   | "Friday"
   | "Saturday"
   | "Sunday"
   | "Day"
   | "Weekday"
   | "Weekend day";

export const DAYS = [
   "Monday",
   "Tuesday",
   "Wednesday",
   "Thursday",
   "Friday",
   "Saturday",
   "Sunday",
   "Day",
   "Weekday",
   "Weekend day",
];

export type Month =
   | "Jan"
   | "Feb"
   | "Mar"
   | "Apr"
   | "May"
   | "Jun"
   | "Jul"
   | "Aug"
   | "Sep"
   | "Oct"
   | "Nov"
   | "Dec";

export const MONTHS: Month[] = [
   "Jan",
   "Feb",
   "Mar",
   "Apr",
   "May",
   "Jun",
   "Jul",
   "Aug",
   "Sep",
   "Oct",
   "Nov",
   "Dec",
];

export type Order = "First" | "Second" | "Third" | "Fourth" | "Last";

export const ORDERS: Order[] = ["First", "Second", "Third", "Fourth", "Last"];

export interface WeeklyIntervalDays {
   mon: boolean;
   tue: boolean;
   wed: boolean;
   thu: boolean;
   fri: boolean;
   sat: boolean;
   sun: boolean;
}

export interface DailyRepeat {
   interval: number;
}

export interface WeeklyRepeat {
   interval: number;
   days: WeeklyIntervalDays;
}

export interface MonthlyOn {
   day?: number;
}
export interface MonthlyOnThe {
   day?: Day;
   which?: Order;
}

export interface YearlyOn {
   day: number;
   month: Month;
}

export interface YearlyOnThe {
   day?: Day;
   which?: Order;
   month: Month;
}

export interface MonthlyRepeat {
   interval: number;
   mode: MonthlyMode;
   on: MonthlyOn;
   onThe: MonthlyOnThe;
}

export interface YearlyRepeat {
   mode: YearlyMode;
   on: YearlyOn;
   onThe: YearlyOnThe;
}

export interface StartConfig {
   onDate: {
      date: Dayjs;
   };
}

export interface RepeatConfig {
   frequency: Frequency;
   yearly: YearlyRepeat;
   monthly: MonthlyRepeat;
   weekly: WeeklyRepeat;
   daily: DailyRepeat;
}

export interface EndConfig {
   mode: EndMode;
   after: number;
   onDate: {
      date: Dayjs;
   };
}

export interface RecurrenceConfigOptions {
   weekStartsOnSunday: boolean;
}

export interface RecurrenceConfig {
   start: StartConfig;
   repeat: RepeatConfig;
   end: EndConfig;
   options: RecurrenceConfigOptions;
}
