import { FC } from "react";
import { Box, BoxProps } from "@mui/material";

export interface ToolbarItemProps extends BoxProps {
   spaced?: boolean;
   disabled?: boolean;
}

export const ToolbarItem: FC<ToolbarItemProps> = ({
   component = "div",
   children,
   spaced,
   sx,
   ...rest
}) => {
   return (
      <Box
         component={component}
         data-testid="toolbar-item"
         sx={{
            border: "0",
            marginRight: spaced ? "2px" : "0",
            display: "flex",
            background: "none",
            borderRadius: "10px",
            padding: "8px",
            cursor: "pointer",
            verticalAlign: "middle",
            ":disabled": {
               cursor: "not-allowed",
            },
            ":hover:not([disabled])": {
               backgroundColor: "#eee",
            },
            ":active": {
               backgroundColor: "#dfe8fa4d",
            },
            ...sx,
         }}
         {...rest}
      >
         {children}
      </Box>
   );
};
