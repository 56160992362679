import { FC } from "react";
import { Container, Stack } from "@mui/material";
import { Features } from "./features/Features";
import { Home } from "./home/Home";
import { Pricing } from "./pricing/Pricing";

export const Content: FC = () => {
   return (
      <Container
         maxWidth="lg"
         sx={{
            backgroundColor: "background.paper",
            position: "relative",
            pt: 4,
            pb: { xs: 8, md: 10 },
         }}
         data-testid="content"
      >
         <Stack spacing={10}>
            <Home />
            <Features />
            <Pricing />
         </Stack>
      </Container>
   );
};
