import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeYearlyMode = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (rruleObj.freq !== 0 || !rruleObj.bymonth) {
      return data.repeat.yearly.mode;
   }
   if (rruleObj.bymonthday) {
      return "on";
   }
   return "on the";
};
