declare global {
   interface Window {
      env: EnvType;
   }
}

type EnvType = {
   REACT_APP_AUTH_URL: string;
   REACT_APP_VISION_NOTES_URL: string;
   REACT_APP_SUBCRIPTIONS_URL: string;
   APP_VERSION: string;
};

export const env: EnvType = {
   ...process.env,
   ...window.env,
   APP_VERSION: "0.0.1",
};
