import { StrictMode } from "react";
import { Box } from "@mui/material";
import { setupStore } from "@prmredux/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { Provider } from "react-redux";
import { env } from "../env";
import { App } from "./App";

export const PrmKeycloak = () => {
   const store = setupStore();

   const config = {
      url: env.REACT_APP_AUTH_URL,
      realm: "vision-notes",
      clientId: "vision-notes-ui",
   };

   const initOptions = {
      onLoad: "check-sso",
      checkLoginIframe: false,
   };

   const keycloak = new Keycloak(config);

   return (
      <Box
         data-testid="prm-keycloak"
         sx={{ height: "100%", minHeight: "100vh" }}
      >
         <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
            <Provider store={store}>
               <StrictMode>
                  <App />
               </StrictMode>
            </Provider>
         </ReactKeycloakProvider>
      </Box>
   );
};
