import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./types";

const initialState: UserState = {
   contactBeingEdited: false,
};

export const counterSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      contactBeingEdited: (state, action) => {
         state.contactBeingEdited = action.payload;
      },
   },
});

export const { contactBeingEdited } = counterSlice.actions;

export const reducer = counterSlice.reducer;
