import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeFrequency = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   switch (rruleObj.freq) {
      case 0: {
         return "Yearly";
      }
      case 1: {
         return "Monthly";
      }
      case 2: {
         return "Weekly";
      }
      case 3: {
         return "Daily";
      }
      default: {
         return data.repeat.frequency;
      }
   }
};
