import dayjs, { Dayjs } from "dayjs";

export const isValidDate = (date: Dayjs | null) => {
   if (date) {
      const asDayjs = dayjs(date);
      return asDayjs.isValid();
   }
   return false;
};

export const formatDate = (date: string | null) => {
   const parsedDate = dayjs(date);
   if (isValidDate(parsedDate)) {
      return parsedDate.format("DD MMM YYYY");
   }
   return date;
};

export const formatDayjsDate = (date: Dayjs | null) => {
   if (isValidDate(date) && date) {
      return date.format("DD MMM YYYY");
   }
   return date;
};

export const daysInMonth = (month: number) => {
   return new Date(2024, month, 0).getDate();
};
