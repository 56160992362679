import { useState } from "react";
import { Dialog, DialogActionProps } from "@components";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
   useCancelSubscription,
   useLoadCurrentPricePlan,
} from "../../../hooks/useQueries";
import { CurrentPricePlan } from "./CurrentPricePlan";

export const ActiveSubscription = () => {
   const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
   const { data: pricePlan } = useLoadCurrentPricePlan();
   const cancelSubscriptionMutation = useCancelSubscription();

   const cancelSubscription = () => {
      cancelSubscriptionMutation.mutate(undefined, {
         onSuccess: () => setShowCancelDialog(false),
      });
   };

   const confirmCancelation = () => {
      setShowCancelDialog(true);
   };

   const cancelCancelation = () => {
      setShowCancelDialog(false);
   };

   const cancelActions = (): DialogActionProps[] => {
      return [
         {
            label: "Cancel",
            onClick: cancelCancelation,
            variant: "outlined",
         },
         {
            label: "Confirm",
            onClick: cancelSubscription,
            variant: "contained",
         },
      ];
   };

   const cancelConfirmationDialog = () => {
      if (showCancelDialog) {
         return (
            <Box data-testid="cancel-confirmation-dialog">
               <Dialog
                  title="Cancel Subscription"
                  open={true}
                  actions={cancelActions()}
                  maxWidth="sm"
               >
                  <Typography sx={{ mb: "1em" }}>
                     Do you realy want to cancel your subscription plan?
                  </Typography>
                  <Typography>
                     Canceled subscriptions will remain active till the end of
                     the current billing period and will not be renewed
                     thereafter.
                  </Typography>
               </Dialog>
            </Box>
         );
      }
      return null;
   };

   const cancelSubscriptionBtn = () => {
      if (pricePlan && !pricePlan.cancelAtPeriodEnd) {
         return (
            <Button
               variant="text"
               onClick={confirmCancelation}
               data-testid="cancel-subscription-btn"
            >
               Cancel Subscription
            </Button>
         );
      }
      return null;
   };

   const actions = () => {
      return (
         <Grid
            container={true}
            item={true}
            justifyContent="flex-end"
            data-testid="subscription-actions"
         >
            <Grid item={true}>{cancelSubscriptionBtn()}</Grid>
            {cancelConfirmationDialog()}
         </Grid>
      );
   };

   const billing = () => {
      return (
         <Grid container={true} direction="column" data-testid="billing-header">
            <Grid item={true}>
               <Typography
                  sx={{
                     fontWeight: 500,
                     fontSize: "1.5em",
                  }}
               >
                  Plan & Billing
               </Typography>
            </Grid>
            <Grid container={true} item={true}>
               <Grid item={true}>
                  <Typography>
                     Manage your subscription plan and payments
                  </Typography>
               </Grid>
            </Grid>
         </Grid>
      );
   };

   return (
      <Grid
         container={true}
         justifyContent="center"
         rowSpacing={2}
         data-testid="subscription-active"
      >
         <Grid item={true} sm={10}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
               Subscription
            </Typography>
         </Grid>
         <Grid container={true} item={true} sm={10}>
            {billing()}
         </Grid>
         <Grid container={true} item={true} sm={10}>
            <CurrentPricePlan pricePlan={pricePlan} />
         </Grid>
         <Grid container={true} item={true} sm={10}>
            {actions()}
         </Grid>
      </Grid>
   );
};
