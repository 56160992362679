import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeMonthlyOnDay = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (rruleObj.freq !== 1 || !rruleObj.bymonthday) {
      return data.repeat.monthly.on.day;
   }
   if (typeof rruleObj.bymonthday === "number") {
      return rruleObj.bymonthday;
   }
   return rruleObj.bymonthday[0];
};
