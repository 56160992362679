import { FC, useState } from "react";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { daysInMonth, toTestId } from "@utils";
import _ from "lodash";
import {
   Day,
   DAYS,
   Month,
   MONTHS,
   Order,
   ORDERS,
   YearlyMode,
   YearlyRepeat,
} from "../../types";

interface YearlyProps {
   config: YearlyRepeat;
   onChange: (update: YearlyRepeat) => void;
}

export const Yearly: FC<YearlyProps> = ({
   config: { mode: initMode },
   onChange,
}) => {
   const [mode, setMode] = useState<YearlyMode>(initMode);
   const [month, setMonth] = useState<Month>("Jan");
   const [monthDay, setMonthDay] = useState<number>(1);
   const [order, setOrder] = useState<Order>("First");
   const [weekday, setWeekday] = useState<Day>("Monday");

   const getMonthdays = (selectedMonth: Month): number[] => {
      const monthDigit =
         _.findIndex(MONTHS, (m) => _.isEqual(m, selectedMonth)) + 1;
      const daysCount = daysInMonth(monthDigit) + 1;
      return _.range(1, daysCount);
   };

   const updatedOnConfig = (
      mode: YearlyMode,
      month: Month,
      monthDay: number
   ) => {
      const update: YearlyRepeat = {
         mode,
         on: { month, day: monthDay },
         onThe: { month, day: weekday, which: order },
      };
      onChange(update);
   };

   const updatedOnTheConfig = (
      mode: YearlyMode,
      order: Order,
      weekday: Day,
      month: Month
   ) => {
      const update: YearlyRepeat = {
         mode,
         on: { month, day: monthDay },
         onThe: { month, day: weekday, which: order },
      };
      onChange(update);
   };

   const updateMode = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as YearlyMode;
      setMode(value);
      updatedOnConfig(value, month, monthDay);
   };

   const updateMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newMonth = event.target.value as Month;
      const days = getMonthdays(newMonth);
      const lastDay = days[days.length - 1];
      let newMonthDay = monthDay;
      if (monthDay > lastDay) {
         newMonthDay = lastDay;
         setMonthDay(newMonthDay);
      }
      setMonth(newMonth);
      updatedOnConfig(mode, newMonth, newMonthDay);
   };

   const updateMonthDay = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newMonthDay = Number(event.target.value);
      setMonthDay(newMonthDay);
      updatedOnConfig(mode, month, newMonthDay);
   };

   const updateOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newOrder = event.target.value as Order;
      setOrder(newOrder);
      updatedOnTheConfig(mode, newOrder, weekday, month);
   };

   const updateWeekday = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newWeekday = event.target.value as Day;
      setWeekday(newWeekday);
      updatedOnTheConfig(mode, order, newWeekday, month);
   };

   const modes: YearlyMode[] = ["on", "on the"];

   const modeSelector = () => {
      return (
         <TextField
            id="select-repeat-mode"
            select={true}
            label="Mode"
            value={mode}
            onChange={updateMode}
            sx={{ width: "8em" }}
            data-testid="select-repeat-mode"
         >
            {modes.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`repeat-mode-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const monthSelector = () => {
      return (
         <TextField
            id="select-month"
            select={true}
            label="Month"
            value={month}
            onChange={updateMonth}
            data-testid="select-month"
         >
            {MONTHS.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`month-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const monthDaySelector = () => {
      return (
         <TextField
            id="select-monthday"
            select={true}
            label="Day"
            value={monthDay}
            onChange={updateMonthDay}
            key={`monthday-${month}`}
            data-testid="select-monthday"
         >
            {getMonthdays(month).map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`monthday-${option}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const orderSelector = () => {
      return (
         <TextField
            id="select-order"
            select={true}
            label="Order"
            value={order}
            onChange={updateOrder}
            data-testid="select-order"
         >
            {ORDERS.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`order-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const weekDaySelector = () => {
      return (
         <TextField
            id="select-weekday"
            select={true}
            label="Weekday"
            value={weekday}
            onChange={updateWeekday}
            data-testid="select-weekday"
         >
            {DAYS.map((option) => (
               <MenuItem
                  key={option}
                  value={option}
                  data-testid={`weekday-${toTestId(option)}`}
               >
                  {option}
               </MenuItem>
            ))}
         </TextField>
      );
   };

   const onInputs = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
         >
            {monthSelector()}
            {monthDaySelector()}
         </Grid>
      );
   };

   const onTheInputs = () => {
      return (
         <Grid
            container={true}
            gap={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
         >
            {orderSelector()}
            {weekDaySelector()}
            <Typography>of</Typography>
            {monthSelector()}
         </Grid>
      );
   };

   const modeInputs = () => {
      if (mode === "on") {
         return onInputs();
      }
      return onTheInputs();
   };

   return (
      <Grid container={true} gap={1} data-testid="yearly-repeat">
         <Grid item={true}>{modeSelector()}</Grid>
         <Grid item={true}>{modeInputs()}</Grid>
      </Grid>
   );
};
