import { FC, forwardRef, ReactNode } from "react";
import {
   Card,
   CardContent,
   CardHeader,
   Divider,
   Typography,
} from "@mui/material";

const headerSX = {
   "& .MuiCardHeader-action": { mr: 0 },
};

interface MainCardProps {
   border?: boolean;
   boxShadow?: boolean;
   children: ReactNode;
   content?: boolean;
   contentClass?: string;
   contentSX?: object;
   darkTitle?: boolean;
   secondary?: ReactNode | string;
   shadow?: string | number;
   sx?: object;
   title?: ReactNode | string;
   elevation?: number;
}

export const MainCard: FC<MainCardProps> = forwardRef<
   HTMLDivElement,
   MainCardProps
>(
   (
      {
         border = false,
         boxShadow,
         children,
         content = true,
         contentClass = "",
         contentSX = {},
         darkTitle,
         secondary,
         shadow,
         sx = {},
         title,
         ...others
      },
      ref
   ) => {
      const cardHeader = () => {
         if (!darkTitle && title) {
            return (
               <CardHeader
                  sx={headerSX}
                  title={title}
                  action={secondary}
                  data-testid="card-header"
               />
            );
         }

         if (darkTitle && title) {
            return (
               <CardHeader
                  sx={headerSX}
                  title={<Typography variant="h3">{title}</Typography>}
                  action={secondary}
                  data-testid="card-header-dark-title"
               />
            );
         }
      };

      const divider = () => {
         if (title) {
            return <Divider data-testid="divider" />;
         }
         return null;
      };

      const cardContent = () => {
         if (content) {
            return (
               <CardContent
                  sx={contentSX}
                  className={contentClass}
                  data-testid="card-content"
               >
                  {children}
               </CardContent>
            );
         }
         return children;
      };

      return (
         <Card
            ref={ref}
            sx={{
               border: border ? "1px solid" : "none",
               borderColor: "divider",
               ":hover": {
                  boxShadow: boxShadow
                     ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
                     : "inherit",
               },
               ...sx,
            }}
            data-testid="main-card"
            {...others}
         >
            {cardHeader()}
            {divider()}
            {cardContent()}
         </Card>
      );
   }
);

MainCard.displayName = "MainCard";
