import _ from "lodash";
import qs from "qs";

type UrlParam = number | string;

export const composeUrl = (template: string, ...args: UrlParam[]) => {
   return template.replace(/{([0-9]+)}/g, (match, index) => {
      return typeof args[index] === "undefined" ? match : `${args[index]}`;
   });
};

export const stringifyUrl = <QueryParams>(
   baseUrl: string,
   queryParams: QueryParams
) => {
   if (_.isEmpty(queryParams)) {
      return baseUrl;
   }
   const urlParams = qs.stringify(queryParams, { allowDots: true });
   return baseUrl.concat("?").concat(urlParams);
};
