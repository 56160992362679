import { Options } from "rrule";
import { MONTHS, RecurrenceConfig } from "../../../types";

export const computeYearlyOnTheMonth = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (rruleObj.freq !== 0 || !rruleObj.byweekday) {
      return data.repeat.yearly.onThe.month;
   }

   if (typeof rruleObj.bymonth === "number") {
      return MONTHS[rruleObj.bymonth - 1];
   }

   const bymothsArray = rruleObj.bymonth as number[];
   return MONTHS[bymothsArray[0] - 1];
};
