import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/700.css";
import "./assets/scss/style.scss";
import CssBaseline from "@mui/material/CssBaseline";
import ReactDOM from "react-dom/client";
import { PrmThemedApp } from "./app/PrmThemedApp";

const root = ReactDOM.createRoot(
   document.getElementById("root") as HTMLElement
);

root.render(
   <>
      <CssBaseline />
      <PrmThemedApp />
   </>
);
