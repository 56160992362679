import { FC, useState } from "react";
import { Grid } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { End, EndUpdate } from "./components/end/End";
import { Repeat } from "./components/repeat/Repeat";
import { Start } from "./components/start/Start";
import { RecurrenceConfig, RepeatConfig } from "./components/types";
import { computeRRule as computeRRuleFromString } from "./components/utils/computeRRule/fromString/computeRRule";
import { computeRRule as computeRRuleToString } from "./components/utils/computeRRule/toString/computeRRule";
import { initConfig } from "./components/utils/initConfig";

interface RecurrenceProps {
   onChange: (rRule: string) => void;
   rrule?: string;
}

export const Recurrence: FC<RecurrenceProps> = ({ onChange, rrule }) => {
   const [config, setConfig] = useState(
      computeRRuleFromString(initConfig(), rrule)
   );

   const updatedConfig = (newConfig: RecurrenceConfig) => {
      const rRule = computeRRuleToString(newConfig);
      onChange(rRule);
      setConfig(newConfig);
   };

   const updateStartDate = (newDate: Dayjs) => {
      const newConfig = { ...config };
      newConfig.start.onDate.date = newDate;
      updatedConfig(newConfig);
   };

   const updateRepeat = (update: RepeatConfig) => {
      const newConfig = { ...config };
      newConfig.repeat.frequency = update.frequency;
      if (update.frequency === "Yearly") {
         newConfig.repeat.yearly = update.yearly;
      }
      if (update.frequency === "Monthly") {
         newConfig.repeat.monthly = update.monthly;
      }
      if (update.frequency === "Weekly") {
         newConfig.repeat.weekly = update.weekly;
      }
      if (update.frequency === "Daily") {
         newConfig.repeat.daily = update.daily;
      }
      updatedConfig(newConfig);
   };

   const updateEndDate = (update: EndUpdate) => {
      const newConfig = { ...config };
      newConfig.end.mode = update.mode;

      if (update.mode === "After") {
         newConfig.end.after = update.count;
      }
      if (update.mode === "On Date") {
         newConfig.end.onDate.date = dayjs(update.until);
      }
      updatedConfig(newConfig);
   };

   return (
      <Grid container={true} gap={1} data-testid="recurrence">
         <Grid container={true} item={true} gap={1} direction="column" xs={4}>
            <Grid item={true}>
               <Start config={config.start} onChange={updateStartDate} />
            </Grid>
            <Grid item={true}>
               <End config={config.end} onChange={updateEndDate} />
            </Grid>
         </Grid>
         <Grid container={true} item={true} xs={7}>
            <Grid item={true} xs={12}>
               <Repeat config={config.repeat} onChange={updateRepeat} />
            </Grid>
         </Grid>
      </Grid>
   );
};
