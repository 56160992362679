import { useState } from "react";
import {
   Grid,
   Stack,
   ToggleButton,
   ToggleButtonGroup,
   Typography,
} from "@mui/material";
import _ from "lodash";
import { Price } from "./Price";
import { DLandingPagePrice, PricePeriod } from "./types";

const prices: DLandingPagePrice[] = [
   {
      title: "Free",
      price: 0,
      currency: "CHF",
      comment: "No credit card needed",
      features: ["15 contacts", "50 MB of storage"],
      period: "month",
      signUpLabel: "Get Started Free",
   },
   {
      title: "Starter",
      price: 9.99,
      currency: "CHF",
      comment: "Paid monthly",
      features: ["150 contacts", "250 MB of storage", "Email notifications"],
      period: "month",
      signUpLabel: "Get Started",
   },
   {
      title: "Professional",
      price: 12.99,
      currency: "CHF",
      comment: "Paid monthly",
      features: [
         "Unlimited contacts",
         "Unlimited storage",
         "Email notifications",
      ],
      period: "month",
      signUpLabel: "Get Started",
   },
   {
      title: "Free",
      price: 0,
      currency: "CHF",
      comment: "No credit card needed",
      features: ["15 contacts", "50 MB of storage"],
      period: "year",
      signUpLabel: "Get Started Free",
   },
   {
      title: "Starter",
      price: 99.99,
      currency: "CHF",
      comment: "Paid yearly",
      features: ["150 contacts", "250 MB of storage", "Email notifications"],
      period: "year",
      signUpLabel: "Get Started",
   },
   {
      title: "Professional",
      price: 129.99,
      currency: "CHF",
      comment: "Paid yearly",
      features: [
         "Unlimited contacts",
         "Unlimited storage",
         "Email notifications",
      ],
      period: "year",
      signUpLabel: "Get Started",
   },
];

export const Pricing = () => {
   const [period, setPeriod] = useState<PricePeriod>("month");

   const handleToggle = (
      event: React.MouseEvent<HTMLElement>,
      newPeriod: PricePeriod | null
   ) => {
      if (newPeriod) {
         setPeriod(newPeriod);
      }
   };

   const periodToggle = (value: string, label: string) => {
      return (
         <ToggleButton
            value={value}
            sx={{
               width: "10em",
               border: "1px solid #e3e8ef",
               borderRadius: "25px",
               "&.Mui-selected, &.Mui-selected:hover": {
                  color: "white",
                  backgroundColor: "primary.main",
               },
               "&.MuiToggleButtonGroup-firstButton": {
                  border: "1px solid #e3e8ef",
                  borderRadius: "25px",
               },

               "&.MuiToggleButtonGroup-lastButton": {
                  border: "1px solid #e3e8ef",
                  borderRadius: "25px",
               },
            }}
            data-testid={value}
         >
            {label}
         </ToggleButton>
      );
   };

   const periodToggles = () => {
      return (
         <ToggleButtonGroup
            color="primary"
            value={period}
            exclusive={true}
            onChange={handleToggle}
            sx={{
               border: "1px solid #eef2f6",
               borderRadius: "25px",
            }}
            data-testid="period-toggle"
         >
            {periodToggle("month", "Monthly")}
            {periodToggle("year", "Yearly")}
         </ToggleButtonGroup>
      );
   };

   const price = (price: DLandingPagePrice, index: number) => {
      return (
         <Grid item={true} key={index}>
            <Price price={price} />
         </Grid>
      );
   };

   const priceCards = () => {
      const selectedPrices = _.filter(
         prices,
         (p: DLandingPagePrice) => p.period === period
      );
      return _.map(selectedPrices, (p, i) => price(p, i));
   };

   return (
      <Stack
         id="pricing"
         justifyContent="center"
         alignItems="center"
         spacing={5}
         data-testid="pricing"
      >
         <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            data-testid="pricing-header"
         >
            Plans & Pricing
         </Typography>
         <Stack
            justifyContent="center"
            alignItems="center"
            spacing={3}
            data-testid="prices"
         >
            {periodToggles()}
            <Grid
               container={true}
               justifyContent={{ xs: "center", lg: "space-between" }}
               data-testid="price-cards"
            >
               {priceCards()}
            </Grid>
         </Stack>
      </Stack>
   );
};
