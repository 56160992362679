import {
   DCreateSubscription,
   DPaymentSession,
   DPricePlan,
   DPricesPage,
   DSubscriptionInfo,
} from "@domain/subscription";
import { subcriptionClient } from "../axios";
import { get, post, put } from "../common";

const SUBSCRIPTIONS_URL = "subscriptions";
const SUBSCRIPTION_URL = SUBSCRIPTIONS_URL + "/subscription";
const SUBSCRIPTION_INFO_URL = SUBSCRIPTIONS_URL + "/subscription-info";
const CURRENT_PRICE_PLAN_URL = SUBSCRIPTIONS_URL + "/current-price-plan";
const CANCEL_SUBSCRIPTION_URL = SUBSCRIPTIONS_URL + "/cancel-subscription";
const PRICES_URL = SUBSCRIPTIONS_URL + "/prices";

export const createSubscription = async (
   createSubscription: DCreateSubscription
): Promise<DPaymentSession> => {
   return await post(subcriptionClient, SUBSCRIPTION_URL, createSubscription);
};

export const cancelSubscription = async (): Promise<void> => {
   return await put(subcriptionClient, CANCEL_SUBSCRIPTION_URL);
};

export const loadSubscriptionInfo = async (): Promise<DSubscriptionInfo> => {
   return await get(subcriptionClient, SUBSCRIPTION_INFO_URL);
};

export const loadCurrentPricePlan = async (): Promise<DPricePlan> => {
   return await get(subcriptionClient, CURRENT_PRICE_PLAN_URL);
};

export const loadPrices = async (): Promise<DPricesPage> => {
   return await get(subcriptionClient, PRICES_URL);
};
