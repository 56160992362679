import { FC } from "react";
import { Box } from "@mui/material";
import {
   DatePicker as MuiDatePicker,
   LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface DatePickerProps {
   field: string;
   label: string;
   value: Dayjs | null;
   onChange: (newDate: Dayjs | null) => void;
   onClear: () => void;
   fullWidth?: boolean;
}

export const DatePicker: FC<DatePickerProps> = ({
   label,
   field,
   fullWidth,
   value,
   onChange,
   onClear,
}) => {
   return (
      <Box data-testid="date-picker" sx={{ width: "100%" }}>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDatePicker
               label={label}
               format="DD MMM YYYY"
               value={value}
               onChange={onChange}
               slotProps={{
                  textField: { fullWidth: fullWidth },
                  field: {
                     clearable: true,
                     onClear: onClear,
                  },
               }}
               data-testid={field}
            />
         </LocalizationProvider>
      </Box>
   );
};
