import { DPrice } from "@domain/subscription";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useLoadPrices } from "../../../hooks/useQueries";
import { Price } from "./Price";

export const PriceTable = () => {
   const { data: page } = useLoadPrices();

   const hasPrices = () => {
      return !_.isEmpty(page?.content);
   };

   const skeleton = () => {
      return (
         <Grid item={true} sm={3}>
            <Skeleton
               variant="rectangular"
               width="100%"
               height="15em"
               sx={{
                  borderRadius: "8px",
               }}
            />
         </Grid>
      );
   };

   const skeletons = () => {
      return (
         <Grid
            container={true}
            item={true}
            columnSpacing={4}
            direction="row"
            justifyContent="center"
            data-testid="skeletons"
         >
            {skeleton()}
            {skeleton()}
            {skeleton()}
         </Grid>
      );
   };

   const price = (p: DPrice) => {
      return (
         <Grid item={true} key={p.id}>
            <Price price={p} />
         </Grid>
      );
   };

   const prices = () => {
      if (hasPrices()) {
         return (
            <Grid
               container={true}
               spacing={2}
               justifyContent="center"
               data-testid="prices"
            >
               {_.map(page?.content, (p) => price(p))}
            </Grid>
         );
      }
      return skeletons();
   };

   const header = () => {
      return (
         <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            data-testid="header"
         >
            <Typography variant="h3">Subscription plans</Typography>
            <Typography sx={{ fontSize: "1.2em" }}>
               VisonNotes subscription plans to supercharge your networking
            </Typography>
         </Stack>
      );
   };

   return (
      <Stack
         spacing={4}
         justifyContent="center"
         alignItems="center"
         data-testid="price-table"
      >
         {header()}
         {prices()}
      </Stack>
   );
};
