import { FC } from "react";
import { AnimateButton } from "@components";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import Image from "mui-image";
import headlineCurve from "@assets/img/pages/landing/headline-curve.svg";
import notes from "@assets/img/pages/landing/notes.png";

export const Home: FC = () => {
   const { keycloak } = useKeycloak();

   const signin = async () => {
      keycloak.login();
   };

   const text = () => {
      return (
         <Box
            sx={{
               textAlign: { xs: "center", md: "left" },
               height: "100%",
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
            }}
            data-testid="text"
         >
            <Box sx={{ mb: 3 }}>
               <Typography
                  component="h2"
                  sx={{
                     position: "relative",
                     fontSize: { xs: 40, md: 72 },
                     letterSpacing: 1.5,
                     fontWeight: "bold",
                     lineHeight: 1.3,
                  }}
               >
                  <Typography
                     component="mark"
                     sx={{
                        position: "relative",
                        color: "primary.main",
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        backgroundColor: "unset",
                     }}
                  >
                     Supercharge{" "}
                     <Box
                        sx={{
                           position: "absolute",
                           top: { xs: 24, md: 34 },
                           left: 2,
                           transform: "rotate(3deg)",
                           "& img": {
                              width: { xs: 146, md: 210 },
                              height: "auto",
                           },
                        }}
                     >
                        <img src={headlineCurve} alt="Headline curve" />
                     </Box>
                  </Typography>
                  your{" "}
                  <Typography
                     component="span"
                     sx={{
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        position: "relative",
                        "& svg": {
                           position: "absolute",
                           top: -16,
                           right: -21,
                           width: { xs: 22, md: 30 },
                           height: "auto",
                        },
                     }}
                  >
                     Professional Networking
                  </Typography>
               </Typography>
            </Box>
            <Box sx={{ mb: 4, width: "100%" }}>
               <Typography
                  sx={{
                     color: "text.secondary",
                     fontSize: 17,
                     lineHeight: 1.6,
                  }}
               >
                  In today`s fast-paced business environment, effective
                  networking is essential for growth and success. VisionNotes
                  streamlines this process, enabling users to cultivate
                  meaningful connections and unlock new opportunities.
               </Typography>
            </Box>
            <Box>
               <AnimateButton>
                  <Button
                     color="info"
                     size="large"
                     variant="contained"
                     onClick={signin}
                     sx={{ boxShadow: "none" }}
                     data-testid="get-started-btn"
                  >
                     Get Started
                  </Button>
               </AnimateButton>
            </Box>
         </Box>
      );
   };

   const image = () => {
      return (
         <Box
            sx={{
               lineHeight: 0,
            }}
            data-testid="image"
         >
            <Image src={notes} duration={0} style={{ objectFit: "contain" }} />
         </Box>
      );
   };

   return (
      <Container data-testid="home">
         <Grid
            container={true}
            spacing={{ xs: 5, md: 3 }}
            sx={{ flexDirection: { xs: "column", md: "unset" } }}
         >
            <Grid item xs={12} md={7}>
               {text()}
            </Grid>
            <Grid item xs={12} md={5} sx={{ position: "relative" }}>
               {image()}
            </Grid>
         </Grid>
      </Container>
   );
};
