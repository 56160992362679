import axios, { AxiosInstance } from "axios";
import Keycloak from "keycloak-js";
import { env } from "../env";

const REACT_APP_VISION_NOTES_URL = env.REACT_APP_VISION_NOTES_URL;
const REACT_APP_SUBCRIPTIONS_URL = env.REACT_APP_SUBCRIPTIONS_URL;

const visionNotesService = () => {
   return axiosClient(REACT_APP_VISION_NOTES_URL);
};

const subscriptionService = () => {
   return axiosClient(REACT_APP_SUBCRIPTIONS_URL);
};

const axiosClient = (hostUrl: string): AxiosInstance => {
   const config = {
      baseURL: hostUrl,
      headers: {
         "Content-type": "application/json",
      },
   };
   return axios.create(config);
};

const setInterceptors = (axiosClient: AxiosInstance, keycloak: Keycloak) => {
   axiosClient.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return config;
   });
};

export const visionNotesClient = visionNotesService();

export const subcriptionClient = subscriptionService();

export const authenticateAxiosClients = (keycloak: Keycloak) => {
   setInterceptors(visionNotesClient, keycloak);
   setInterceptors(subcriptionClient, keycloak);
};
