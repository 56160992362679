import { Options } from "rrule";
import { EndConfig } from "../../../types";

export const computeEnd = ({
   mode,
   after,
   onDate: { date },
}: EndConfig): Partial<Options> => {
   if (mode === "After") {
      return { count: after };
   }
   if (mode === "On Date") {
      return { until: date.toDate() };
   }
   return {};
};
