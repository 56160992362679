import { useState } from "react";
import { Box } from "@mui/material";
import { BrowserSearch } from "./BrowserSearch";
import { MobileSearch } from "./MobileSearch";

export const Search = () => {
   const [value, setValue] = useState("");

   return (
      <Box data-testid="search">
         <MobileSearch value={value} setValue={setValue} />
         <BrowserSearch value={value} setValue={setValue} />
      </Box>
   );
};
