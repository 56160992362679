import { FC, useState } from "react";
import { DatePicker } from "@components";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { StartConfig } from "../types";

interface StartProps {
   config: StartConfig;
   onChange: (date: Dayjs) => void;
}

export const Start: FC<StartProps> = ({ config: { onDate }, onChange }) => {
   const [date, setDate] = useState<Dayjs>(onDate.date);

   const updateDate = (newDate: Dayjs | null) => {
      if (_.isNull(newDate)) {
         newDate = dayjs().second(0).millisecond(0);
      }
      setDate(newDate);
      onChange(newDate);
   };

   return (
      <Box data-testid="recurrence-start">
         <DatePicker
            label="Start"
            field="start-date"
            fullWidth={true}
            value={date}
            onChange={updateDate}
            onClear={() => updateDate(null)}
         />
      </Box>
   );
};
