import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeWeeklyInterval = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
): number => {
   if (rruleObj.freq !== 2) {
      return data.repeat.weekly.interval;
   }
   return rruleObj.interval || 0;
};
