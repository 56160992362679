import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeEndMode = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (rruleObj.count || rruleObj.count === 0) {
      return "After";
   }
   if (rruleObj.until) {
      return "On Date";
   }
   return "Never";
};
