import { useEffect, useRef, useState } from "react";
import { MainCard, Transitions } from "@components";
import { PopperChildrenProps } from "@mui/base/Popper";
import {
   Avatar,
   Box,
   Chip,
   ClickAwayListener,
   Divider,
   Grid,
   List,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Paper,
   Popper,
   Stack,
   Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { borderRadius } from "@prmthemes";
import { useKeycloak } from "@react-keycloak/web";
import {
   IconCalendarMonth,
   IconLogout,
   IconSettings,
} from "@tabler/icons-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import { UpgradePlanCard } from "./UpgradePlanCard";

export const Profile = () => {
   const [selectedIndex, setSelectedIndex] = useState(-1);
   const [open, setOpen] = useState(false);
   const theme = useTheme();
   const navigate = useNavigate();
   const { keycloak } = useKeycloak();

   const anchorRef = useRef<HTMLDivElement | null>(null);

   const logout = async () => {
      keycloak.logout();
   };

   const toSubscription = async (index: number) => {
      navigate("/subscription");
      setSelectedIndex(index);
      handleClose();
   };

   const handleClose = () => {
      setOpen(false);
   };

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const prevOpen = useRef(open);
   useEffect(() => {
      if (prevOpen.current === true && open === false && anchorRef.current) {
         anchorRef.current.focus();
      }

      prevOpen.current = open;
   }, [open]);

   const chip = () => {
      return (
         <Chip
            icon={
               <Avatar
                  sx={{
                     ...theme.typography.mediumAvatar,
                     margin: "8px 0 8px 8px !important",
                     cursor: "pointer",
                  }}
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  color="inherit"
                  data-testid="chip-avatar"
               />
            }
            label={
               <IconSettings
                  stroke={1.5}
                  size="1.5rem"
                  color={theme.palette.primary.main}
                  data-testid="chip-settings-icon"
               />
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
            sx={{
               height: "48px",
               alignItems: "center",
               borderRadius: "27px",
               transition: "all .2s ease-in-out",
               borderColor: theme.palette.primary.light,
               backgroundColor: theme.palette.primary.light,
               '&[aria-controls="menu-list-grow"], &:hover': {
                  borderColor: theme.palette.primary.main,
                  background: `${theme.palette.primary.main}!important`,
                  color: theme.palette.primary.light,
                  "& svg": {
                     stroke: theme.palette.primary.light,
                  },
               },
               "& .MuiChip-label": {
                  lineHeight: 0,
               },
            }}
            data-testid="chip"
         />
      );
   };

   const profileOptions = () => {
      return (
         <PerfectScrollbar
            style={{
               height: "100%",
               maxHeight: "calc(100vh - 250px)",
               overflowX: "hidden",
            }}
         >
            <Box sx={{ p: 2, pt: 0 }} data-testid="profile-options">
               <UpgradePlanCard />
               <Divider />
               <List
                  component="nav"
                  sx={{
                     width: "100%",
                     maxWidth: 350,
                     minWidth: 300,
                     backgroundColor: theme.palette.background.paper,
                     borderRadius: "10px",
                     [theme.breakpoints.down("md")]: {
                        minWidth: "100%",
                     },
                     "& .MuiListItemButton-root": {
                        mt: 0.5,
                     },
                  }}
               >
                  <ListItemButton
                     sx={{
                        borderRadius: `${borderRadius}px`,
                     }}
                     selected={selectedIndex === 0}
                  >
                     <ListItemIcon>
                        <IconSettings stroke={1.5} size="1.3rem" />
                     </ListItemIcon>
                     <ListItemText
                        primary={
                           <Typography variant="body2">
                              Account Settings
                           </Typography>
                        }
                     />
                  </ListItemButton>
                  <ListItemButton
                     sx={{
                        borderRadius: `${borderRadius}px`,
                     }}
                     selected={selectedIndex === 1}
                     onClick={() => toSubscription(1)}
                     data-testid="profile-menu-subscription"
                  >
                     <ListItemIcon>
                        <IconCalendarMonth stroke={1.5} size="1.3rem" />
                     </ListItemIcon>
                     <ListItemText
                        primary={
                           <Grid
                              container
                              spacing={1}
                              justifyContent="space-between"
                           >
                              <Grid item>
                                 <Typography variant="body2">
                                    Subscription
                                 </Typography>
                              </Grid>
                           </Grid>
                        }
                     />
                  </ListItemButton>
                  <ListItemButton
                     sx={{
                        borderRadius: `${borderRadius}px`,
                     }}
                     selected={selectedIndex === 4}
                     onClick={logout}
                     data-testid="list-item-log-out"
                  >
                     <ListItemIcon>
                        <IconLogout stroke={1.5} size="1.3rem" />
                     </ListItemIcon>
                     <ListItemText
                        primary={
                           <Typography variant="body2">Logout</Typography>
                        }
                     />
                  </ListItemButton>
               </List>
            </Box>
         </PerfectScrollbar>
      );
   };

   const popperContent = () => {
      return (
         <Paper data-testid="popper-content">
            <ClickAwayListener onClickAway={handleClose}>
               <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
               >
                  <Box sx={{ p: 2, pb: 0 }}>
                     <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Morning,</Typography>
                        <Typography
                           component="span"
                           variant="h4"
                           sx={{ fontWeight: 400 }}
                        >
                           {keycloak.idTokenParsed?.name}
                        </Typography>
                     </Stack>
                  </Box>
                  {profileOptions()}
               </MainCard>
            </ClickAwayListener>
         </Paper>
      );
   };

   const popper = () => {
      return (
         <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition={true}
            disablePortal={true}
            popperOptions={{
               modifiers: [
                  {
                     name: "offset",
                     options: {
                        offset: [0, 14],
                     },
                  },
               ],
            }}
            data-testid="popper"
         >
            {({ TransitionProps }: PopperChildrenProps) => (
               <Transitions
                  in={open}
                  {...TransitionProps}
                  data-testid="popper-transition"
               >
                  {popperContent()}
               </Transitions>
            )}
         </Popper>
      );
   };

   return (
      <Box data-testid="profile">
         {chip()}
         {popper()}
      </Box>
   );
};
