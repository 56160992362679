import dayjs from "dayjs";
import { RecurrenceConfig } from "../types";

export const initConfig = (): RecurrenceConfig => {
   return {
      start: {
         onDate: {
            date: dayjs().second(0).millisecond(0),
         },
      },
      repeat: {
         frequency: "Yearly",
         yearly: {
            mode: "on",
            on: {
               month: "Jan",
               day: 1,
            },
            onThe: {
               month: "Jan",
               day: "Monday",
               which: "First",
            },
         },
         monthly: {
            mode: "on day",
            interval: 1,
            on: {
               day: 1,
            },
            onThe: {
               day: "Monday",
               which: "First",
            },
         },
         weekly: {
            interval: 1,
            days: {
               mon: false,
               tue: false,
               wed: false,
               thu: false,
               fri: false,
               sat: false,
               sun: false,
            },
         },
         daily: {
            interval: 1,
         },
      },
      end: {
         mode: "Never",
         after: 1,
         onDate: {
            date: dayjs().second(0).millisecond(0),
         },
      },
      options: {
         weekStartsOnSunday: false,
      },
   };
};
