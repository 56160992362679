import { AxiosInstance } from "axios";
import { stringifyUrl } from "./utils";

export const get = async <T, QueryParams>(
   axiosClient: AxiosInstance,
   baseUrl: string,
   queryParams?: QueryParams
): Promise<T> => {
   const url = stringifyUrl(baseUrl, queryParams);
   const respone = await axiosClient.get(url, jsonRequestConfig);
   return respone.data;
};

export const post = async <T, R = T>(
   axiosClient: AxiosInstance,
   url: string,
   payload: T
): Promise<R> => {
   const respone = await axiosClient.post(url, payload, jsonRequestConfig);
   return respone.data;
};

export const put = async <T>(
   axiosClient: AxiosInstance,
   url: string,
   payload?: T
): Promise<T> => {
   const respone = await axiosClient.put(url, payload, jsonRequestConfig);
   return respone.data;
};

export const ddelete = async <T>(
   axiosClient: AxiosInstance,
   url: string
): Promise<T> => {
   const respone = await axiosClient.delete(url, jsonRequestConfig);
   return respone.data;
};

export const uploadFile = async <T>(
   axiosClient: AxiosInstance,
   url: string,
   file: File
): Promise<T> => {
   const formData = new FormData();
   formData.append("file", file);
   const config = {
      headers: mulltipartHeaders,
   };
   const respone = await axiosClient.post(url, formData, config);
   return respone.data;
};

const jsonRequestConfig = {
   headers: {
      Accept: "application/json",
      "Content-type": "application/json",
   },
};

const mulltipartHeaders = {
   "Content-type": "multipart/form-data",
};
