import { Box, CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { PrmKeycloak } from "./PrmKeycloak";
import { theme } from "./themes/theme";

export const PrmThemedApp = () => {
   return (
      <Box data-testid="prm-themed-app">
         <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme()}>
               <CssBaseline />
               <PrmKeycloak />
            </ThemeProvider>
         </StyledEngineProvider>
      </Box>
   );
};
