import {
   AppBar,
   Box,
   CssBaseline,
   styled,
   Toolbar,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { useAppSelector } from "@prmhooks";
import { leftDrawerOpened } from "@prmredux/ui/reducer";
import { selectUiState } from "@prmredux/ui/selector";
import { drawerWidth } from "@prmthemes";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Header } from "./header/Header";
import { Sidebar } from "./sidebar/Sidebar";

interface MainProps {
   drawerOpened: boolean;
}

const Main = styled("main", {
   shouldForwardProp: (prop) => prop !== "drawerOpened" && prop !== "theme",
})<MainProps>(({ theme, drawerOpened }) => ({
   ...theme.typography.mainContent,
   borderBottomLeftRadius: 0,
   borderBottomRightRadius: 0,
   transition: theme.transitions.create(
      "margin",
      drawerOpened
         ? {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
           }
         : {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
           }
   ),
   [theme.breakpoints.up("md")]: {
      marginLeft: drawerOpened ? 0 : -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
   },
   [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
   },
   [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
   },
}));

export const MainPage = () => {
   const theme = useTheme();
   const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
   const dispatch = useDispatch();

   const uiState = useAppSelector((state) => selectUiState(state));
   const drawerOpened = uiState.leftDrawerOpened;

   const handleLeftDrawerToggle = () => {
      dispatch(leftDrawerOpened(!drawerOpened));
   };

   return (
      <Box sx={{ display: "flex" }} data-testid="main-page">
         <CssBaseline />
         <AppBar
            enableColorOnDark={true}
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
               bgcolor: theme.palette.background.default,
               transition: drawerOpened
                  ? theme.transitions.create("width")
                  : "none",
            }}
            data-testid="app-bar"
         >
            <Toolbar
               sx={{ py: { xs: "16px" }, px: { xs: "16px", sm: "24px" } }}
               data-testid="toolbar"
            >
               <Header handleDrawerToggle={handleLeftDrawerToggle} />
            </Toolbar>
         </AppBar>
         <Sidebar
            drawerOpen={!matchDownMd ? drawerOpened : !drawerOpened}
            drawerToggle={handleLeftDrawerToggle}
         />
         <Main theme={theme} drawerOpened={drawerOpened}>
            {/* <Breadcrumbs
               separator={IconChevronRight}
               navigation={navigation}
               icon
               title
               rightAlign
            /> */}
            <Outlet />
         </Main>
      </Box>
   );
};
