import { rrulestr as RRuleObjectFromString } from "rrule";
import { RecurrenceConfig } from "../../../types";
import { computeDailyInterval } from "./computeDailyInterval";
import { computeEndAfter } from "./computeEndAfter";
import { computeEndMode } from "./computeEndMode";
import { computeEndOnDate } from "./computeEndOnDate";
import { computeFrequency } from "./computeFrequency";
import { computeMonthlyInterval } from "./computeMonthlyInterval";
import { computeMonthlyMode } from "./computeMonthlyMode";
import { computeMonthlyOnDay } from "./computeMonthlyOnDay";
import { computeMonthlyOnTheDay } from "./computeMonthlyOnTheDay";
import { computeMonthlyOnTheWhich } from "./computeMonthlyOnTheWhich";
import { computeStartOnDate } from "./computeStartOnDate";
import { computeWeeklyDays } from "./computeWeeklyDays";
import { computeWeeklyInterval } from "./computeWeeklyInterval";
import { computeWeekStartDay } from "./computeWeekStartDay";
import { computeYearlyMode } from "./computeYearlyMode";
import { computeYearlyOnMonth } from "./computeYearlyOnMonth";
import { computeYearlyOnMonthday } from "./computeYearlyOnMonthday";
import { computeYearlyOnTheMonth } from "./computeYearlyOnTheMonth";
import { computeYearlyOnTheMonthday } from "./computeYearlyOnTheMonthday";
import { computeYearlyOnTheWhich } from "./computeYearlyOnTheWhich";

export const computeRRule = (
   initConfig: RecurrenceConfig,
   rrule?: string
): RecurrenceConfig => {
   if (!rrule) {
      return initConfig;
   }

   const rruleObj = RRuleObjectFromString(rrule).origOptions;

   return {
      ...initConfig,
      start: {
         ...initConfig.start,
         onDate: {
            date: computeStartOnDate(initConfig, rruleObj),
         },
      },
      repeat: {
         ...initConfig.repeat,
         frequency: computeFrequency(initConfig, rruleObj),
         yearly: {
            ...initConfig.repeat.yearly,
            mode: computeYearlyMode(initConfig, rruleObj),
            on: {
               month: computeYearlyOnMonth(initConfig, rruleObj),
               day: computeYearlyOnMonthday(initConfig, rruleObj),
            },
            onThe: {
               month: computeYearlyOnTheMonth(initConfig, rruleObj),
               day: computeYearlyOnTheMonthday(initConfig, rruleObj),
               which: computeYearlyOnTheWhich(initConfig, rruleObj),
            },
         },
         monthly: {
            ...initConfig.repeat.monthly,
            mode: computeMonthlyMode(initConfig, rruleObj),
            interval: computeMonthlyInterval(initConfig, rruleObj),
            on: {
               day: computeMonthlyOnDay(initConfig, rruleObj),
            },
            onThe: {
               day: computeMonthlyOnTheDay(initConfig, rruleObj),
               which: computeMonthlyOnTheWhich(initConfig, rruleObj),
            },
         },
         weekly: {
            interval: computeWeeklyInterval(initConfig, rruleObj),
            days: computeWeeklyDays(initConfig, rruleObj),
         },
         daily: {
            interval: computeDailyInterval(initConfig, rruleObj),
         },
      },
      end: {
         ...initConfig.end,
         mode: computeEndMode(initConfig, rruleObj),
         after: computeEndAfter(initConfig, rruleObj),
         onDate: {
            date: computeEndOnDate(initConfig, rruleObj),
         },
      },
      options: {
         ...initConfig.options,
         weekStartsOnSunday: computeWeekStartDay(initConfig, rruleObj),
      },
   };
};
