import { FC } from "react";
import { DPricePlan } from "@domain/subscription";
import { Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { formatDate } from "@utils";
import _ from "lodash";

interface CurrentPricePlanProps {
   pricePlan?: DPricePlan;
}

export const CurrentPricePlan: FC<CurrentPricePlanProps> = ({ pricePlan }) => {
   const interval = (pp: DPricePlan) => {
      switch (pp.interval) {
         case "month":
            return pp.intervalCount == 3 ? "quater" : "month";
         default:
            return pp.interval;
      }
   };

   const planName = (pp: DPricePlan) => {
      const intervall = interval(pp);
      return `${_.startCase(intervall)}ly plan`;
   };

   const price = (pp: DPricePlan) => {
      const amount = pp.amount / 100;
      const currency = _.upperCase(pp.currency);
      const intervall = interval(pp);
      return `${amount}${currency}/${intervall}`;
   };

   const nextBillingDate = (pp: DPricePlan) => {
      return formatDate(pp.nextBillingDate);
   };

   const cancelAtDate = (pp: DPricePlan) => {
      return formatDate(pp.cancelAt);
   };

   const chipActive = (pp: DPricePlan) => {
      if (pp.active) {
         return (
            <Chip
               label="Active"
               variant="filled"
               color="success"
               data-testid="chip-active"
            />
         );
      }
      return null;
   };

   const chipCancelAtPeriodEnd = (pp: DPricePlan) => {
      if (pp.cancelAtPeriodEnd) {
         const label = `Canceled on ${formatDate(pp.canceledAt)}`;
         return (
            <Chip label={label} variant="filled" data-testid="chip-canceled" />
         );
      }
      return null;
   };

   const billingSummaryText = (pp: DPricePlan) => {
      return pp.cancelAtPeriodEnd ? "Expires on" : "Renews on";
   };

   const billingSummaryDate = (pp: DPricePlan) => {
      return pp.cancelAtPeriodEnd ? cancelAtDate(pp) : nextBillingDate(pp);
   };

   const priceSummary = (pp: DPricePlan) => {
      return (
         <Grid
            container={true}
            item={true}
            direction="row"
            sx={{
               px: "1em",
               py: "2em",
               border: "1px solid #b3b0b0",
               borderRadius: "8px",
            }}
            data-testid="price"
         >
            <Grid
               container={true}
               item={true}
               columnSpacing={1}
               justifyContent="center"
               alignItems="center"
            >
               <Grid item={true}>
                  <Typography
                     sx={{
                        fontSize: "0.9em",
                     }}
                     data-testid="plan-name"
                  >
                     {planName(pp)}
                  </Typography>
                  <Typography
                     sx={{
                        fontWeight: 500,
                        fontSize: "1.5em",
                     }}
                     data-testid="plan-price"
                  >
                     {price(pp)}
                  </Typography>
               </Grid>
               <Grid item={true}>
                  <Stack direction="row" spacing={1}>
                     {chipActive(pp)}
                     {chipCancelAtPeriodEnd(pp)}
                  </Stack>
               </Grid>
            </Grid>
         </Grid>
      );
   };

   const billingSummary = (pp: DPricePlan) => {
      return (
         <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            sx={{
               px: "1em",
               py: "2em",
               border: "1px solid #b3b0b0",
               borderRadius: "8px",
            }}
            data-testid="next-billing-date"
         >
            <Grid item={true}>
               <Stack>
                  <Typography
                     sx={{
                        fontSize: "0.9em",
                     }}
                  >
                     {billingSummaryText(pp)}
                  </Typography>
                  <Typography
                     sx={{
                        fontWeight: 500,
                        fontSize: "1.5em",
                     }}
                  >
                     {billingSummaryDate(pp)}
                  </Typography>
               </Stack>
            </Grid>
         </Grid>
      );
   };

   const paymentSummary = (pp: DPricePlan) => {
      return (
         <Grid
            container={true}
            item={true}
            columnSpacing={3}
            direction="row"
            data-testid="payment-summary"
         >
            <Grid container={true} item={true} sm={6}>
               {priceSummary(pp)}
            </Grid>
            <Grid container={true} item={true} sm={6}>
               {billingSummary(pp)}
            </Grid>
         </Grid>
      );
   };

   const skeletons = () => {
      return (
         <Grid
            container={true}
            item={true}
            columnSpacing={3}
            direction="row"
            data-testid="skeletons"
         >
            <Grid item={true} sm={6}>
               <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="8em"
                  sx={{
                     borderRadius: "8px",
                  }}
               />
            </Grid>
            <Grid item={true} sm={6}>
               <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="8em"
                  sx={{
                     borderRadius: "8px",
                  }}
               />
            </Grid>
         </Grid>
      );
   };

   const currentPlan = () => {
      if (pricePlan) {
         return paymentSummary(pricePlan);
      }
      return skeletons();
   };

   return (
      <Grid container={true} rowSpacing={2} data-testid="current-price-plan">
         <Grid item={true}>
            <Typography
               sx={{
                  fontWeight: 500,
                  fontSize: "1.2em",
               }}
            >
               Current Plan
            </Typography>
         </Grid>
         <Grid container={true} item={true}>
            {currentPlan()}
         </Grid>
      </Grid>
   );
};
