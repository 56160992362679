import { FC, useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from "@lexical/selection";
import { mergeRegister } from "@lexical/utils";
import {
   $getSelection,
   $isRangeSelection,
   COMMAND_PRIORITY_LOW,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import _ from "lodash";
import { ToolbarDropDown } from "../widgets/ToolbarDropDown";
import { ToolbarDropDownOption } from "../widgets/ToolbarDropDownOption";
import { IconType } from "../widgets/ToolbarIcon";
import { FontFamilyOptions } from "./utils/constants";
import { getSelectionFontFamily } from "./utils/nodes";

interface FontFamilyProps {
   initFontFamily?: FontFamilyOptions;
}

export const FontFamily: FC<FontFamilyProps> = ({
   initFontFamily = FontFamilyOptions.arial,
}) => {
   const [editor] = useLexicalComposerContext();
   const [currentFontFamily, setCurrentFontFamily] =
      useState<FontFamilyOptions>(initFontFamily);

   const updateToolbar = useCallback(() => {
      const fontFamily = getSelectionFontFamily(
         currentFontFamily
      ) as FontFamilyOptions;
      setCurrentFontFamily(fontFamily);
   }, [currentFontFamily]);

   const styleSelectedText = useCallback(
      (styles: Record<string, string>) => {
         editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
               $patchStyleText(selection, styles);
            }
         });
      },
      [editor]
   );

   useEffect(() => {
      return mergeRegister(
         editor.registerCommand<void>(
            SELECTION_CHANGE_COMMAND,
            () => {
               updateToolbar();
               return false;
            },
            COMMAND_PRIORITY_LOW
         ),
         editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
               updateToolbar();
            });
         })
      );
   }, [editor, updateToolbar]);

   const styleText = (newFontFamily: FontFamilyOptions) => {
      styleSelectedText({ "font-family": newFontFamily });
   };

   const isActive = (fontFamily: FontFamilyOptions) => {
      return _.isEqual(fontFamily, currentFontFamily);
   };

   return (
      <div data-testid="font-family">
         <ToolbarDropDown label={currentFontFamily} icon={IconType.FONT_FAMILY}>
            <ToolbarDropDownOption
               text={FontFamilyOptions.arial}
               onClick={() => styleText(FontFamilyOptions.arial)}
               active={isActive(FontFamilyOptions.arial)}
               sx={{ fontFamily: FontFamilyOptions.arial }}
            />
            <ToolbarDropDownOption
               text={FontFamilyOptions.courier_new}
               onClick={() => styleText(FontFamilyOptions.courier_new)}
               active={isActive(FontFamilyOptions.courier_new)}
               sx={{ fontFamily: FontFamilyOptions.courier_new }}
            />
            <ToolbarDropDownOption
               text={FontFamilyOptions.georgia}
               onClick={() => styleText(FontFamilyOptions.georgia)}
               active={isActive(FontFamilyOptions.georgia)}
               sx={{ fontFamily: FontFamilyOptions.georgia }}
            />
            <ToolbarDropDownOption
               text={FontFamilyOptions.times_new_roman}
               onClick={() => styleText(FontFamilyOptions.times_new_roman)}
               active={isActive(FontFamilyOptions.times_new_roman)}
               sx={{ fontFamily: FontFamilyOptions.times_new_roman }}
            />
            <ToolbarDropDownOption
               text={FontFamilyOptions.trebuchet_ms}
               onClick={() => styleText(FontFamilyOptions.trebuchet_ms)}
               active={isActive(FontFamilyOptions.trebuchet_ms)}
               sx={{ fontFamily: FontFamilyOptions.trebuchet_ms }}
            />
            <ToolbarDropDownOption
               text={FontFamilyOptions.verdana}
               onClick={() => styleText(FontFamilyOptions.verdana)}
               active={isActive(FontFamilyOptions.verdana)}
               sx={{ fontFamily: FontFamilyOptions.verdana }}
            />
         </ToolbarDropDown>
      </div>
   );
};
