import { useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
   COMMAND_PRIORITY_LOW,
   FORMAT_TEXT_COMMAND,
   SELECTION_CHANGE_COMMAND,
} from "lexical";
import { ToolbarButton } from "../widgets/ToolbarButton";
import { IconType, ToolbarIcon } from "../widgets/ToolbarIcon";
import { selectionHasFormat } from "./utils/nodes";

export const ItalicButton = () => {
   const [editor] = useLexicalComposerContext();
   const [isItalic, setIsItalic] = useState(false);

   const updateToolbar = useCallback(() => {
      const fontFamily = selectionHasFormat("italic");
      setIsItalic(fontFamily);
   }, []);

   useEffect(() => {
      return editor.registerCommand(
         SELECTION_CHANGE_COMMAND,
         () => {
            updateToolbar();
            return false;
         },
         COMMAND_PRIORITY_LOW
      );
   }, [editor, updateToolbar]);

   const format = () => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
   };

   return (
      <ToolbarButton
         data-testid="italic-button"
         onClick={format}
         active={isItalic}
      >
         <ToolbarIcon type={IconType.TYPE_ITALIC} blured={!isItalic} />
      </ToolbarButton>
   );
};
