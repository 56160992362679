import { FC, useState } from "react";
import { Box } from "@mui/material";
import { HexColorInput, HexColorPicker } from "react-colorful";

interface ColorPickerProps {
   initColor?: string;
   onChange: (colorHex: string) => void;
}

export const ColorPicker: FC<ColorPickerProps> = ({
   initColor = "#000000",
   onChange,
}) => {
   const [color, setColor] = useState(initColor);

   const onColorSelected = (color: string) => {
      setColor(color);
      onChange(color);
   };

   const hexManulInput = () => {
      return (
         <Box
            data-testid="maual-color-input"
            sx={{
               mb: "1em",
               display: "flex",
               alignItems: "center",
            }}
         >
            <Box
               sx={{
                  flex: "1",
               }}
            >
               Hex
            </Box>
            <Box
               sx={{
                  flex: "2",
                  border: "1px solid #999",
                  borderRadius: "3px",
                  fontSize: "15px",
                  "input[spellcheck]": {
                     width: "100%",
                     height: "2em",
                     border: "none",

                     p: "0.2em 0.3em",
                  },
               }}
            >
               <HexColorInput color={color} onChange={onColorSelected} />
            </Box>
         </Box>
      );
   };

   const speedDialBtn = (color: string) => {
      return (
         <Box
            data-testid={`speed-dial-btn${color}`}
            component="button"
            sx={{
               width: "1.3em",
               height: "1.3em",
               backgroundColor: color,
               border: "1px solid #999",
            }}
            onClick={() => onColorSelected(color)}
         />
      );
   };

   const speedDialBtns = () => {
      return (
         <Box
            data-testid="speed-dial-btns"
            sx={{
               mb: "1em",
               display: "flex",
               justifyContent: "flex-start",
               flexWrap: "wrap",
               gap: "0.5em",
            }}
         >
            {speedDialBtn("#d0021b")}
            {speedDialBtn("#f5a623")}
            {speedDialBtn("#f8e71c")}
            {speedDialBtn("#8b572a")}
            {speedDialBtn("#7ed321")}
            {speedDialBtn("#417505")}
            {speedDialBtn("#bd10e0")}
            {speedDialBtn("#9013fe")}
            {speedDialBtn("#4a90e2")}
            {speedDialBtn("#50e3c2")}
            {speedDialBtn("#b8e986")}
            {speedDialBtn("#000000")}
            {speedDialBtn("#4a4a4a")}
            {speedDialBtn("#9b9b9b")}
            {speedDialBtn("#ffffff")}
         </Box>
      );
   };

   const colorPicker = () => {
      return (
         <Box
            sx={{
               height: "12em",
               ".react-colorful": {
                  width: "100%",
                  height: "100%",
               },
            }}
         >
            <HexColorPicker color={color} onChange={onColorSelected} />
         </Box>
      );
   };

   const selectedColor = () => {
      return (
         <Box
            sx={{
               mt: "1em",
               height: "1.5em",
               backgroundColor: color,
               border: "1px solid #999",
            }}
         />
      );
   };

   return (
      <Box
         data-testid="color-picker"
         sx={{
            p: "1em",
            width: "16em",
         }}
      >
         {hexManulInput()}
         {speedDialBtns()}
         {colorPicker()}
         {selectedColor()}
      </Box>
   );
};
