import { ChangeEvent, FC, FocusEvent, KeyboardEvent, useState } from "react";
import { Box, TextField } from "@mui/material";
import _ from "lodash";
import { ToolbarButton } from "./ToolbarButton";
import { IconType, ToolbarIcon } from "./ToolbarIcon";

interface SizeToggleProps {
   initSize?: number;
   maxSize?: number;
   minSize?: number;

   onChange: (size: number) => void;
}

export const SizeToggle: FC<SizeToggleProps> = ({
   initSize = 15,
   maxSize = 72,
   minSize = 8,
   onChange,
}) => {
   const [currentSize, setCurrentSize] = useState<number>(initSize);

   const resolveCurrentSize = () => {
      return _.isNaN(currentSize) ? undefined : currentSize;
   };

   const reconciledCurrentSize = () => {
      if (currentSize > maxSize) {
         return maxSize;
      }
      if (currentSize < minSize) {
         return minSize;
      }
      return currentSize;
   };

   const updateSize = (size: number) => {
      setCurrentSize(size);
      onChange(size);
   };

   const increase = () => {
      const size = currentSize + 1;
      updateSize(size);
   };

   const decrease = () => {
      const size = currentSize - 1;
      updateSize(size);
   };

   const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
      event.target.select();
   };

   const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const size = Number(value);
      setCurrentSize(size);
   };

   const onInputKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key == "Enter") {
         const size = reconciledCurrentSize();
         updateSize(size);
         event.preventDefault();
      }
   };

   const increaseDisabled = (): boolean => {
      return !increaseEnabled();
   };

   const increaseEnabled = (): boolean => {
      return currentSize < maxSize;
   };

   const decreaseDisabled = (): boolean => {
      return !decreaseEnabled();
   };

   const decreaseEnabled = (): boolean => {
      return currentSize > minSize;
   };

   return (
      <Box
         data-testid="size-toggle"
         sx={{
            display: "flex",
            alignItems: "center",
         }}
      >
         <ToolbarButton
            data-testid="decrease-button"
            onClick={decrease}
            disabled={decreaseDisabled()}
            sx={{
               px: "0.5px",
               mr: "3px",
            }}
         >
            <ToolbarIcon
               type={IconType.TEXT_SIZE_MINUS}
               blured={true}
               disabled={decreaseDisabled()}
            />
         </ToolbarButton>
         <TextField
            data-testid="input-field"
            type="number"
            inputProps={{
               min: minSize,
               max: maxSize,
               size: "1",
               maxLength: "2",
            }}
            value={resolveCurrentSize()}
            onFocus={onInputFocus}
            onChange={onInputChange}
            onKeyDown={onInputKeyPress}
            sx={{
               "input[type=number]": {
                  MozAppearance: "textfield",
                  width: "1.4em",
                  pl: "5px",
                  pr: "4px",
                  pt: "3px",
                  pb: "2px",
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "#777",
                  borderRadius: "5px",
                  border: "2px solid #a9a6a6",
               },
               "input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
               },
               "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                     borderColor: "#000",
                  },
                  "&.Mui-focused fieldset": {
                     borderColor: "#000",
                  },
               },
            }}
         />
         <ToolbarButton
            data-testid="increase-button"
            onClick={increase}
            disabled={increaseDisabled()}
            sx={{
               px: "0.5px",
               ml: "3px",
            }}
         >
            <ToolbarIcon
               type={IconType.TEXT_SIZE_PLUS}
               blured={true}
               disabled={increaseDisabled()}
            />
         </ToolbarButton>
      </Box>
   );
};
