import { Options } from "rrule";
import { RecurrenceConfig } from "../../../types";

export const computeYearlyOnMonthday = (
   data: RecurrenceConfig,
   rruleObj: Partial<Options>
) => {
   if (rruleObj.freq !== 0 || !rruleObj.bymonthday) {
      return data.repeat.yearly.on.day;
   }
   if (typeof rruleObj.bymonthday === "number") {
      return rruleObj.bymonthday;
   }
   return rruleObj.bymonthday[0];
};
