import { FC } from "react";
import { Button, Stack } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

interface AuthBtnsProps {
   sx?: object;
}

export const AuthBtns: FC<AuthBtnsProps> = ({ sx }) => {
   const { keycloak } = useKeycloak();

   const signin = async () => {
      keycloak.login();
   };

   const signup = async () => {
      keycloak.register();
   };

   return (
      <Stack direction="row" spacing={2} sx={sx} data-testid="auth-btns">
         <Button
            variant="outlined"
            disableRipple={true}
            onClick={signin}
            sx={{
               padding: "6px 14px",
               borderRadius: "10px",
               fontWeight: 500,
               letterSpacing: 1,
            }}
            data-testid="sign-in-btn"
         >
            Sign In
         </Button>
         <Button
            variant="contained"
            disableRipple={true}
            onClick={signup}
            sx={{
               padding: "6px 14px",
               borderRadius: "10px",
               fontWeight: 500,
               letterSpacing: 1,
            }}
            data-testid="sign-up-btn"
         >
            Sign Up
         </Button>
      </Stack>
   );
};
