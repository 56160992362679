import { forwardRef } from "react";
import { Avatar, AvatarProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const SearchAvatar = forwardRef<HTMLDivElement, AvatarProps>(
   ({ children, ...others }, ref) => {
      const theme = useTheme();

      return (
         <Avatar
            ref={ref}
            variant="rounded"
            sx={{
               ...theme.typography.commonAvatar,
               ...theme.typography.mediumAvatar,
               bgcolor: "secondary.light",
               color: "secondary.dark",
               "&:hover": {
                  bgcolor: "secondary.dark",
                  color: "secondary.light",
               },
            }}
            {...others}
            data-testid="search-avatar"
         >
            {children}
         </Avatar>
      );
   }
);

SearchAvatar.displayName = "SearchAvatar";
