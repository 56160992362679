import { Box } from "@mui/material";
import { Content } from "./content/Content";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";

export const LandingPage = () => {
   return (
      <Box data-testid="landing-page">
         <Header />
         <Content />
         <Footer />
      </Box>
   );
};
