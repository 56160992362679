import { FC } from "react";
import { Chip as MuiChip, ChipProps } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

interface PrmChipProps extends ChipProps {
   chipcolor?: string;
   sx?: object;
}

export const Chip: FC<PrmChipProps> = ({
   chipcolor,
   disabled,
   sx = {},
   variant,
   ...others
}) => {
   const theme = useTheme();

   const resolveDisabledSx = () => {
      if (variant === "outlined") {
         return {
            color: "grey.500",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "grey.500",
            ":hover": {
               color: "grey.500",
               bgcolor: "transparent",
            },
         };
      } else {
         return {
            color: "grey.500",
            bgcolor: "grey.50",
            ":hover": {
               color: "grey.500",
               bgcolor: "grey.50",
            },
         };
      }
   };

   const resolveOutlinedSx = () => {
      switch (chipcolor) {
         case "secondary":
            return {
               color: "secondary.main",
               bgcolor: "transparent",
               border: "1px solid",
               borderColor: "secondary.main",
               ":hover": {
                  color: "secondary.main",
                  bgcolor: "secondary.light",
               },
            };

         case "success":
            return {
               color: "success.dark",
               bgcolor: "transparent",
               border: "1px solid",
               borderColor: "success.dark",
               ":hover": {
                  color: "success.dark",
                  bgcolor: alpha(theme.palette.success.light, 0.6),
               },
            };

         case "error":
            return {
               color: "error.main",
               bgcolor: "transparent",
               border: "1px solid",
               borderColor: "error.main",
               ":hover": {
                  color: "error.dark",
                  bgcolor: "error.light",
               },
            };
         case "orange":
            return {
               color: "orange.dark",
               bgcolor: "transparent",
               border: "1px solid",
               borderColor: "orange.main",
               ":hover": {
                  color: "orange.dark",
                  bgcolor: "orange.light",
               },
            };
         case "warning":
            return {
               color: "warning.dark",
               bgcolor: "transparent",
               border: "1px solid",
               borderColor: "warning.dark",
               ":hover": {
                  color: "warning.dark",
                  bgcolor: "warning.light",
               },
            };
         default:
            return {
               color: "grey.500",
               bgcolor: "transparent",
               border: "1px solid",
               borderColor: "grey.500",
               ":hover": {
                  color: "grey.500",
                  bgcolor: "transparent",
               },
            };
      }
   };

   const resolveDefaultSx = () => {
      switch (chipcolor) {
         case "secondary":
            return {
               color: "secondary.main",
               bgcolor: "secondary.light",
               ":hover": {
                  color: "secondary.light",
                  bgcolor: "secondary.main",
               },
            };
         case "success":
            return {
               color: "success.dark",
               bgcolor: alpha(theme.palette.success.light, 0.6),
               ":hover": {
                  color: "success.light",
                  bgcolor: "success.dark",
               },
            };
         case "error":
            return {
               color: "error.dark",
               bgcolor: alpha(theme.palette.error.light, 0.6),
               ":hover": {
                  color: "error.light",
                  bgcolor: "error.dark",
               },
            };
         case "orange":
            return {
               color: "orange.dark",
               bgcolor: "orange.light",
               ":hover": {
                  color: "orange.light",
                  bgcolor: "orange.dark",
               },
            };

         case "warning":
            return {
               color: "warning.dark",
               bgcolor: "warning.light",
               ":hover": {
                  color: "warning.light",
                  bgcolor: "warning.dark",
               },
            };
         default:
            return {
               color: "primary.main",
               bgcolor: "primary.light",
               ":hover": {
                  color: "primary.light",
                  bgcolor: "primary.dark",
               },
            };
      }
   };

   const resolveSx = () => {
      if (disabled) {
         return resolveDisabledSx();
      }
      if (variant === "outlined") {
         return resolveOutlinedSx();
      }
      return resolveDefaultSx();
   };

   return (
      <MuiChip {...others} sx={{ ...resolveSx(), ...sx }} data-testid="chip" />
   );
};
